import React, { useState, useEffect } from 'react'
import { DateBox } from 'devextreme-react'
import cx from 'clsx'
import { Form } from 'react-bootstrap'
import styles from './Filter.module.scss'

const DATE_BOX_WIDTH = 280

type dateTypeT = 'day' | 'range'

interface FilterInterface {
  onChangePeriod: (key: string, value: any) => void
  from: any
  to: any
  withFilter?: boolean
  handleChangeDateType?: (type: string) => void
  fromProps?: DateBox | any
  toProps?: DateBox | any
  initialTimeTo?: number[]
  initialTimeFrom?: number[]
  notNextDay?: boolean
}

const CalendarPicker = ({
  onChangePeriod,
  from,
  to,
  withFilter = false,
  handleChangeDateType,
  fromProps = {},
  toProps = {},
  initialTimeTo = [2, 59, 59, 999],
  initialTimeFrom = [3, 0, 0, 0],
  notNextDay = false,
}: FilterInterface) => {
  const _now_date = new Date()

  const [dateType, setDateType] = useState<dateTypeT>(
    withFilter ? 'day' : 'range'
  )

  function updateDataFrom(event: any) {
    if (dateType === 'day') {
      if (event.value) {
        const _value = new Date(event.value)
        const _from = _value.setHours(
          initialTimeFrom[0],
          initialTimeFrom[1],
          initialTimeFrom[2],
          initialTimeFrom[3]
        )
        let _to = null
        if (notNextDay) {
          _to = new Date(
            _value.setHours(
              initialTimeTo[0],
              initialTimeTo[1],
              initialTimeTo[2],
              initialTimeTo[3]
            )
          ).getTime()
        } else {
          _to = new Date(
            _value.setHours(
              initialTimeTo[0],
              initialTimeTo[1],
              initialTimeTo[2],
              initialTimeTo[3]
            )
          ).setDate(_value.getDate() + 1)
        }

        onChangePeriod('day', {
          from: _from,
          to: _to,
        })
      } else {
        onChangePeriod('day', {
          from: null,
          to: null,
        })
      }
    } else {
      onChangePeriod('from', new Date(event?.value).getTime() || null)
    }
  }

  function updateDataTo(event: any) {
    onChangePeriod('to', new Date(event?.value).getTime() || null)
  }

  const _handleChangeDateType = (e: any) => {
    setDateType(e.target.id)
    if (typeof handleChangeDateType === 'function') {
      handleChangeDateType(e.target.id)
    }
  }

  useEffect(() => {
    const _from = new Date(from)

    if (dateType === 'day' && from && !to) {
      const _to = new Date(_from.setHours(2, 59, 59, 999)).setDate(
        _from.getDate() + 1
      )

      onChangePeriod('day', {
        from,
        to: _to,
      })
    }
  }, [dateType, from, to, onChangePeriod])

  const _max = new Date(_now_date.setDate(_now_date.getDate() + 1)).setHours(
    23,
    59,
    59,
    999
  )

  return (
    <div className="d-flex flex-column">
      {withFilter && (
        <div className="mb-3">
          <Form onChange={_handleChangeDateType}>
            <Form.Check
              inline
              type="radio"
              label="За добу"
              id="day"
              name="date-type"
              defaultChecked={dateType === 'day'}
            />
            <Form.Check
              inline
              type="radio"
              label="За період"
              id="range"
              name="date-type"
              defaultChecked={dateType === 'range'}
            />
          </Form>
        </div>
      )}
      <div className="d-flex flex-row">
        <div className={'column'}>
          <div className="font-weight-bold">
            {dateType === 'day' ? 'Дата' : 'Початок'}
          </div>
          <DateBox
            width={DATE_BOX_WIDTH}
            type={dateType === 'day' ? 'date' : 'datetime'}
            onValueChanged={updateDataFrom}
            showClearButton
            max={_now_date}
            value={from}
            {...fromProps}
          />
        </div>
        {dateType === 'range' && (
          <div className={cx('column', styles.filterItem)}>
            <div className="font-weight-bold">Кінець</div>
            <DateBox
              width={DATE_BOX_WIDTH}
              type="datetime"
              onValueChanged={updateDataTo}
              showClearButton
              min={from}
              max={_max}
              value={to}
              {...toProps}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CalendarPicker
