import React, { useEffect } from 'react'
import GoogleLogin, { useGoogleLogout } from 'react-google-login'

import GoogleIcon from './GoogleIcon'
import { CLIENT_ID } from '../../constants/googleClientConstant'
import styles from './Authorization.module.css'

function GoogleAuth({ onSuccess }: any) {
  const { signOut } = useGoogleLogout({
    clientId: CLIENT_ID,
    onLogoutSuccess: () => {},
    onFailure: () => {},
  })

  const GoogleButton = (renderProps: any) => (
    <button
      className={styles.googleButtonContainer}
      onClick={renderProps.onClick}
    >
      <GoogleIcon />
    </button>
  )

  const responseGoogle = (response: any) => {
    if (typeof onSuccess !== 'function') return
    const { accessToken } = response || {}
    onSuccess({ access_token: accessToken })
  }

  useEffect(() => {
    return () => {
      try {
        signOut()
      } catch (error) {
        console.log('error google logout')
      }
    }
  }, [signOut])

  return (
    <GoogleLogin
      clientId={CLIENT_ID}
      render={GoogleButton}
      onSuccess={responseGoogle}
      //onFailure={logIn}
      cookiePolicy={'single_host_origin'}
      isSignedIn={false}
    />
  )
}

export default GoogleAuth
