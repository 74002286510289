/* eslint-disable no-param-reassign */
import React from 'react'
import clsx from 'clsx'
import * as DataGrid from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import api from '../../../../api'
import { ConfigT } from '../../../../api/modules/config'
import { TYPES, ConfigParamT } from '../../../../api/modules/configParam'
import { ConfigDataT } from '../../../../api/modules/configData'

import styles from './Params.module.scss'
import { storeErrorHandler } from '../../../../helpers/errorHandling'

function DataCellTooltip(cellInfo: any): React.ReactNode {
  const isAttributeName = cellInfo.column.dataField === 'attribute.name'

  const renderTooltip = (props: any) => (
    <Tooltip {...props}>
      {cellInfo.data.attribute.purpose
        ? String(cellInfo.data.attribute.purpose)
        : 'No data'}
    </Tooltip>
  )

  return (
    <OverlayTrigger placement="top-start" overlay={renderTooltip}>
      <div
        className={clsx(styles.cell, {
          [styles.own]: cellInfo.data.own,
          [styles.name]: isAttributeName,
        })}
      >
        {String(cellInfo.value)}
      </div>
    </OverlayTrigger>
  )
}

function DataCell(cellInfo: any): React.ReactNode {
  return (
    <div
      className={clsx(styles.cell, {
        [styles.own]: cellInfo.data.own,
      })}
    >
      {String(cellInfo.value)}
    </div>
  )
}

function checkDataValue(value: string, type: TYPES): boolean {
  switch (type) {
    case TYPES.boolean:
      return ['true', 'false'].includes(value)
    case TYPES.number: {
      const casted = Number(value)
      return !Number.isNaN(casted) && Number.isFinite(casted)
    }
    case TYPES.string:
      return true
    default:
      return false
  }
}

function transformValue(value: string, type: TYPES): number | boolean | string {
  if (type === TYPES.number) {
    return Number(value)
  }

  if (type === TYPES.boolean) {
    return value === 'true'
  }

  return value
}

function normalizeAttribute(attribute: Record<string, any>): ConfigParamT {
  return attribute?.name?.id ? attribute.name : attribute
}

function validateValue({ data }: any): boolean {
  const attr = normalizeAttribute(data.attribute)
  if (attr) {
    return checkDataValue(data.value, attr.type)
  }

  return false
}

function disableEditName(event: any): void {
  if (event.dataField === 'attribute.name' && !event.row.isNewRow) {
    event.editorOptions.readOnly = true
  }
}

function extendUpdatingData(options: any): void {
  options.newData = { ...options.oldData, ...options.newData }
}

const allowDeletingGrid = (e: any): boolean => e.row.data.own

interface PropsI {
  selectedNode: ConfigT | undefined
  attributes: ConfigParamT[]
}

export default function Params({ selectedNode, attributes }: PropsI) {
  const paramId = selectedNode?.id
  if (!paramId) {
    return null
  }

  let config: ConfigDataT[] = []
  const device = selectedNode

  const dataStore: any = new CustomStore({
    key: 'id',
    load: () => {
      return api.configData
        .get({ id: paramId })
        .then(result => {
          config = result.data
          return config
        })
        .catch(storeErrorHandler)
    },
    remove: id => api.configData.delete(id).catch(storeErrorHandler),
    update: (id, data) => {
      const attr = normalizeAttribute(data.attribute)
      if (data.own) {
        return api.configData.update({
          id: data.id,
          value: transformValue(data.value, attr.type),
        })
      }

      return api.configData
        .create({
          value: transformValue(data.value, attr.type),
          parameterId: paramId,
          attributeId: data.attribute.id,
        })
        .catch(storeErrorHandler)
    },
    insert: data => {
      const attr = normalizeAttribute(data.attribute)
      return api.configData
        .create({
          value: transformValue(data.value, attr.type),
          parameterId: paramId,
          attributeId: attr.id,
        })
        .catch(storeErrorHandler)
    },
  })

  const getAvailAttributes = () => {
    const used = config.map(c => c.attribute.id)
    return attributes.filter(a => !used.includes(a.id))
  }

  return (
    <div className="col-6">
      <div className={styles.title}>{device ? device.name : ''}</div>
      <DataGrid.DataGrid
        id="params"
        showBorders
        dataSource={dataStore}
        repaintChangesOnly
        onEditorPreparing={disableEditName}
        onRowUpdating={extendUpdatingData}
      >
        <DataGrid.Column
          dataField="attribute.name"
          caption="Параметер"
          cellRender={DataCellTooltip}
        >
          <DataGrid.RequiredRule />
          <DataGrid.Lookup dataSource={getAvailAttributes} displayExpr="name" />
        </DataGrid.Column>
        <DataGrid.Column
          dataField="value"
          caption="Значення"
          cellRender={DataCell}
        >
          <DataGrid.RequiredRule />
          <DataGrid.CustomRule
            reevaluate
            validationCallback={validateValue}
            message="Невірне значення"
          />
        </DataGrid.Column>

        <DataGrid.Editing
          refreshMode="full"
          mode="form"
          allowUpdating
          allowDeleting={allowDeletingGrid}
          allowAdding
          useIcons
        />
      </DataGrid.DataGrid>
    </div>
  )
}
