import React, { useEffect, useState, memo } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import Spinner from 'react-bootstrap/Spinner'
import { Button } from 'devextreme-react/button'
import { GrUpdate } from 'react-icons/gr'
import SelectBox from 'devextreme-react/select-box'

import PageTitle from '../../components/PageTitle/PageTitle'
import { storeErrorHandler } from '../../helpers/errorHandling'
import api from '../../api/index'

import styles from './SystemStatusPage.module.scss'

const MIN = 1000 * 60
const _yesterdayDate = new Date()
_yesterdayDate.setDate(_yesterdayDate.getDate() - 1)
const _startDay = _yesterdayDate.setHours(0, 0, 0, 0)
const _endDay = _yesterdayDate.setHours(23, 59, 59, 999)

interface transactionsDataI {
  unprocessing: {
    cnt: string | number | null
    maxtrdt: string | number | null
    mintrdt: string | number | null
  }
  processing: {
    devcnt: string | number | null
    devgood: string | number | null
    devnotrans: string | number | null
    dt: string | number | null
    trcnt: string | number | null
    errs: string | number | null
    warns: string | number | null
  }
  systemStatus: any
}

const msToTime = (duration: number): string => {
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
  const days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 24)

  let res = `${seconds} сек`
  if (minutes) res = `${res} ${minutes} хв`
  if (hours) res = `${res} ${hours} год`
  if (days) res = `${res} ${days} дн`

  return res
}

const SystemStatusPage = props => {
  const [transactionsData, setTransactionsData] = useState<transactionsDataI>({
    unprocessing: {
      cnt: '',
      maxtrdt: '',
      mintrdt: '',
    },
    processing: {
      devcnt: '',
      devgood: '',
      devnotrans: '',
      dt: '',
      trcnt: '',
      errs: '',
      warns: '',
    },
    systemStatus: [],
  })

  const [isFetching, setIsFetching] = useState(false)

  const [intervalOfUpdates, setIntervalOfUpdates] = useState(null)

  const systemStateReports: any = () =>
    new CustomStore({
      key: 'id',
      load: async () => {
        setIsFetching(true)
        const batch = [
          api.stateSystem
            .getUnprocessedTransactionsInfo()
            .then((result: any) => result.data)
            .catch(storeErrorHandler),
          api.stateSystem
            .getProcessingTransactionsInfo({
              from: _startDay,
              to: _endDay,
            })
            .then(result => result.data)
            .catch(storeErrorHandler),
          api.reports
            .getSystemStatus()
            .then(res => res.data)
            .catch(storeErrorHandler),
        ]
        const [
          unprocessedTransactionsInfo,
          processingTransactionsInfo,
          systemStatus,
        ] = await Promise.all(batch)

        setTransactionsData({
          unprocessing: unprocessedTransactionsInfo,
          processing:
            processingTransactionsInfo[processingTransactionsInfo.length - 1] ||
            {},
          systemStatus: systemStatus || [],
        })
        setIsFetching(false)
      },
    })

  const handleChangeUpdateInterval = (e: any) => {
    setIntervalOfUpdates(e.value)
  }

  const handlerClickUpdate = () => {
    systemStateReports().load()
  }

  useEffect(() => {
    systemStateReports().load()
  }, [props.location.key])

  useEffect(() => {
    let timeout = null
    if (intervalOfUpdates) {
      timeout = setInterval(() => {
        systemStateReports().load()
      }, intervalOfUpdates)
    } else {
      clearInterval(timeout)
    }

    return () => {
      clearInterval(timeout)
    }
  }, [intervalOfUpdates])

  const deviseSizeWithFalseTransactions =
    Number(transactionsData?.processing?.devcnt) -
    Number(transactionsData?.processing?.devgood) -
    Number(transactionsData?.processing?.devnotrans)

  const UTCOffset = new Date().getTimezoneOffset()

  return (
    <div
      className="container d-flex flex-column "
      style={{ position: 'relative' }}
    >
      <div className="row d-flex flex-row justify-content-between align-items-center">
        <PageTitle title="Cтатус системи" className="mb-4" />
        <div className={styles.updateSection}>
          <Button
            width={40}
            type="normal"
            stylingMode="contained"
            onClick={handlerClickUpdate}
            className={styles.updateButton}
          >
            <GrUpdate
              color="#495057"
              className={isFetching && styles.updateIconFetching}
            />
          </Button>
          <SelectBox
            value={intervalOfUpdates}
            onValueChanged={handleChangeUpdateInterval}
            items={[
              { name: 'ніколи', value: null },
              { name: '1 хв', value: MIN },
              { name: '5 хв', value: MIN * 5 },
              { name: '10 хв', value: MIN * 10 },
              { name: '30 хв', value: MIN * 30 },
            ]}
            grouped={false}
            displayExpr="name"
            valueExpr="value"
            width={90}
            className={styles.selectBox}
          />
        </div>
      </div>
      <div className="row d-flex flex-column mb-4">
        <span className="mb-1">
          <strong>Рiзниця мiж локальним часом та UTC:</strong> {UTCOffset / 60}{' '}
          годин ({UTCOffset} хв)
        </span>
      </div>
      <div className="row d-flex flex-column mb-4">
        <h4 className="mb-3">Необроблені транзакції</h4>
        <span className="mb-1">
          <strong>Кількість необробленних транзакцій: </strong>
          {transactionsData?.unprocessing?.cnt ?? 0}
        </span>
        <span className="mb-1">
          <strong>Мінімальна дата транзакцій: </strong>
          {transactionsData?.unprocessing?.mintrdt
            ? new Date(transactionsData?.unprocessing?.mintrdt).toLocaleString()
            : isFetching
            ? ''
            : 'немає'}
        </span>
        <span className="mb-1">
          <strong>Максимальна дата транзакцій: </strong>
          {transactionsData?.unprocessing?.maxtrdt
            ? new Date(transactionsData?.unprocessing?.maxtrdt).toLocaleString()
            : isFetching
            ? ''
            : 'немає'}
        </span>
      </div>
      <div className="row d-flex flex-column mb-4">
        <h4 className="mb-3">Оброблені транзакції</h4>
        <span className="mb-1">
          <strong>Дата: </strong>
          {transactionsData?.processing?.dt}
        </span>
        <span className="mb-1">
          <strong>Кількість пристроїв: </strong>
          {transactionsData?.processing?.devcnt}
        </span>
        <span className="mb-1">
          <strong>
            Кількість пристроїв з успішно перевіреними транзакціями:{' '}
          </strong>
          {transactionsData?.processing?.devgood}
        </span>
        <span className="mb-1">
          <strong>
            Кількість пристроїв з не успішно перевіреними транзакціями:{' '}
          </strong>
          {isFetching ? '' : deviseSizeWithFalseTransactions}
        </span>
        <span className="mb-1">
          <strong>Кількість пристроїв без транзакцій: </strong>
          {transactionsData?.processing?.devnotrans}
        </span>
        <span className="mb-1">
          <strong>Кількість необроблених транзакцій: </strong>
          {transactionsData?.processing?.trcnt}
        </span>
        <span className="mb-1">
          <strong>Кількість застережень перерахунку: </strong>
          {transactionsData?.processing?.warns}
        </span>
        <span className="mb-1">
          <strong>Кількість помилок перерахунку: </strong>
          {transactionsData?.processing?.errs}
        </span>
      </div>
      <div className="row d-flex flex-column"></div>
      {transactionsData.systemStatus.map((s: any, index) => {
        return (
          <div key={index} className="row d-flex flex-column mb-4">
            <h4 className={`mb-3 ${styles.order0}`}>{s.name}</h4>
            {s.metrics.map(m => {
              let _value = m.value
              let _description = m.description
              let classNameOrder = styles.order2
              if (m.name === 'CurrentDate') {
                const threeMin = 1000 * 60 * 3

                const differenceTime = s.secondsOffline * 1000

                const timeContent = msToTime(differenceTime)
                _value = (
                  <span
                    className={`badge`}
                    style={{
                      backgroundColor:
                        differenceTime < threeMin ? 'green' : 'red',
                      color: 'white',
                    }}
                  >
                    {timeContent}
                  </span>
                )
                _description = 'Був у мережi'
                classNameOrder = styles.order1
              }
              return (
                <span
                  className={`mb-1 ${classNameOrder}`}
                  key={m.name + m.value}
                >
                  <strong>{_description}: </strong>
                  {_value}
                </span>
              )
            })}
          </div>
        )
      })}
      {isFetching && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  )
}

export default memo(SystemStatusPage)
