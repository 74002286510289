import React, { FC, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, Formik, FormikProps } from 'formik'
import cx from 'clsx'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container'
import axios from 'axios'
import InputField from '../../components/FormInputField/FormInputField'
import {
  login,
  loginWithWebauthn,
  StateT as AuthState,
  loginWithGoogle,
} from '../../store/modules/auth/auth'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import GoogleAuth from './GoogleAuth'
import styles from './Authorization.module.css'

import { localizedValidationErrors } from '../../constants/errorsLocalization'

import {
  publicKeyCredentialToJSON,
  preformatGetAssertReq,
  isWebAuthAvailable,
} from '../../helpers/webauthn'
import appConfig from '../../config'

// Types
import { AppState } from '../../store/modules'

type AuthorizationFormValues = {
  email: string
  password: string
}

const validate = (values: AuthorizationFormValues): AuthorizationFormValues => {
  const errors = {} as AuthorizationFormValues

  if (!values.email) {
    errors.email = localizedValidationErrors.required
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = localizedValidationErrors.email
  }

  if (!values.password) {
    errors.password = localizedValidationErrors.required
  }
  return errors
}

const AuthorizationPage: FC = () => {
  const dispatch = useDispatch()
  const { loading, error } = useSelector<AppState, AuthState>(
    state => state.auth
  )

  const handleSubmit = (values: AuthorizationFormValues): void => {
    dispatch(login(values))
  }

  const handleAuthWithGoogle = useCallback(
    data => {
      dispatch(loginWithGoogle(data))
    },
    [dispatch]
  )

  const handleWebAuthn = async (): Promise<void> => {
    try {
      const credential = await axios.post(
        `${appConfig.baseUrl}/user/webauthn/login`
      )
      const publicKey = preformatGetAssertReq(credential.data)
      const keyResponse = (await navigator.credentials.get({
        publicKey,
      })) as any
      if (keyResponse) {
        const getAssertionResponse = publicKeyCredentialToJSON(keyResponse)
        const loginResponse = await axios.post(
          `${appConfig.baseUrl}/user/webauthn/login/confirm`,
          getAssertionResponse
        )
        dispatch(loginWithWebauthn(loginResponse))
      }
    } catch (webauthnError) {
      console.log(webauthnError)
    }
  }

  return (
    <Container className={styles.loginPage} fluid>
      <Card className={cx('px-2 py-3', styles.cardStyles)}>
        <Card.Body>
          <div className={styles.logoWrapper}>
            <Card.Img
              variant="top"
              src="./logo.png"
              className={styles.logoImg}
            />
            <Card.Text>
              Київський <br />
              Метрополітен
            </Card.Text>
          </div>
          <blockquote>
            Неспинний рух задля <br />
            розвитку міста
          </blockquote>
          <div className={styles.titleContainer}>
            <h1 className={styles.formTitle}>Вхід</h1>
            <div className={styles.authIconsContainer}>
              <GoogleAuth onSuccess={handleAuthWithGoogle} />
              {isWebAuthAvailable ? (
                <div
                  className={styles.webAuthButton}
                  onClick={() => handleWebAuthn()}
                >
                  <svg viewBox="0 0 512 512">
                    <path
                      fill="#82c91e"
                      d="M63.8 409.3l60.5-59c32.1 42.8 71.1 66 126.6 67.4 30.5.7 60.3-7 86.4-22.4 5.1 5.3 18.5 19.5 20.9 22-32.2 20.7-69.6 31.1-108.1 30.2-43.3-1.1-84.6-16.7-117.7-44.4.3-.6-38.2 37.5-38.6 37.9 9.5 29.8-13.1 62.4-46.3 62.4C20.7 503.3 0 481.7 0 454.9c0-34.3 33.1-56.6 63.8-45.6zm354.9-252.4c19.1 31.3 29.6 67.4 28.7 104-1.1 44.8-19 87.5-48.6 121 .3.3 23.8 25.2 24.1 25.5 9.6-1.3 19.2 2 25.9 9.1 11.3 12 10.9 30.9-1.1 42.4-12 11.3-30.9 10.9-42.4-1.1-6.7-7-9.4-16.8-7.6-26.3-24.9-26.6-44.4-47.2-44.4-47.2 42.7-34.1 63.3-79.6 64.4-124.2.7-28.9-7.2-57.2-21.1-82.2l22.1-21zM104 53.1c6.7 7 9.4 16.8 7.6 26.3l45.9 48.1c-4.7 3.8-13.3 10.4-22.8 21.3-25.4 28.5-39.6 64.8-40.7 102.9-.7 28.9 6.1 57.2 20 82.4l-22 21.5C72.7 324 63.1 287.9 64.2 250.9c1-44.6 18.3-87.6 47.5-121.1l-25.3-26.4c-9.6 1.3-19.2-2-25.9-9.1-11.3-12-10.9-30.9 1.1-42.4C73.5 40.7 92.2 41 104 53.1zM464.9 8c26 0 47.1 22.4 47.1 48.3S490.9 104 464.9 104c-6.3.1-14-1.1-15.9-1.8l-62.9 59.7c-32.7-43.6-76.7-65.9-126.9-67.2-30.5-.7-60.3 6.8-86.2 22.4l-21.1-22C184.1 74.3 221.5 64 260 64.9c43.3 1.1 84.6 16.7 117.7 44.6l41.1-38.6c-1.5-4.7-2.2-9.6-2.2-14.5C416.5 29.7 438.9 8 464.9 8zM256.7 113.4c5.5 0 10.9.4 16.4 1.1 78.1 9.8 133.4 81.1 123.8 159.1-9.8 78.1-81.1 133.4-159.1 123.8-78.1-9.8-133.4-81.1-123.8-159.2 9.3-72.4 70.1-124.6 142.7-124.8zm-59 119.4c.6 22.7 12.2 41.8 32.4 52.2l-11 51.7h73.7l-11-51.7c20.1-10.9 32.1-29 32.4-52.2-.4-32.8-25.8-57.5-58.3-58.3-32.1.8-57.3 24.8-58.2 58.3zM256 160"
                    />
                  </svg>
                </div>
              ) : null}
            </div>
          </div>

          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={handleSubmit}
            validate={validate}
          >
            {(
              formik: FormikProps<AuthorizationFormValues>
            ): React.ReactNode => (
              <Form
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <Field
                  name="email"
                  component={InputField}
                  placeholder="Введіть ваш E-mail"
                  type="email"
                />
                <Field
                  name="password"
                  component={InputField}
                  placeholder="Введіть ваш пароль"
                  type="password"
                />
                {error && (
                  <ErrorMessage error={error} className={styles.errorMessage} />
                )}

                <Button
                  variant="primary"
                  type="submit"
                  className={styles.submitButton}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    'Увійти'
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default AuthorizationPage
