import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import CustomStore from 'devextreme/data/custom_store'
import { GrUpdate } from 'react-icons/gr'
import SelectBox from 'devextreme-react/select-box'
import { Button } from 'devextreme-react/button'
import DataGrid, { Column } from 'devextreme-react/data-grid'

import PageTitle from '../../components/PageTitle/PageTitle'
import { storeErrorHandler } from '../../helpers/errorHandling'
import api from '../../api'

import styles from './FeddingPage.module.scss'

const FILTERED_PARAMETER_ATTRIBUTE_NAME = 'RPM416HWGroupList'
const MIN = 1000 * 60

let ___SERVERTIME = new Date().getTime()

const checkConnectStatus = (data: any, timeGotData: any) => {
  const { device } = data || {}
  const { Connected, LastConnected = '' } = device || {} ///State
  let boolConnected = false

  if (Connected === 'true') {
    boolConnected = true
  } else {
    boolConnected = Boolean(Number(Connected))
  }

  let isMoreOneMinConnected = false

  if (LastConnected) {
    const differentMinuteOfNowTime =
      timeGotData - new Date(LastConnected).getTime()

    isMoreOneMinConnected = differentMinuteOfNowTime < 70000
  }

  return boolConnected && isMoreOneMinConnected && data?.deviceOnline === 'OK'
}

const makeArrayData = (obj: any) => {
  const result: any[] = []

  if (typeof obj !== 'object' || !Array.isArray(obj?.children)) return []

  const { children } = obj

  const requ = (node: any, prevName?: any) => {
    let _name = prevName
    node.forEach((n: any) => {
      if (n.deviceId) {
        const isOnline = checkConnectStatus(n, ___SERVERTIME)
        result.push({
          ...n,
          name: `${_name}/${n.name}`,
          deviceStateConnect: isOnline,
          v1: (isOnline && n?.device?.V1) || null,
          v2: (isOnline && n?.device?.V2) || null,
          temperature: (isOnline && n?.device?.Temperature1) || null,
          accumState: (isOnline && n?.device?.V24group4) || null,
          feedingGroup1: (isOnline && n?.device?.V24group1) || null,
          feedingGroup2: (isOnline && n?.device?.V24group2) || null,
          feedingGroup3: (isOnline && n?.device?.V24group3) || null,
          caseOpen: (isOnline && n?.device?.Case1Open) || null,
        })
      } else {
        _name = _name ? `${_name}/${n.name}` : `${n.name}`
        requ(n.children, _name)
        _name = prevName
      }
    })
  }

  requ(children, '')

  return result
}

const buildDeviceNode = (
  firmwareGroup: any,
  metrics: any,
  filterFirmwareGroupName?: string
) => {
  const devices: any = []
  firmwareGroup.forEach((item: any) => {
    if (item.devices.length > 0) {
      item.devices.forEach((device: any) => {
        const deviceWithHardware = { ...device }
        if (
          filterFirmwareGroupName === item.firmwareGroupId ||
          filterFirmwareGroupName === item.hardware.hardwareId
        ) {
          devices.push(deviceWithHardware)
        }
      })
    }
  })
  const deviceWithMetric = devices.map((item: any) => {
    const device = { ...item }
    const metric = metrics.filter(
      (element: any) => element.deviceId === item.id
    )
    if (metric.length) {
      const dates = metric.map((metricItem: any) =>
        new Date(metricItem.updatedAt).valueOf()
      )
      const maxDate = new Date(Math.max(...dates))
      device.metricUpdatedAt = maxDate

      const metricsObj = {}
      metric.forEach((element: any) => {
        Object.assign(metricsObj, {
          [element.attribute.name]: element.value,
        })
      })
      Object.assign(device, metricsObj)

      // device.metrics = metric;
    }
    return device
  })
  return deviceWithMetric
}

const updateDevice = (params: any, devices: any) => {
  const newTree = { ...params }
  function sortNode(nodeItem: any): any {
    return nodeItem.map((node: any) => {
      const parameter = { ...node }
      if (parameter.deviceId) {
        const device = devices.find((d: any) => d.id === parameter.deviceId)
        parameter.device = device || null
        parameter.name = device?.name || parameter.name
      }
      if (parameter.children !== undefined && parameter.children.length > 0) {
        parameter.children = sortNode(parameter.children)
      }
      return parameter
    })
  }

  return sortNode([newTree])
}

function filterChildren(childrenItem: any) {
  return childrenItem.filter((item: any) => {
    if (item.device) {
      return true
    }
    if (item.children && item.children.length === 0) {
      return false
    }
    if (item.children && item.children.length) {
      // eslint-disable-next-line no-param-reassign
      item.children = filterChildren(item.children)
      if (item.children.length === 0 && !item.device) {
        return false
      }

      return true
    }
    return true
  })
}

const removeEmptyConfiguration = (tree: any) => {
  const updatedTree = { ...tree }
  function filterNode(nodeItem: any): any {
    const updatedNodeItem = { ...nodeItem }
    if (updatedNodeItem.children && updatedNodeItem.children.length) {
      updatedNodeItem.children = updatedNodeItem.children.filter(
        (node: any): any => {
          if (node.device) {
            return true
          }
          if (node?.children.length === 0) {
            return false
          }
          if (node.children && node.children.length) {
            // eslint-disable-next-line no-param-reassign
            node.children = filterChildren(node.children)
            if (node.children.length === 0) {
              return false
            }
            return true
          }

          return true
        }
      )
    }
    return updatedNodeItem
  }
  return filterNode(updatedTree[0])
}

const buildTree = (
  firmwareGroup: any,
  params: any,
  metric: any,
  filterFirmwareGroupName?: string
) => {
  const deviceWithHardwareAndMetric = buildDeviceNode(
    firmwareGroup,
    metric,
    filterFirmwareGroupName
  )
  const tree = updateDevice(params, deviceWithHardwareAndMetric)
  return removeEmptyConfiguration(tree)
}

const FeedingPage: React.FC = () => {
  const treeRef = useRef<DataGrid>(null)

  const [intervalOfUpdates, setIntervalOfUpdates] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const configStore: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: async () => {
          setIsFetching(true)
          const batch = [
            api.firmwareGroup
              .get({})
              .then((result: any) => {
                ___SERVERTIME = new Date(result?.headers?.serverdate).getTime()
                return result.data
              })
              .catch(storeErrorHandler),
            api.config
              .get({})
              .then(result => result.data)
              .catch(storeErrorHandler),
            api.metric
              .get({})
              .then(result => result.data)
              .catch(storeErrorHandler),
            api.configParam
              .get({})
              .then(result => result.data)
              .catch(storeErrorHandler),
            api.configData
              .getAll()
              .then(result => result.data)
              .catch(storeErrorHandler),
          ]
          const [
            firmwareGroup,
            params,
            metric,
            parameterAttribute,
            parameterData,
          ] = await Promise.all(batch)
          const parameterAttributeByFilterGroup = parameterAttribute.find(
            (element: any) => element.name === FILTERED_PARAMETER_ATTRIBUTE_NAME
          )
          const parameterDataByGroup = parameterData.filter(
            (element: any) =>
              parameterAttributeByFilterGroup.id === element.attributeId
          )
          const filterFirmwareGroupName =
            parameterDataByGroup.length > 0
              ? parameterDataByGroup[0].value
              : undefined
          const returnedData = buildTree(
            firmwareGroup,
            params,
            metric,
            filterFirmwareGroupName
          )
          setIsFetching(false)

          return makeArrayData(returnedData)
        },
      }),

    []
  )

  const handleChangeUpdateInterval = (e: any) => {
    setIntervalOfUpdates(e.value)
  }

  const handlerClickUpdate = () => {
    treeRef.current.instance.refresh()
  }

  useEffect(() => {
    let timeout = null
    if (intervalOfUpdates) {
      timeout = setInterval(() => {
        treeRef.current.instance.refresh()
      }, intervalOfUpdates)
    } else {
      clearInterval(timeout)
    }

    return () => {
      clearInterval(timeout)
    }
  }, [intervalOfUpdates, configStore])

  return (
    <>
      <div className="container mb-3">
        <div className="row d-flex flex-row justify-content-between align-items-center mb-3">
          <PageTitle title="Живлення" />
          <div className={styles.updateSection}>
            <Button
              width={40}
              type="normal"
              stylingMode="contained"
              onClick={handlerClickUpdate}
              className={styles.updateButton}
            >
              <GrUpdate
                color="#495057"
                className={isFetching && styles.updateIconFetching}
              />
            </Button>
            <SelectBox
              value={intervalOfUpdates}
              onValueChanged={handleChangeUpdateInterval}
              items={[
                { name: 'ніколи', value: null },
                { name: '1 хв', value: MIN },
                { name: '5 хв', value: MIN * 5 },
                { name: '10 хв', value: MIN * 10 },
                { name: '30 хв', value: MIN * 30 },
              ]}
              grouped={false}
              displayExpr="name"
              valueExpr="value"
              width={90}
              className={styles.selectBox}
            />
          </div>
        </div>
      </div>

      <DataGrid ref={treeRef} dataSource={configStore} showBorders id={'id'}>
        <Column
          dataField="name"
          caption="Розташування"
          width="280"
          cellRender={(props: any) => <NameCell {...props} />}
        />
        <Column
          dataField="v1"
          caption="V1"
          width={'auto'}
          cellRender={(props: any) => <V1Column {...props} field="V1" />}
          alignment="center"
          cssClass={styles.cellStyle}
        />
        <Column
          dataField="v2"
          caption="V2"
          width={'auto'}
          cellRender={(props: any) => <V1Column {...props} field="V2" />}
          alignment="center"
          cssClass={styles.cellStyle}
        />
        <Column
          dataField="temperature"
          caption="Температура"
          width={'auto'}
          cellRender={(props: any) => (
            <TemperatureColumn {...props} field="Temperature1" />
          )}
          alignment="center"
          cssClass={styles.cellStyle}
        />

        <Column
          dataField="accumState"
          caption="Статус аккумулятору"
          width={'auto'}
          cellRender={(props: any) => (
            <AcumState {...props} field="V24group4" />
          )}
          alignment="center"
          cssClass={styles.cellStyle}
        />
        <Column caption="Групи живлення" alignment="center">
          <Column
            dataField="feedingGroup1"
            caption="1"
            width={'auto'}
            alignment="center"
            cellRender={(props: any) => (
              <V24group {...props} field="V24group1" />
            )}
            cssClass={styles.cellStyle}
          />
          <Column
            dataField="feedingGroup2"
            caption="2"
            width={'auto'}
            alignment="center"
            cellRender={(props: any) => (
              <V24group {...props} field="V24group2" />
            )}
            cssClass={styles.cellStyle}
          />
          <Column
            dataField="feedingGroup3"
            caption="3"
            width={'auto'}
            alignment="center"
            cellRender={(props: any) => (
              <V24group {...props} field="V24group3" />
            )}
            cssClass={styles.cellStyle}
          />
        </Column>
        <Column
          dataField="caseOpen"
          caption="Стан дверей"
          width={'auto'}
          alignment="center"
          cssClass={styles.cellStyle}
          cellRender={(props: any) => <DoorStatus {...props} />}
        />
        <Column
          dataField="deviceStateConnect"
          caption="Стан"
          width={'auto'}
          alignment="center"
          cssClass={styles.cellStyle}
          cellRender={(props: any) => <StateColumn {...props} />}
        />
      </DataGrid>
    </>
  )
}

const NameCell = ({ data }: { data: any }) => {
  const { deviceId } = data
  const history = useHistory()
  const icon = deviceId ? 'fa fa-microchip' : 'fa fa-object-group'

  const handleClick = (e: any) => {
    e.preventDefault()
    if (deviceId) {
      history.push(`/device-state/?device-id=${deviceId}`)
    }
  }
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <i className={icon} style={{ marginRight: '5px' }} />
      <span className={styles.nameStyle} onClick={handleClick}>
        {data.name}
      </span>
    </span>
  )
}

const StateColumn = ({ data }: { data: any }) => {
  const { deviceStateConnect: isOnline } = data

  const value = isOnline ? 'online' : 'offline'
  const color = isOnline ? 'green' : 'red'
  return (
    <span className="badge" style={{ backgroundColor: color, color: '#fff' }}>
      {value}
    </span>
  )
}

const V1Column = ({ data, field }: { data: any; field: string }) => {
  const { deviceStateConnect: isOnline } = data

  const color =
    data.device[field] > 260 || data.device[field] < 180 ? 'red' : 'green'
  return isOnline ? (
    <span className="badge" style={{ backgroundColor: color, color: '#fff' }}>
      {data.device[field]}
    </span>
  ) : null
}

const TemperatureColumn = ({ data, field }: { data: any; field: string }) => {
  const { deviceStateConnect: isOnline } = data

  const color = data.device[field] > 60 ? 'red' : 'green'

  if (!isOnline) return null

  if (data.device[field] === 'invalid') {
    return <span>{data.device[field]}</span>
  }
  return (
    <span className="badge" style={{ backgroundColor: color, color: '#fff' }}>
      {data.device[field]}
    </span>
  )
}

const AcumState = ({ data, field }: { data: any; field: string }) => {
  const { deviceStateConnect: isOnline } = data

  let value = data?.device[field] === '1' ? 'OK' : ''

  if (data?.device[field] === '0') {
    value = 'помилка'
  }
  const color = data?.device[field] === '1' ? 'green' : 'red'

  if (!isOnline) return null

  return (
    <span className="badge" style={{ backgroundColor: color, color: '#fff' }}>
      {value}
    </span>
  )
}

const V24group = ({ data, field }: { data: any; field: string }) => {
  const { deviceStateConnect: isOnline } = data

  let value = data?.device[field] === '1' ? 'OK' : ''

  if (data?.device[field] === '0') {
    value = '1/2 фідер'
  }
  const color = data?.device[field] === '1' ? 'green' : 'red'

  if (!isOnline) return null
  return (
    <span className="badge" style={{ backgroundColor: color, color: '#fff' }}>
      {value}
    </span>
  )
}

const DoorStatus = ({ data }: { data: any }) => {
  if (data.device.Case1Open && data.device.Case2Open) {
    const v = parseFloat(data.device.RPMADC10)
    const d1Status = parseInt(data.device.Case1Open, 10)
    const d2Status = parseInt(data.device.Case2Open, 10)

    let color = 'red'
    let badgeLabel = 'помилка'
    if (v >= 1 && v < 12) {
      color = d1Status === 0 && d2Status === 0 ? 'green' : '#F1C40F'
      badgeLabel =
        d1Status === 0 && d2Status === 0 ? 'OK' : String(d1Status + d2Status)
    }

    const { deviceStateConnect: isOnline } = data

    if (!isOnline) return null

    return (
      <span className="badge" style={{ backgroundColor: color, color: '#fff' }}>
        {badgeLabel}
      </span>
    )
  }
  return null
}

export default FeedingPage
