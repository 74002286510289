import { ApiRequestT, API_REQUEST } from '../../apiAction';
import api from '../../../api';
import { UserT, AuthDataT, GoogleAuthDataT } from '../../../api/modules/user';
import { GrantT } from '../../../helpers/permissions';
import errorHandling, { ErrorResponseT } from '../../../helpers/errorHandling';

export const LOGIN_START = 'auth/LOGIN_START';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';

export const WEBAUTHN_START = 'auth/WEBAUTHN_START';
export const WEBAUTHN_SUCCESS = 'auth/WEBAUTHN_SUCCESS';
export const WEBAUTHN_FAILURE = 'auth/WEBAUTHN_FAILURE';

export const USER_ME_LOGIN = 'auth/USER_ME_LOGIN';
export const USER_ME_LOGIN_SUCCESS = 'auth/USER_ME_LOGIN_SUCCESS';

export const LOGOUT_START = 'auth/LOGOUT_START';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'auth/LOGOUT_FAILURE';

export const GOOGLE_AUTH_START = 'GOOGLE_AUTH_START'
export const GOOGLE_SUCCESS = 'GOOGLE_AUTH_START'
export const GOOGLE_FAILURE = 'GOOGLE_AUTH_START'

export type WebauthChallengeT = {
  type: typeof WEBAUTHN_START;
  payload: {
    userName: string;
    displayName: string;
  };
};

export type LoginStartActionT = {
  type: typeof LOGIN_START;
  payload: AuthDataT;
};

export type LoginSuccessResultT = {
  data: {
    user: UserT;
    grants: GrantT[];
  };
  headers: Record<string, string>;
};

export type LoginSuccessActionT = {
  type: typeof LOGIN_SUCCESS;
  result: LoginSuccessResultT;
  initialized?: boolean;
};

export type LoginFailureActionT = {
  type: typeof LOGIN_FAILURE;
  error: ErrorResponseT;
};

export type LogoutStartActionT = {
  type: typeof LOGOUT_START;
};

export type LogoutSuccessActionT = {
  type: typeof LOGOUT_SUCCESS;
};

export type LogoutFailureActionT = {
  type: typeof LOGOUT_FAILURE;
};

export type UserMeLoginActionT = {
  type: typeof USER_ME_LOGIN;
};

export type UserMeLoginSuccessActionT = {
  type: typeof USER_ME_LOGIN_SUCCESS;
  result: LoginSuccessResultT;
};

export type ActionTypeT =
  | LoginStartActionT
  | LoginSuccessActionT
  | LoginFailureActionT
  | LogoutStartActionT
  | LogoutSuccessActionT
  | LogoutFailureActionT
  | UserMeLoginActionT
  | UserMeLoginSuccessActionT;

export type StateT = {
  loading: boolean;
  error: null | string;
  user: null | UserT;
  grants: null | GrantT[];
};

const initialState: StateT = {
  loading: false,
  error: null,
  user: null,
  grants: null,
};

export const authReducer = (state = initialState, action: ActionTypeT): StateT => {
  switch (action.type) {
    case LOGIN_START:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.result.data.user,
        grants: action.result.data.grants,
      };
    case LOGIN_FAILURE:
      return { ...state, loading: false, error: errorHandling(action.error) };
    case USER_ME_LOGIN:
      return { ...state, loading: true, error: null };
    default:
      return state;
  }
};

export default authReducer;

export function loginWithWebauthn(result: any): LoginSuccessActionT {
  return {
    type: WEBAUTHN_SUCCESS as any,
    result,
  };
}

export function login(data: AuthDataT): ApiRequestT<UserT> {
  return {
    type: API_REQUEST,
    types: [LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILURE],
    call: () => api.user.auth(data),
  };
}

export function loginWithGoogle(data: GoogleAuthDataT): ApiRequestT<UserT> {
  return {
    type: API_REQUEST,
    types: [LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILURE],
    call: () => api.user.googleAuthnLogin(data),
  };
}

export function userMe(token: string): ApiRequestT<UserT> {
  return {
    type: API_REQUEST,
    types: [USER_ME_LOGIN, USER_ME_LOGIN_SUCCESS, LOGOUT_SUCCESS],
    call: () => api.user.me(token),
  };
}

export function userMeLogin(result: LoginSuccessResultT): LoginSuccessActionT {
  return {
    type: LOGIN_SUCCESS,
    result,
    initialized: true,
  };
}

export function logout(): ApiRequestT<UserT> {
  return {
    type: API_REQUEST,
    types: [LOGOUT_START, LOGOUT_SUCCESS, LOGOUT_FAILURE],
    call: () => api.user.logout(),
  };
}

export function logoutFailure(): ActionTypeT {
  return {
    type: LOGOUT_FAILURE,
  };
}
