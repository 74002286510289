import { AxiosInstance, AxiosPromise } from 'axios';
import CRUD from '../base/crud';

export type PermissionT = {
  id: number;
  action: 'string';
};

export type RoleT = {
  id: number;
  name: string;
  permissions: PermissionT[];
  haveUsers?: number;
};

export class RoleCRUD extends CRUD<RoleT> {
  permissions(): AxiosPromise<PermissionT> {
    return this.r({
      method: 'GET',
      url: `${this.config.url}/permissions`,
    });
  }
}

export default function roleCrud(request: AxiosInstance): RoleCRUD {
  const crud = new RoleCRUD({
    url: '/role',
    request,
    id: 'id',
  });

  return crud;
}
