import { AxiosInstance } from 'axios'
import CRUD from '../base/crud'

export type AlarmT = {
  attributeId: number
  createAt: string
  alarmTypeId: string
  device: string
  deviceId: number
  id: number
  line: string
  lobby: string
  metro: string
  newValue: string
  oldValue: string
  station: string
  devicePrimalId?: number
  nodeId?: number
}
export class AlarmCRUD extends CRUD<AlarmT> {
  getReport(params: any) {
    let url = `${this.config.url}/report/generate`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: 'blob',
    })
  }
}

export default function deviceCRUD(request: AxiosInstance): AlarmCRUD {
  return new AlarmCRUD({
    url: '/alarm',
    request,
    id: 'id',
  })
}
