import React, { useEffect, useRef, useState } from 'react'
import { Editing, Form, TreeList } from 'devextreme-react/tree-list'
import { Column, RequiredRule } from 'devextreme-react/data-grid'
import { GroupItem, Item } from 'devextreme-react/form'
import CustomStore from 'devextreme/data/custom_store'
import { orderBy } from 'lodash'

import { DeviceIdsT } from '../../../components/DeviceFilter/DeviceFilter'
import api from '../../../api'
import { storeErrorHandler } from '../../../helpers/errorHandling'
import { FirmwareGroupT } from '../../../api/modules/firmwareGroup'
import { DeviceT } from '../../../api/modules/device'
import { HardwareT } from '../../../api/modules/hardware'
import EditTextInput from '../../../components/EditTextInput/EditTextInput'
import GroupsListDropDown from '../../../components/GroupsListDropDown/GroupsListDropDown'
import ParameterListDropDown from '../../../components/ParameterListDropDown/ParameterListDropDown'
import {
  findDeviceParentId,
  removeDevicesFromTree,
} from '../../../helpers/treeModificator'
import { ConfigT } from '../../../api/modules/config'

interface TableInterface {
  isCollapsed: boolean
  count: number
  deviceIds: number[] | undefined
  isShowAddButtonInToolbox?: boolean
}

const RenderColumnName: React.FC = (data: any) => {
  switch (data.data.type) {
    case 'software':
      return <span>Software id: {data.data.name}</span>
    case 'hardware':
      return <span>Hardware id: {data.data.id}</span>
    case 'group':
      return <span>Group id: {data.data.name}</span>
    case 'device':
      return <span>{data.data.deviceName}</span>
    default:
      return <span />
  }
}

const RenderId: React.FC = (data: any) => {
  switch (data.data.type) {
    case 'software':
      return <span>{data.data.id}</span>
    case 'hardware':
      return <span>{data.data.hardwareId}</span>
    case 'group':
      return <span>{data.data.firmwareGroupId}</span>
    case 'device':
      return <span>{data.data.deviceId}</span>
    default:
      return <span />
  }
}

function extendUpdatingData(options: any): void {
  options.newData = { ...options.oldData, ...options.newData }
}

function allowUpdating(e: any) {
  return e.row.node.data.type === 'device'
}

function allowAdding(e: any) {
  return e.row.node.data.type === 'group'
}

type TreeItemT<T> = {
  [K in keyof T]: T[K]
} & {
  key: string
  parentId: string | number
  type: string
  children: any[]
  groups: any[]
}

const getTree = (
  firmwareGroup: TreeItemT<FirmwareGroupT>[],
  hardware: TreeItemT<HardwareT>[],
  config: ConfigT,
  deviceIds: DeviceIdsT,
  firmware: any
) => {
  const groupsWithSoftware = firmwareGroup.map((item: any) => {
    const firmwareGroupItem = {
      ...item,
      type: 'group',
      key: `${item.id}-group`,
      parentId: item.hardware.id,
      children: [],
    }
    const devices = firmwareGroupItem.devices
      .filter((device: any) =>
        deviceIds ? deviceIds.includes(device.id) : true
      )
      .map((device: any) => {
        const parameterParentId = findDeviceParentId(config, device.id)

        const _filteredFirmware = firmware.reduce((res, firm) => {
          const _filterByGroupId = firmware.filter(
            (i: any) => firm?.groupId === i.groupId
          )

          if (_filterByGroupId?.length === 1) {
            res = [...res, ..._filterByGroupId]
          }
          if (_filterByGroupId?.length > 1) {
            const filterByEnabled = _filterByGroupId.filter(f => f.enabled)

            if (filterByEnabled?.length > 1) {
              const _filteredSameGroupIdByVersion = orderBy(
                filterByEnabled,
                ['versionMajor', 'versionMinor', 'versionPatch'],
                ['desc', 'desc', 'desc']
              )[0]

              res = [...res, _filteredSameGroupIdByVersion]
            } else if (filterByEnabled?.length === 1) {
              res = [...res, ...filterByEnabled]
            }
          }

          return res
        }, [])

        const firmGroup = _filteredFirmware.find(
          firm => firm.groupId === device.firmwareGroupId
        )
        // enabled, groupId, versionMajor, versionMinor, versionPatch
        return {
          ...device,
          type: 'device',
          deviceName: device.name || '',
          deviceVersion: firmGroup
            ? `${firmGroup.versionMajor}.${firmGroup.versionMinor}.${firmGroup.versionPatch}`
            : '',
          deviceVersionMetric: '1.2.1',
          firmwareGroupId: firmwareGroupItem.id,
          parameterParentId,
        }
      })

    firmwareGroupItem.children = devices
    return firmwareGroupItem
  })
  const tree = hardware.map((item: any) => {
    delete item.groups
    item.key = `${item.id}-hardware`
    item.type = 'hardware'
    groupsWithSoftware.forEach((group: any) => {
      if (group.hardware.id === item.id) {
        item.children = item.children ? [...item.children, group] : [group]
      }
    })
    return item
  })
  return tree
}

function getBatch() {
  return [
    api.firmwareGroup
      .get({})
      .then(result => result.data)
      .catch(storeErrorHandler),
    api.hardware
      .get({})
      .then(result => result.data)
      .catch(storeErrorHandler),
    api.config
      .get({})
      .then((result: any) => result.data)
      .catch(storeErrorHandler),
    api.firmware
      .get()
      .then((result: any) => result.data)
      .catch(storeErrorHandler),
  ]
}

const Table = ({
  count,
  deviceIds,
  isCollapsed,
  isShowAddButtonInToolbox,
}: TableInterface) => {
  const [groupListStore, setGroupListStore] = useState<any>([])
  const [paramsListStore, setParamsListStore] = useState<any>([])

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const treeListRef = useRef<TreeList>(null)

  useEffect(() => {
    treeListRef.current?.instance.forEachNode((node: any) => {
      treeListRef.current?.instance[!isCollapsed ? 'collapseRow' : 'expandRow'](
        node.key
      )
    })
  }, [isCollapsed])

  const firmwareData: any = useRef(
    new CustomStore({
      key: 'key',
      load: async () => {
        const [firmwareGroup, hardware, config, firmware] = await Promise.all(
          getBatch()
        )
        setGroupListStore(firmwareGroup)
        setParamsListStore(removeDevicesFromTree(config))
        const result = getTree(
          firmwareGroup,
          hardware,
          config,
          deviceIds,
          firmware
        )

        return result
      },
      insert: async (data: any) => {
        return api.device.create(data).catch(storeErrorHandler)
      },
      update: async (id: any, data: Partial<DeviceT>) => {
        return api.device
          .update({ id: data.id, ...data })
          .catch(storeErrorHandler)
      },
      remove: async (id: any) => {
        const deviceId = treeListRef.current?.instance.getNodeByKey(id).data.id
        return api.device.delete(deviceId).catch(storeErrorHandler)
      },
    })
  ).current

  useEffect(() => {
    firmwareData.on('modified', () => setIsEdit(false))

    return () => {
      firmwareData.off('modified')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (treeListRef.current) {
      treeListRef.current.instance.refresh()
    }
  }, [count])

  function onToolbarPreparing(e: any) {
    const toolbarItems = e.toolbarOptions.items
    if (isShowAddButtonInToolbox) {
      toolbarItems.shift()
    }
  }

  return (
    <TreeList
      ref={treeListRef}
      id="firmwareTree"
      dataStructure="tree"
      dataSource={firmwareData}
      itemsExpr="children"
      keyExpr="key"
      showRowLines
      showBorders
      columnAutoWidth
      onToolbarPreparing={e => onToolbarPreparing(e)}
      onRowUpdating={extendUpdatingData}
      onEditingStart={() => setIsEdit(true)}
    >
      <Editing
        refreshMode="full"
        mode="form"
        allowUpdating={allowUpdating}
        useIcons
        allowDeleting={allowUpdating}
        allowAdding={allowAdding}
      >
        <Form
          activeStateEnabled
          focusStateEnabled
          onFocusOut={() => setIsEdit(false)}
          colCount="1"
        >
          <GroupItem colCount="2">
            <Item dataField="deviceId" caption="Device Id" />

            <Item dataField="deviceMac" caption="MAC Address" />
            <Item dataField="name" caption="Device Name" />
            <Item visible={isEdit} dataField="firmwareGroupId" />
            <Item dataField="parameterParentId" />
          </GroupItem>
        </Form>
      </Editing>
      <Column
        allowEditing={false}
        dataField="id"
        caption="ID пристрою"
        cellRender={RenderId}
      />
      <Column
        cellRender={RenderColumnName}
        dataField="deviceName"
        caption="Назва пристрою"
        showEditorAlways={false}
      />
      <Column
        editCellRender={cellOption => (
          <EditTextInput
            cellOption={cellOption}
            textBoxParams={{ maxLength: 45 }}
          />
        )}
        dataField="deviceMac"
        caption="MAC адреса"
      >
        <RequiredRule />
      </Column>
      <Column dataField="deviceVersion" caption="Версія ПЗ" />
      <Column
        visible={false}
        editCellRender={cellOption => (
          <EditTextInput
            cellOption={cellOption}
            textBoxParams={{ maxLength: 255 }}
          />
        )}
        dataField="name"
        caption="Ім'я"
      >
        <RequiredRule />
      </Column>
      <Column
        editCellRender={cellOption => (
          <EditTextInput
            cellOption={cellOption}
            textBoxParams={{ maxLength: 44 }}
          />
        )}
        visible={false}
        dataField="deviceId"
        caption="Пристрій Id"
      >
        <RequiredRule />
      </Column>

      <Column
        visible={false}
        editCellRender={cellOption => (
          <GroupsListDropDown
            dataSource={groupListStore}
            cellOption={cellOption}
          />
        )}
        dataField="firmwareGroupId"
        caption="Группа пристроїв"
      >
        <RequiredRule />
      </Column>

      <Column
        visible={false}
        editCellRender={cellOption => (
          <ParameterListDropDown
            dataSource={paramsListStore}
            cellOption={cellOption}
          />
        )}
        dataField="parameterParentId"
        caption="Параметр"
      >
        <RequiredRule />
      </Column>
    </TreeList>
  )
}

export default Table
