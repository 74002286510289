import { AxiosInstance, AxiosPromise } from 'axios';
import CRUD from '../base/crud';

import { ConfigParamT } from './configParam';

export type ConfigDataT = {
  id: number;
  value: boolean | number | string | null;
  attribute: ConfigParamT;
  attributeId: number;
  parameterId: number;
  own: boolean;
};

export class ConfigDataCRUD extends CRUD<ConfigDataT> {
  getAll(): AxiosPromise<Array<ConfigDataT>> {
    return this.r({
      method: 'GET',
      url: '/parameter/data',
    });
  }

  get(params: { id: number }): AxiosPromise<Array<ConfigDataT>> {
    return this.r({
      method: 'GET',
      url: `/parameter/${params.id}/data`,
    });
  }
}

export default function configCRUD(request: AxiosInstance): ConfigDataCRUD {
  return new ConfigDataCRUD({
    url: '/parameter/data',
    request,
    id: 'id',
  });
}
