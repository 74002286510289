import React, { memo } from 'react'
import { Button as DevExtremeButton } from 'devextreme-react/button'
import { GrUpdate } from 'react-icons/gr'

import styles from './ForPeriodTitleSection.module.scss'

interface PropsI {
  from?: string | Date | number
  to?: string | Date | number
  isUpdateButton?: boolean
  isFetching?: boolean
  isDate?: boolean
  onUpdate?: () => void
}

const llFormat = (date: string | Date | number) => {
  if (!date) return '/-/'
  const d = new Date(date)
  return `${d.getDate()}/${d.getMonth() +
    1}/${d.getFullYear()} ${(d.getHours() < 10
    ? '0' + d.getHours()
    : d.getHours()) || '00'}:${(d.getMinutes() < 10
    ? '0' + d.getMinutes()
    : d.getMinutes()) || '00'}`
}

const ForPeriodTitleSection: React.FC<PropsI> = props => {
  const {
    from,
    to,
    isFetching = false,
    onUpdate,
    isUpdateButton = false,
    isDate = true,
  } = props

  const classNameWithDate = isDate ? 'mb-3' : ''

  return (
    <div
      className={`d-flex flex-row justify-content-between align-items-center ${classNameWithDate}`}
    >
      {isDate && (
        <div className="d-flex flex-row">
          <div className="font-weight-bold">За період:</div>
          <div className="ml-2">
            {llFormat(from)} - {llFormat(to)}
          </div>
        </div>
      )}
      {isUpdateButton && (
        <div className={styles.updateSection}>
          <DevExtremeButton
            width={40}
            type="normal"
            stylingMode="contained"
            onClick={onUpdate}
            className={styles.updateButton}
          >
            <GrUpdate
              color="#495057"
              className={isFetching && styles.updateIconFetching}
            />
          </DevExtremeButton>
        </div>
      )}
    </div>
  )
}

export default memo(ForPeriodTitleSection)
