import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid'
import { uniqueId } from 'lodash'
import Spinner from 'react-bootstrap/Spinner'
import { Template } from 'devextreme-react/core/template'

import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import styles from './MonthlyTransportationReportByNode.module.scss'

import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'
import { getPeriodTransportaionNodeReportXls } from '../../store/sagas/transaction-report'

export type SelectedPlaceT = {
  name?: string | number
  deviceId?: number
  id?: number
}

const toTimeStamp = (date: any) => new Date(date).getTime()

const date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth()
const firstDay = new Date(y, m, 1)
const lastDay = new Date(y, m + 1, 0)

function MonthlyTransportationReportByNode() {
  const dispatch = useDispatch()
  const isFetchingCsv = useSelector(
    (store: any) => store.transactionState.isFetchingCsv
  )
  const dataGridRef = useRef<DataGrid>(null)
  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState({
    from: firstDay.setHours(0, 0, 0, 0),
    to: lastDay.setHours(23, 59, 59, 999),
  })

  const monthlyTransportation: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: async () => {
          const res = await api.reports
            .getMonthlyTransportationReportByNode({
              ...period,
              nodeId: place.id,
            })
            .then((result: any) => result.data)
            .catch(storeErrorHandler)
          const _mappedRes = res?.map((item: any) => {
            return {
              ...item,
              id: uniqueId(),
              count:
                Number(item.bcard ?? 0) +
                Number(item.kcard ?? 0) +
                Number(item.qr ?? 0) +
                Number(item.scard ?? 0),
            }
          })

          return _mappedRes
        },
      }),
    [period, place]
  )

  function onChangePeriod(key: string, value: string) {
    setPeriod({
      ...period,
      [key]: toTimeStamp(value),
    })
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const downloadReport = () => {
    dispatch(
      getPeriodTransportaionNodeReportXls({
        ...period,
        nodeId: place.id,
        type: 'xls',
      })
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: updateData,
        },
      }
    )
  }

  useEffect(updateData, [period, place])

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column mb-4">
        <PageTitle
          title="Звіт з перевезень за період вестибюль"
          className="mb-4"
        />
        <ForPeriodTitleSection
          from={period.from}
          to={period.to}
          isUpdateButton={false}
        />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          setSelectedPlace={setPlace}
          selectedPlace={place.name || ''}
        />
      </div>
      <div className="row">
        {period.from && period.to && place.id ? (
          <div className="d-flex flex-column">
            <DataGrid
              ref={dataGridRef}
              dataSource={monthlyTransportation}
              showBorders
              allowColumnReordering={true}
              className={styles.transactionTableContainer}
              remoteOperations={false}
              onToolbarPreparing={onToolbarPreparing}
            >
              <Paging defaultPageSize={100} />
              {/*<Pager showPageSizeSelector={false} /> */}
              {/* <Column
                dataField="repdate"
                caption="Дата"
                dataType="date"
                format="dd/MM/yyyy HH:mm"
                width={150}
              /> */}
              <Column dataField="sname" caption="Станція" width={'auto'} />
              <Column dataField="hname" caption="Вестибюль" width={'auto'} />
              <Column
                dataField="kcard"
                caption="Картка Киянина"
                width={'auto'}
              />
              <Column
                dataField="bcard"
                caption="Банківська картка"
                width={'auto'}
              />
              <Column dataField="scard" caption="Смарт карта" width={'auto'} />
              <Column dataField="qr" caption="QR" width={'auto'} />
              <Column dataField="count" caption="Всього" width={'auto'} />
              <Template name="spinner" render={spinnerComponent} />
            </DataGrid>
          </div>
        ) : (
          <div>
            <span>Необхідно вибрати початкову і кінцеву дату та Станцію</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MonthlyTransportationReportByNode)
