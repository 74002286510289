import { AxiosInstance } from 'axios'
import CRUD from '../base/crud'

export type StateSystemT = {
  query: any
}

export class TranaliticCRUD extends CRUD<StateSystemT> {
  getTranaliticError(params?: any) {
    let url = `${this.config.url}/errors`

    return this.r({
      method: 'GET',
      url,
      params
    })
  }
}

export default function tranaliticCRUD(
  request: AxiosInstance
): TranaliticCRUD {
  return new TranaliticCRUD({
    url: `/tranalitic`,
    request,
    id: 'id',
  })
}
