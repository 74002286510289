import React from 'react';
import { Route, RouteProps } from 'react-router';
import { checkPermission } from '../../helpers/permissions';

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<RouteProps>;
  resource: string;
  role: string;
}

const ProtectedRoute = ({ component: Component, resource, role, ...rest }: ProtectedRouteProps) => {
  const hasAccess = checkPermission(resource, role);
  return hasAccess ? <Route {...rest} component={Component} /> : null;
};

export default ProtectedRoute;
