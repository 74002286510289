import React, { useState, useEffect, useCallback } from 'react'
import { uniqueId } from 'lodash'

const TestForm = ({ value }) => {
  const [funcObj, setFunc] = useState({
    argCount: 0,
    func: null,
    arr: [],
  })
  const [res, setRes] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const handleClick = event => {
    if (funcObj.func) {
      const _arg = []
      Array.prototype.forEach.call(event.target.form.elements, node => {
        if (node.tagName === 'INPUT' && node.dataset.type !== 'result') {
          _arg.push(mormalizeValueOnType(node.value))
        }
      })
      const _res = funcObj.func(..._arg)
      setRes(_res)
    }
  }

  const mormalizeValueOnType = value => {
    if (
      (value[0] === `'` && value[value.length - 1] === `'`) ||
      (value[0] === `"` && value[value.length - 1] === `"`)
    ) {
      return value.slice(1, value.length - 1)
    }
    if (value === 'true' || value === 'false') {
      return Boolean(value)
    }

    if (value === 'null') {
      return null
    }
    if (value === 'undefined' || !value?.length) {
      return undefined
    }
    if (!isNaN(value)) {
      return Number(value)
    } else {
      return value
    }
  }

  const handleClearForm = event => {
    setRes('')
    event.target.form.reset()
  }

  const convertStringToFunctionCode = useCallback(() => {
    try {
      if (!value) return
      if (
        typeof value !== 'string' ||
        !value.toLocaleLowerCase().includes('function')
      ) {
        throw Error('Не валідний код функціЇ, перевірка неможлива!')
      }
      /* eslint-disable no-new-func */
      const _func = new Function(`return ${value}`)

      const f = _func()

      if (typeof f !== 'function') {
        throw Error('Не валідний код функціЇ, перевірка неможлива!')
      }
      const _arr = []
      for (let i = 0; i < f?.length; i++) {
        _arr.push(uniqueId('id_'))
      }
      setFunc({
        argCount: f?.length || 0,
        func: f,
        arr: _arr,
      })
      setErrorMessage('')
    } catch (error) {
      if (error?.message) {
        setErrorMessage(error?.message)
      } else {
        setErrorMessage('Щось пішло не так')
      }
    }
  }, [value])

  useEffect(() => {
    convertStringToFunctionCode()
  }, [convertStringToFunctionCode])

  return (
    <div className="test_form_container">
      {!!value && (
        <form name="fuction_test" className="test_form">
          {funcObj.arr.map((item, index) => {
            return (
              <span key={item} className="test_form_input_item">
                <label className="test_form_input_label">{`Аргумент ${index +
                  1}:`}</label>
                <input
                  type="text"
                  disabled={errorMessage}
                  className="test_form_input"
                />
              </span>
            )
          })}
          {!!res && (
            <span className="test_form_input_item">
              <label className="test_form_input_label res_label">{`Результат:`}</label>
              <input
                type="text"
                disabled={true}
                className="test_form_input res_input"
                value={res}
                data-type="result"
              />
            </span>
          )}
          <span className="test_form_buttons_container">
            <button
              type="button"
              onClick={handleClick}
              disabled={errorMessage || !value}
              className="test_form_button"
            >
              Результат
            </button>
            {!!funcObj.argCount && (
              <button
                type="button"
                onClick={handleClearForm}
                disabled={errorMessage || !value}
                className="test_form_button"
              >
                Очистити
              </button>
            )}
          </span>
        </form>
      )}
      {!!errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </div>
  )
}

export default TestForm
