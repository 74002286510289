/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import FileManager, {
  Permissions,
  ItemView,
  Upload,
} from 'devextreme-react/file-manager'
import CustomFileProvider from 'devextreme/ui/file_manager/file_provider/custom'

import { storeErrorHandler } from '../../../helpers/errorHandling'
import api from '../../../api'
import { FileT } from '../../../api/modules/fw-file'

const GB = 1073741824

type LocalFileT = {
  executable: boolean
  id: number
  size?: number
  name: string
  fileData?: any
}

const maxFileSize = 100 // MB

const CustomFileManager = ({
  data,
  updateData,
  isCreate,
  localFilesData,
}: //setUpdateFilesStatus,
any) => {
  const [files, updateFiles] = useState<FileT[]>([])
  const [localFiles, updateLocalFiles] = useState<LocalFileT[]>([])
  const [localFilesForUpdate, updateFilesForUpdate] = useState<LocalFileT[]>([])
  const [localFilesForRemove, updateFilesForRemove] = useState<number[]>([])

  useEffect(() => {
    setTimeout(() => {
      localFilesData.current.create = localFiles
      localFilesData.current.update = localFilesForUpdate
      localFilesData.current.delete = localFilesForRemove
    }, 500)
    // eslint-disable-next-line
  }, [localFiles, localFilesForUpdate, localFilesForRemove])

  const loadFiles = (id: number) => () =>
    id &&
    api.firmware
      .one(id)
      .then(result => {
        updateFiles([...files, ...result.data.files])
        return result.data.files
      })
      .catch(storeErrorHandler)

  const fileManagerRef = useRef<FileManager>(null)

  useEffect(() => {
    const closeButtonsContainer: HTMLDivElement | null = document.querySelector<
      HTMLDivElement
    >('.dx-treelist-form-buttons-container')
    let buttons: Element[] = []

    if (closeButtonsContainer && closeButtonsContainer.children.length) {
      buttons = Array.from(closeButtonsContainer.children)
      buttons.forEach(button => {
        button.addEventListener('click', updateData)
      })
    }
  }, [updateData])

  if (isEmpty(data.data)) {
    return null
  }

  const removeFromLocalFiles = (fileId: any) =>
    Promise.resolve(
      updateLocalFiles([...localFiles].filter(file => file.id !== fileId))
    )

  const renameLocalFile = (id: any, name: string) =>
    Promise.resolve(
      updateLocalFiles(
        [...localFiles].map(file => {
          if (file.id === id) return { ...file, name }
          return file
        })
      )
    )

  const addFileToLocalList = (fileData: any) => {
    Promise.resolve(
      updateLocalFiles([
        ...localFiles,
        {
          executable: false,
          name: fileData.name,
          fileData,
          id: Math.random(),
          size: fileData.size,
        },
      ])
    )
    if (!isCreate) {
      const file: any = {
        executable: false,
        name: fileData.name,
        fileData,
        id: Math.random(),
        size: fileData.size,
      }
      updateFiles([...files, file])
    }
  }

  const setRenameToUpdateList = (id: number, name: any): void => {
    let isUpdateLocalFile = false
    const renameLocalFiles = [...localFiles].map(file => {
      if (file.id === id) {
        isUpdateLocalFile = true
        return { ...file, name }
      }
      return file
    })
    if (isUpdateLocalFile) {
      updateLocalFiles([...renameLocalFiles])
      return
    }
    const updateLocalFile = files.map(element => {
      const newFile = { ...element }
      if (id === element.id) {
        newFile.name = name
      }
      return newFile
    })
    updateFiles([...updateLocalFile])
    const updatedFiles = localFilesForUpdate.map((element: LocalFileT) => {
      if (element.id === id) {
        const newElement = { ...element }
        newElement.name = name
        return newElement
      }
      return element
    })
    if (updatedFiles.length > 0) {
      Promise.resolve(updateFilesForUpdate([...updatedFiles]))
      return
    }
    const currentFile = files.find(element => element.id === id)
    if (currentFile) {
      currentFile.name = name
      Promise.resolve(
        updateFilesForUpdate([
          ...localFilesForUpdate,
          (currentFile as unknown) as LocalFileT,
        ])
      )
    }
  }

  const setRemoveFileToRemoveList = (id: number) => {
    let removeLocalFiles = false
    const removeFileFromArray = files.filter(
      (element: FileT) => element.id !== id
    )
    const filesWithoutRemoveFile = [...localFilesForUpdate].filter(
      file => file.id !== id
    )
    updateFilesForUpdate([...filesWithoutRemoveFile])
    const createFilesWithputRemoveFile = [...localFiles].filter(file => {
      if (file.id !== id) {
        removeLocalFiles = true
        return false
      }
      return true
    })
    updateLocalFiles([...createFilesWithputRemoveFile])
    updateFiles([...removeFileFromArray])
    if (!removeLocalFiles) {
      Promise.resolve(updateFilesForRemove([...localFilesForRemove, id]))
    }
  }

  // eslint-disable-next-line
  useEffect(() => {
    loadFiles(data.row.data.id)()
    // eslint-disable-next-line
  }, [])

  const fileProvider = new CustomFileProvider({
    getItems: isCreate
      ? () => Promise.resolve(localFiles)
      : () => Promise.resolve(files),
    deleteItem: ({ dataItem: { id } }: any) =>
      isCreate ? removeFromLocalFiles(id) : setRemoveFileToRemoveList(id),
    uploadFileChunk: (file: any) => addFileToLocalList(file),
    renameItem: ({ dataItem: { id } }: any, name: string) =>
      isCreate ? renameLocalFile(id, name) : setRenameToUpdateList(id, name),
    uploadChunkSize: GB,
  })

  const handleExecutedChange = (id: number, executable: boolean) => {
    if (isCreate) {
      updateLocalFiles(
        [...localFiles].map(file => {
          if (file.id === id) return { ...file, executable: executable }
          return file
        })
      )
    } else {
      updateFiles(
        [...files].map(file => {
          if (file.id === id) return { ...file, executable: executable }
          return file
        })
      )
      updateFilesForUpdate(
        [...files].map(file => {
          if (file.id === id) return { ...file, executable: executable }
          return file
        })
      )
    }
  }

  const executableCell = (cell: HTMLElement, cellInfo: any) => {
    const file = cellInfo.row.data.fileItem.dataItem
    const cb = document.createElement('input')

    cb.setAttribute('type', 'checkbox')
    cb.checked = file.executable
    cb.onchange = () => {
      handleExecutedChange(file.id, cb.checked)
    }

    cell.appendChild(cb)
  }

  const fileManagerCustomizeDetailColumns = (columns: any) => {
    columns.push({
      dataField: 'fileItem.executable',
      caption: 'Виконуваний',
      cellTemplate: executableCell,
      alignment: 'center',
      allowSorting: false,
    })

    return columns.map((column: any) => ({ ...column, allowSorting: false }))
  }

  return (
    <FileManager
      ref={fileManagerRef}
      fileProvider={fileProvider}
      selectionMode="single"
      customizeDetailColumns={fileManagerCustomizeDetailColumns}
      allowedFileExtensions={[]}
    >
      <ItemView mode="details" showFolders={false} showParentFolder={false} />
      <Permissions create remove upload rename />
      <Upload maxFileSize={1000000 * maxFileSize} />
    </FileManager>
  )
}

export default CustomFileManager
