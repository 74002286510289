import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uniqueId } from 'lodash'
import CustomStore from 'devextreme/data/custom_store'
import { Template } from 'devextreme-react/core/template'
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  ColumnChooser,
} from 'devextreme-react/data-grid'
import Spinner from 'react-bootstrap/Spinner'

import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import styles from './TransactionsDailyReport.module.scss'
import api from '../../api'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import { getDealyTransactionReportAction } from '../../store/sagas/transaction-report'
import { storeErrorHandler } from '../../helpers/errorHandling'

export type dateTypeT = 'day' | 'range'

export type SelectedPlaceT = {
  name?: any
  id?: any
}

export type SelectedDateT = {
  from?: string | number | null
  to?: number | string | null
}

export type SortDateT = {
  sortColumn: string
  sortOrder: 'DESC' | 'ASC'
}

const TransactionsDailyReport: React.FC = () => {
  const dispatch = useDispatch()

  const isFetchingCsv = useSelector(
    (store: any) => store.transactionState.isFetchingCsv
  )
  const dataGridRef = useRef<DataGrid>(null)
  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState<SelectedDateT>({
    from: null,
    to: null,
  })

  const [dateType, setDateType] = useState<dateTypeT>('day')

  const [sordData, setSortData] = useState<SortDateT>({
    sortColumn: 'TRANSACTIONDATE',
    sortOrder: 'ASC',
  })

  const transactionsDevice: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: ({ skip, ...rest }) => {
          const { sort } = rest || {}
          const [sortDate] = sort || []
          const sortColumn = sortDate?.selector || 'TRANSACTIONDATE'
          const sortOrder = sortDate?.desc ? 'DESC' : 'ASC'
          setSortData({
            sortColumn,
            sortOrder,
          })

          const _page = !skip ? 1 : skip / 100 + 1
          return api.transactionsDevice
            .getDayliTransactionsReport({
              nodeId: place.id,
              ...period,
              page: _page,
              perPage: 100,
              sortColumn: sortColumn,
              sortOrder: sortOrder,
            })
            .then(result => {
              // @ts-ignore
              const { data } = result

              const transactions =
                data?.transactions?.map((t: any) => ({
                  ...t,
                  sumOfCount:
                    Number(t.BANKCARD) +
                    Number(t.TRANSPCARD) +
                    Number(t.RIDANGOCARD) +
                    Number(t.QRCCODE) +
                    Number(t.BTNGATEOPEN) +
                    Number(t.BTNPASSOPEN),
                  id: uniqueId(),
                })) ?? []

              return {
                data: transactions,
                totalCount: data.count,
              }
            })
            .catch(storeErrorHandler)
        },
      }),
    [place, period]
  )

  function onChangePeriod(key: string, value: any) {
    if (dateType === 'day') {
      setPeriod({
        from: value.from,
        to: value.to,
      })
    } else {
      setPeriod({
        ...period,
        [key]: value,
      })
    }
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const downloadReport = () => {
    dispatch(
      getDealyTransactionReportAction({
        nodeId: place.id,
        ...period,
        page: 1,
        perPage: 10000,
        sortColumn: sordData.sortColumn,
        sortOrder: sordData.sortOrder,
        reportType: 'xls',
      })
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: updateData,
        },
      }
    )
  }

  useEffect(() => {
    if (dateType === 'day' && period.to) {
      const nowDay = new Date(period.to).setHours(2, 59, 59, 999)
      setPeriod({
        from: period.from,
        to: nowDay,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType])

  useEffect(updateData, [period, place])

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column">
        <PageTitle title="Звіт по денним транзакціям" className="mb-4" />
        <ForPeriodTitleSection
          from={period.from}
          to={period.to}
          isUpdateButton={false}
        />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          setSelectedPlace={setPlace}
          selectedPlace={place.name || ''}
          handleChangeDateType={setDateType}
        />
      </div>
      <div className="row mb-3">
        {place?.id && period.from && period.to ? (
          <div className="d-flex flex-column mr-3 mb-3 pr-3">
            <DataGrid
              ref={dataGridRef}
              dataSource={transactionsDevice}
              showBorders
              allowColumnReordering={true}
              className={styles.transactionTableContainer}
              remoteOperations={true}
              width="1140"
              height="60vh"
              onToolbarPreparing={onToolbarPreparing}
            >
              <ColumnChooser enabled={true} />
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Paging defaultPageSize={100} />
              <Column
                dataField="TRANSACTIONDATE"
                caption="Дата Транзакції"
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
                width={170}
                alignment="center"
                defaultSortOrder="asc"
              />
              <Column
                dataField="DEVICENAME"
                caption="Пристрiй"
                width={'auto'}
                alignment="left"
              />
              <Column caption="Лічильники" width={'auto'} alignment="center">
                <Column
                  dataField="BANKCARD"
                  caption="Банківська карта"
                  width={'auto'}
                />
                <Column
                  dataField="TRANSPCARD"
                  caption="Транспортна карта"
                  width={'auto'}
                />
                <Column
                  dataField="RIDANGOCARD"
                  caption="Карта ріданго"
                  width={'auto'}
                />
                <Column dataField="QRCCODE" caption="QR код" width={'auto'} />
                <Column
                  dataField="BTNGATEOPEN"
                  caption="Кнопка хвіртка"
                  width={'auto'}
                />
                <Column
                  dataField="BTNPASSOPEN"
                  caption="Кнопка трипод"
                  width={'auto'}
                />
                <Column dataField="sumOfCount" caption="Сумма" width={'auto'} />
              </Column>
              <Column
                caption="Фіскальні лічильники"
                width={'auto'}
                alignment="center"
              >
                <Column
                  dataField="BANKCARDFISC"
                  caption="Банківська карта"
                  width={'auto'}
                />
                <Column
                  dataField="TRANSPCARDFISC"
                  caption="Транспортна карта"
                  width={'auto'}
                />
                <Column
                  dataField="RIDANGOCARDFISC"
                  caption="Карта ріданго"
                  width={'auto'}
                />
                <Column
                  dataField="QRCCODEFISC"
                  caption="QR код"
                  width={'auto'}
                />
              </Column>
              <Column caption="Транзакція" width={'auto'} alignment="center">
                <Column
                  dataField="FIRSTTRANSNUM"
                  caption="с N"
                  width={'auto'}
                />
                <Column
                  dataField="LASTTRANSNUM"
                  caption="по N"
                  width={'auto'}
                />
              </Column>
              <Column
                dataField="ONLINE"
                caption="Онлайн"
                cellRender={OnlineCellRender}
                width={'auto'}
                alignment="center"
              />
              <Template name="spinner" render={spinnerComponent} />
            </DataGrid>
          </div>
        ) : (
          <div>
            <span>Необхідно вибрати пристрій та початкову і кінцеву дату</span>
          </div>
        )}
      </div>
    </div>
  )
}

const OnlineCellRender = (props: any) => {
  const { data } = props
  const { online } = data
  const text = online === 0 ? 'Offline' : 'Online'
  const color = online === 0 ? 'blue' : 'green'
  return (
    <span
      className="badge"
      style={{ backgroundColor: color, color: '#fff', width: 'auto' }}
    >
      {text}
    </span>
  )
}

export default TransactionsDailyReport
