import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { uniqueId } from 'lodash'
import CustomStore from 'devextreme/data/custom_store'
import { Spinner } from 'react-bootstrap'
import DataGrid, { Column, Paging, Pager } from 'devextreme-react/data-grid'
import { Template } from 'devextreme-react/core/template'

import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import styles from './RouteSheetByNumberReport.module.scss'
import api from '../../api'
import { getTransactionListByPatternCsvPdfAction } from '../../store/sagas/transaction-report'
//import { storeErrorHandler } from '../../helpers/errorHandling'
import { localizedServerErrors } from '../../constants/errorsLocalization'

const toTimeStamp = (date: any) => new Date(date).getTime()

export type SelectedDateT = {
  from?: string | number | null
  to?: number | string | null
}

const RouteSheetByNumberReport: React.FC = () => {
  const toDate = new Date().getTime()
  const fromDate = new Date().setFullYear(new Date().getFullYear() - 1)

  const location: any = useLocation()
  const query = new URLSearchParams(location.search)
  const pattern = query.get('pattern')
  const dispatch = useDispatch()
  const isFetchingCsv = useSelector(
    (state: any) => state?.transactionState?.isFetchingCsv
  )
  const dataGridRef = useRef<DataGrid>(null)

  const [patternIdent, setPatternIdent] = useState('')

  const [period, setPeriod] = useState<SelectedDateT>({
    from: fromDate,
    to: toDate,
  })

  const [error, setError] = useState('')

  const transactionsDevice: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: () => {
          return api.reports
            .getTransportationListByPatternReport({
              pattern: patternIdent, //'3804401100007981363',
              ...period,
            })
            .then(result => {
              // @ts-ignore
              const { data } = result

              const transactions =
                data?.transactions?.map((t: any) => ({
                  ...t,
                  id: uniqueId(),
                })) ?? []

              if (error) {
                setError('')
              }
              return {
                data: transactions,
                totalCount: data.count,
              }
            })
            .catch(e => {
              const errorCode = e.response?.data?.code
              const message =
                errorCode && localizedServerErrors[errorCode]
                  ? localizedServerErrors[errorCode]
                  : localizedServerErrors['???']
              setError(message)
            })
        },
      }),
    [period, patternIdent, error]
  )

  function onChangePeriod(key: string, value: string) {
    setPeriod({
      ...period,
      [key]: toTimeStamp(value),
    })
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const downloadReport = () => {
    dispatch(
      getTransactionListByPatternCsvPdfAction({
        from: period.from,
        to: period.to,
        reportType: 'xls',
        pattern: patternIdent,
      })
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: updateData,
        },
      }
    )
  }

  const _setPatternIdent = (ident: any) => {
    if (error) {
      setError('')
    }
    setPatternIdent(ident)
  }

  useLayoutEffect(() => {
    if (pattern) {
      // const d = new Date().getTime()
      // const from = new Date(d).setHours(3, 0, 0, 0)
      setPatternIdent(pattern)
      // setPeriod({
      //   from: new Date(from).getTime(),
      //   to: d,
      // })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    query.set('pattern', patternIdent)
    window.history.replaceState(null, '', '?' + query.toString())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patternIdent])

  useEffect(updateData, [period])

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column mb-3">
        <PageTitle title="Маршрутний лист за номером" className="mb-4" />
        <ForPeriodTitleSection
          from={period.from}
          to={period.to}
          isUpdateButton={false}
        />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          patternIdent={patternIdent}
          setPatternIdent={_setPatternIdent}
        />
      </div>
      <div className="row d-flex">
        {period.to && period.from && patternIdent ? (
          !!error ? (
            <div className="d-flex justify-content-center w-100">
              <strong>{error}</strong>
            </div>
          ) : (
            <div className="d-flex flex-column mb-3">
              <DataGrid
                ref={dataGridRef}
                dataSource={transactionsDevice}
                showBorders
                allowColumnReordering={true}
                className={styles.transactionTableContainer}
                onToolbarPreparing={onToolbarPreparing}
              >
                <Paging defaultPageSize={100} />
                <Pager showPageSizeSelector={false} />
                <Column
                  dataField="transactionDate"
                  caption="Дата Транзакції"
                  dataType="date"
                  format="dd/MM/yyyy HH:mm:ss"
                  width={150}
                  defaultSortOrder="desc"
                />
                <Column
                  dataField="transactionNum"
                  caption="Номер Транзакції"
                  width={'auto'}
                />
                <Column
                  dataField="transactionType"
                  caption="Тип Транзакції"
                  width={'auto'}
                />
                <Column
                  dataField="deviceId"
                  caption="Термінал"
                  width={'auto'}
                />
                <Column
                  dataField="ident"
                  caption="Ідентифікатор"
                  width={'auto'}
                />
                <Column
                  dataField="online"
                  caption="Онлайн"
                  cellRender={OnlineCellRender}
                  width={'auto'}
                />
                <Column
                  dataField="errorCode"
                  caption="Код помилки"
                  width={'auto'}
                />
                <Column
                  dataField="errorMessage"
                  caption="Помилка"
                  width={'auto'}
                />
                <Template name="spinner" render={spinnerComponent} />
              </DataGrid>
            </div>
          )
        ) : (
          <div>
            <span>
              Необхідно ввести "Номер Ідентифікатора" та початкову і кінцеву
              дату
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

const OnlineCellRender = (props: any) => {
  const { data } = props
  const { online } = data
  const text = online === 0 ? 'Offline' : 'Online'
  const color = online === 0 ? 'blue' : 'green'
  return (
    <span
      className="badge"
      style={{ backgroundColor: color, color: '#fff', width: 'auto' }}
    >
      {text}
    </span>
  )
}

export default RouteSheetByNumberReport
