import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid'
import { Template } from 'devextreme-react/core/template'
import ScrollView from 'devextreme-react/scroll-view'
import { Popup } from 'devextreme-react/popup'

import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import styles from './AuditOfSettings.module.scss'
import api from '../../api'

import { storeErrorHandler } from '../../helpers/errorHandling'

import { getAuditSettingReportXls } from '../../store/sagas/transaction-report'

export type dateTypeT = 'day' | 'range'

export type SelectedDateT = {
  from?: string | number | null
  to?: number | string | null
}
export type SelectedMethodT = {
  changeSelectedMethod: (data: any) => void
  selectedMethod: any
}

const AuditOfSettings: React.FC = () => {
  const dispatch = useDispatch()
  const isFetchingCsv = useSelector(
    (state: any) => state?.transactionState?.isFetchingCsv
  )

  const dataGridRef = useRef<DataGrid>(null)
  const [period, setPeriod] = useState<SelectedDateT>({
    from: null,
    to: null,
  })
  const [dateType, setDateType] = useState<dateTypeT>('day')

  const [selectedMethod, setSelectedMethod] = useState([])

  const [selectedEntity, setSelectedEntity] = useState([])

  const [isOpen, setIsOpen] = useState(false)

  const [dataForPopup, setDataForPopup] = useState('')

  const handleToopgePopup = () => {
    setIsOpen(p => !p)
  }

  const transactionsDevice: any = useMemo(() => {
    return new CustomStore({
      key: 'id',
      load: () => {
        return api.audit
          .getSettingAuditReport({
            ...period,
            method: selectedMethod.join(),
            entity: selectedEntity.join(),
          })
          .then(result => {
            // @ts-ignore
            const { data } = result
            return data
          })
          .catch(storeErrorHandler)
      },
    })
  }, [period, selectedMethod, selectedEntity])

  function onChangePeriod(key: string, value: any) {
    if (dateType === 'day') {
      setPeriod({
        from: value.from,
        to: value.to,
      })
    } else {
      setPeriod({
        ...period,
        [key]: value,
      })
    }
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const downloadReport = () => {
    dispatch(
      getAuditSettingReportXls({
        reportType: 'xls',
        ...period,
        method: selectedMethod.join(),
        entity: selectedEntity.join(),
        perPage: 10000,
        page: 1,
      })
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: updateData,
        },
      }
    )
  }

  useEffect(() => {
    if (dateType === 'day' && period.to) {
      const nowDay = new Date(period.from).setHours(23, 59, 59, 999)
      setPeriod({
        from: period.from,
        to: nowDay,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType])

  useEffect(updateData, [period])

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column">
        <PageTitle title="Аудит зміни налаштувань" className="mb-2" />
        <ForPeriodTitleSection from={period.from} to={period.to} />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          handleChangeDateType={setDateType}
          selectedMethod={selectedMethod}
          changeSelectedMethod={setSelectedMethod}
          selectedEntity={selectedEntity}
          changeSelectedEntity={setSelectedEntity}
        />
      </div>
      <div className="row">
        {period.from && period.to ? (
          <div className="d-flex flex-column">
            <DataGrid
              ref={dataGridRef}
              dataSource={transactionsDevice}
              showBorders
              allowColumnReordering={true}
              className={styles.transactionTableContainer}
              width="1140"
              onToolbarPreparing={onToolbarPreparing}
            >
              <Paging defaultPageSize={100} />
              <Column
                dataField="createdAt"
                caption="Дата"
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
                width={150}
              />
              <Column
                dataField="user"
                caption="Ім'я користувача"
                //width={130}
                cssClass={styles.wordWrap}
              />
              <Column
                dataField="ip"
                caption="IP адреса"
                //width={90}
                cssClass={styles.wordWrap}
              />
              <Column
                dataField="route"
                caption="Шлях"
                //width={170}
                cssClass={styles.wordWrap}
              />
              <Column
                dataField="data"
                caption="Тіло запиту"
                width={110}
                //cssClass={styles.wordWrap}
                cellRender={props => (
                  <RenderDataCell
                    {...props}
                    setDataForPopup={setDataForPopup}
                    handleToopgePopup={handleToopgePopup}
                  />
                )}
              />
              <Column
                dataField="method"
                caption="Тип методу"
                width={110}
                cssClass={styles.wordWrap}
                cellRender={RenderMethodCell}
              />
              <Column
                dataField="entity"
                caption="Категорія"
                width={140}
                cssClass={styles.wordWrap}
              />
              <Template name="spinner" render={spinnerComponent} />
            </DataGrid>
          </div>
        ) : (
          <div>
            <span>Необхідно вибрати дату</span>
          </div>
        )}
        <Popup
          visible={isOpen}
          onHiding={handleToopgePopup}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          title={`Дата зніни налаштувань`}
          width={700}
          height={'auto'}
          maxHeight={'90vh'}
        >
          <ScrollView width="100%" height="100%">
            <code>
              {dataForPopup
                ? JSON.parse(`[${dataForPopup}]` || '[]').map(
                    (item: any, index: string | number | undefined) => {
                      return (
                        <pre className="raw_tax_summary_text" key={index}>
                          {JSON.stringify(item, null, 2)}
                        </pre>
                      )
                    }
                  )
                : null}
            </code>
          </ScrollView>
        </Popup>
      </div>
    </div>
  )
}

const RenderMethodCell = props => {
  return (
    <span className={`${styles.methodCell} ${styles[props.data.method]}`}>
      {props.data.method}
    </span>
  )
}

const RenderDataCell = props => {
  const { data, handleToopgePopup, setDataForPopup } = props

  const _handleToopgePopup = () => {
    setDataForPopup(data.data)
    handleToopgePopup(true)
  }
  return (
    <>
      <button
        onClick={_handleToopgePopup}
        className={styles.transactionsDataContentButton}
      >
        {data.data}
      </button>
    </>
  )
}

export default AuditOfSettings
