import React, { useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import CustomStore from 'devextreme/data/custom_store'
import { cloneDeep } from 'lodash'

import PageTitle from '../../components/PageTitle/PageTitle'
import styles from './VersionsReportsPage.module.scss'

import Table from './components/table/Table'
import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'

const checkMetricValue = (mv: any) => {
  if (!mv || typeof mv !== 'string') return mv
  const mvToArr = mv.split('.')

  if (mvToArr?.length === 2) {
    return `${mv}.0`
  }
  return mv
}

const filteredDevice = (data: any) => {
  const _data = cloneDeep(data)

  const filteredFunc = (children: any) => {
    children.forEach((item: any) => {
      if (
        item.metrics &&
        item.metrics.length &&
        item.metrics.some((m: any) => m.metricName === 'Version')
      ) {
        if (item.device?.lastFirmwareVersion) {
          item.lastFirmwareVersion = item.device?.lastFirmwareVersion || ''
        }
        const needMetric =
          item.metrics.find((m: any) => m.metricName === 'Version') || {}

        const _transformMetricValue = checkMetricValue(needMetric.metricValue)

        item.metricValue = _transformMetricValue
        item.metricUpdatedAt = needMetric.metricUpdatedAt
      }
      if (item.children && item.children.length) {
        filteredFunc(item.children)
      }
    })
  }

  filteredFunc(_data.children)

  return _data
}

const VersionsReportsPage: React.FC = () => {
  const [isCollapsed, setCollapseStatus] = useState(false)

  const firmwareData: any = useMemo(
    () =>
      new CustomStore({
        key: 'key',
        load: async () => {
          return api.reports
            .getSoftwareVersionReport()
            .then(result => {
              const res = filteredDevice(result.data)
              return res.children
            })
            .catch(storeErrorHandler)
        },
      }),
    []
  )

  return (
    <div className="mb-3">
      <div className="container">
        <div className="row justify-content-between mb-4">
          <div className="column">
            <PageTitle title="Версії ПЗ" />
          </div>

          <div className="column justify-content-end mt-3">
            <div className="container">
              <div className="row justify-content-end align-items-center">
                <Button
                  onClick={() => setCollapseStatus(!isCollapsed)}
                  variant="outline-primary"
                  className={styles.downloadButtonWide}
                >
                  {!isCollapsed ? 'Розгорнути' : 'Згорнути'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Table isCollapsed={isCollapsed} data={firmwareData} />
    </div>
  )
}

export default VersionsReportsPage
