import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';

export type MetricT = {
  id: number;
  value: Buffer;
  updatedAt: string;
  attributeId: number;
  deviceId: number;
};

export class MetricCRUD extends CRUD<MetricT> {
  getDeviceMetrics(id: any) {
    let url = `/metric/device/${id}`
    return this.r({
      method: 'GET',
      url,
    });
}
}

export default function metricCRUD(request: AxiosInstance): MetricCRUD {
  return new MetricCRUD({
    url: '/metric',
    request,
    id: 'id',
  });
}
