import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';

export type AuditTypeT = {

};

export class AuditCRUD extends CRUD<AuditTypeT> {
  getSettingAuditReport(params: any) {
    let url = `${this.config.url}`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params.reportType ? 'blob': 'json',
    })
  }

  getSettingAuditEntities() {
    let url = `${this.config.url}/entities`

    return this.r({
      method: 'GET',
      url,
    })
  }
}

export default function auditCRUD(request: AxiosInstance): AuditCRUD {
  return new AuditCRUD({
    url: '/audit',
    request,
    id: 'id',
  });
}
