import { combineReducers } from 'redux';

import data, { StateT as DataStateT } from './data';
import load, { StateT as LoadStateT } from './load';
import create, { StateT as CreateStateT } from './create';
import update, { StateT as UpdateStateT } from './update';
import del, { StateT as DeleteStateT } from './delete';

export type UserStateT = {
  data: DataStateT;
  load: LoadStateT;
  create: CreateStateT;
  update: UpdateStateT;
  delete: DeleteStateT;
};

export default combineReducers({
  data,
  load,
  create,
  update,
  delete: del,
});
