import api from '../../../api';
import { API_REQUEST, ApiRequestT } from '../../apiAction';
import { UserT } from '../../../api/modules/user';

const CREATE: 'user/CREATE' = 'user/CREATE';
export const CREATE_SUCCESS: 'user/CREATE_SUCCESS' = 'user/CREATE_SUCCESS';
const CREATE_FAILURE: 'user/CREATE_FAILURE' = 'user/CREATE_FAILURE';

type CreateT = {
  type: typeof CREATE;
};
export type CreateSuccessT = {
  type: typeof CREATE_SUCCESS;
  result: {
    data: UserT;
  };
};
type CreateFailureT = {
  type: typeof CREATE_FAILURE;
  error: string | null;
};

type ActionTypeT = CreateT | CreateSuccessT | CreateFailureT;

export type StateT = {
  creating: boolean;
  error: string | null;
};

const initialState: StateT = {
  creating: false,
  error: null,
};

export default function userCreateReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {
  switch (action.type) {
    case CREATE:
      return {
        ...initialState,
        creating: true,
      };

    case CREATE_SUCCESS:
      return {
        ...initialState,
      };

    case CREATE_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
}

export function createUser(data: UserT): ApiRequestT<UserT> {
  return {
    type: API_REQUEST,
    types: [CREATE, CREATE_SUCCESS, CREATE_FAILURE],
    call: () => api.user.create(data),
  };
}
