import React, { useRef } from 'react'
import DataGrid, {
  Column,
  Editing,
  Form,
  RequiredRule,
} from 'devextreme-react/data-grid'
import { Item, GroupItem } from 'devextreme-react/form'
import axios from 'axios'
import { CustomRule } from 'devextreme-react/validator'
import CustomStore from 'devextreme/data/custom_store'

import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'
import {
  publicKeyCredentialToJSON,
  preformatMakeCredReq,
} from '../../helpers/webauthn'
import appConfig from '../../config'
import { validationKeyName } from '../../helpers/validation'
import { localizedValidationErrors } from '../../constants/errorsLocalization'
import EditTextInput from '../../components/EditTextInput/EditTextInput'

const MyKeysPage: React.FC = () => {
  const dataGridRef = useRef<DataGrid>(null)

  const customStore: any = new CustomStore({
    key: 'id',
    load: () =>
      api.key
        .me()
        .then((result: any) => result.data)
        .catch(storeErrorHandler),
    remove: id => api.key.delete(id).catch(storeErrorHandler),
    update: (id, data) =>
      api.key.update({ id, ...data }).catch(storeErrorHandler),
    insert: data =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        try {
          const token = localStorage.getItem('accessToken')
          const response = await axios.post(
            `${appConfig.baseUrl}/user/webauthn/create`,
            data,
            {
              headers: {
                'x-access-token': token,
              },
            }
          )
          if (!response.data) {
            throw Error('Invalid backend error')
          }

          const publicKey = preformatMakeCredReq(response.data)
          const credentials = (await navigator.credentials.create({
            publicKey,
          })) as any
          const json = publicKeyCredentialToJSON(credentials)

          json.keyName = data.keyName

          await axios.post(
            `${appConfig.baseUrl}/user/webauthn/create/confirm`,
            json,
            {
              headers: {
                'x-access-token': token,
              },
            }
          )
          const updateData = api.key.me()
          if (updateData) {
            resolve(updateData)
          } else {
            reject(new Error('Не вдалося строрити ключ'))
          }
        } catch (error) {
          reject(error)
        }
      }),
  })

  return (
    <DataGrid
      ref={dataGridRef}
      id="key"
      showBorders
      dataSource={customStore}
      repaintChangesOnly
    >
      <Column
        dataField="keyName"
        editCellRender={cellOption => (
          <EditTextInput
            cellOption={cellOption}
            textBoxParams={{ maxLength: 255 }}
          />
        )}
      >
        <RequiredRule />
        <CustomRule
          validationCallback={validationKeyName}
          message={localizedValidationErrors.keyName}
        />
      </Column>

      <Column dataField="publicKey" />
      <Column dataField="credentialID" />
      <Column dataField="createdAt" />

      <Editing
        refreshMode="full"
        mode="form"
        allowDeleting
        allowAdding
        useIcons
      >
        <Form activeStateEnabled focusStateEnabled colCount="1">
          <GroupItem colCount="2">
            <Item dataField="keyName" />
          </GroupItem>
        </Form>
      </Editing>
    </DataGrid>
  )
}

export default MyKeysPage
