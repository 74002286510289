import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';

export type FileT = {
  id: number;
  name: string;
  path: string;
  sha256: string;
  executable: boolean;
};

export class FwFileCRUD extends CRUD<any> {}

export default function fwFileCrud(request: AxiosInstance): FwFileCRUD {
  const crud = new FwFileCRUD({
    url: '/fw-file',
    request,
    id: 'id',
  });

  return crud;
}
