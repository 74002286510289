import { takeEvery, call, put } from 'redux-saga/effects'
import { saveAs } from 'file-saver'

import api from '../../api'

const { transactionsDevice, reports, audit } = api

export const GET_UNPROCESSED_TRANSACTION_REPORT_CSV_PDF =
  'GET_UNPROCESSED_TRANSACTION_REPORT_CSV_PDF'
export const GET_DEALY_TRANSACTION_REPORT = 'GET_DEALY_TRANSACTION_REPORT'
export const SET_IS_FETCHING_CSV_REPORT = 'SET_IS_FETCHING_CSV_REPORT'
export const GET_TRANSACTIONS_REPORT_PDF_CSV = 'GET_TRANSACTIONS_REPORT_PDF_CSV'
export const GET_TRANSACTIONS_LIST_BY_PATTERN_PDF_CSV =
  'GET_TRANSACTIONS_LIST_BY_PATTERN_PDF_CSV'
export const GET_MOUNTLY_TRANSPORTATIO_LOBBY_REPORT_PDF_CSV =
  'GET_MOUNTLY_TRANSPORTATIO_LOBBY_REPORT_PDF_CSV'
export const GET_ENTRANCE_LOAD_REPORT_PDF_CSV = 'GET_ENTRANCE_LOAD_REPORT_PDF_CSV'
export const GET_CONFIGURATION_HALL_REPORT_PDF_XLS = 'GET_CONFIGURATION_HALL_REPORT_PDF_XLS'
export const GET_CONFIGURATION_TURNSTYLIES_REPORT_PDF_XLS = 'GET_CONFIGURATION_TURNSTYLIES_REPORT_PDF_XLS'
export const GET_MONTHLY_TRANSACTION_REPORT_XLS = 'GET_MONTHLY_TRANSACTION_REPORT_XLS'

export const GET_PERIOD_TRANSPORTATION_NODE_REPORT_XLS = 'GET_PERIOD_TRANSPORTATION_NODE_REPORT_XLS'

export const GET_METRICS_STATE_ON_DEVICE_REPORT_XLS = 'GET_METRICS_STATE_ON_DEVICE_REPORT_XLS'

export const GET_EVENTS_TURNSTILES_REPORT_XLS = 'GET_EVENTS_TURNSTILES_REPORT_XLS'

export const GET_SETTING_AUDIT_REPORT_XLS = 'GET_SETTING_AUDIT_REPORT_XLS'

export const getAuditSettingReportXls = (payload: any) => ({
  type: GET_SETTING_AUDIT_REPORT_XLS,
  payload,
})

export const getEventsTurnstilesReportXls = (payload: any) => ({
  type: GET_EVENTS_TURNSTILES_REPORT_XLS,
  payload,
})

export const getMetricsStateOnDeviceReportXls = (payload: any) => ({
  type: GET_METRICS_STATE_ON_DEVICE_REPORT_XLS,
  payload,
})

export const getPeriodTransportaionNodeReportXls = (payload: any) => ({
  type: GET_PERIOD_TRANSPORTATION_NODE_REPORT_XLS,
  payload,
})

export const getMonthlyTransactionReportXls = (payload: any) => ({
  type: GET_MONTHLY_TRANSACTION_REPORT_XLS,
  payload,
})

export const getEntranceLoadReportCsvPdfAction = (payload: any) => ({
  type: GET_ENTRANCE_LOAD_REPORT_PDF_CSV,
  payload,
})

export const getTransportationLobbyReportCsvPdfAction = (payload: any) => ({
  type: GET_MOUNTLY_TRANSPORTATIO_LOBBY_REPORT_PDF_CSV,
  payload,
})

export const getUnprocessedTransactionReportCsvPdfAction = (payload: any) => ({
  type: GET_UNPROCESSED_TRANSACTION_REPORT_CSV_PDF,
  payload,
})

export const getTransactionReportCsvPdfAction = (payload: any) => ({
  type: GET_TRANSACTIONS_REPORT_PDF_CSV,
  payload,
})

export const getTransactionListByPatternCsvPdfAction = (payload: any) => {
  return {
    type: GET_TRANSACTIONS_LIST_BY_PATTERN_PDF_CSV,
    payload,
  }
}

export const getDealyTransactionReportAction = (payload: any) => ({
  type: GET_DEALY_TRANSACTION_REPORT,
  payload,
})

export const getConfigurationHallReportAction = (payload: any) => ({
  type: GET_CONFIGURATION_HALL_REPORT_PDF_XLS,
  payload,
})

export const getConfigurationTurnstyliesReportAction = (payload: any) => ({
  type: GET_CONFIGURATION_TURNSTYLIES_REPORT_PDF_XLS,
  payload,
})

export const setIsFetchingCsvReport = (payload: any) => ({
  type: SET_IS_FETCHING_CSV_REPORT,
  payload,
})

function* getUnprocessedTransactionReportCsv(action: any) {
  const { payload = {} } = action
  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getUnprocessedTransactionsReportCsv'],
      payload
    )
    const { data, status } = res

    const to = new Date(payload.to).toLocaleDateString()
    const from = new Date(payload.from).toLocaleDateString()

    if (status === 200) {
      saveAs(data, `unprocessed-transactions-report-${from}-${to}.xls`)
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getDealyTransactionReport(action: any) {
  const { payload = {} } = action
  try {
    yield put(setIsFetchingCsvReport(true))

    const res = yield call(
      [transactionsDevice, 'getDayliTransactionsReport'],
      payload
    )

    const { data, status } = res

    const to = new Date(payload.to).toLocaleDateString()
    const from = new Date(payload.from).toLocaleDateString()

    if (status === 200) {
      saveAs(
        data,
        `daily-transactions-devices-report-${from}-${to}.${payload.reportType}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getTransactionReportCsvPdf(action: any) {
  const { payload = {} } = action
  //const { id, params } = payload
  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [transactionsDevice, 'getTransactionsReportCsvPdf'],
      payload
    )
    const { data, status } = res

    const to = new Date(payload.to).toLocaleDateString()
    const from = new Date(payload.from).toLocaleDateString()

    if (status === 200) {
      saveAs(data, `transactions-report-${from}-${to}.${payload.type}`)
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getTransactionListByPatternCsvPdf(action: any) {
  const { payload = {} } = action
  //const { type, ...rest } = payload

  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getTransportationListByPatternReport'],
      payload
    )
    const { data, status } = res

    const to = new Date(payload.to).toLocaleDateString()
    const from = new Date(payload.from).toLocaleDateString()

    if (status === 200) {
      saveAs(
        data,
        `transactions-list-by-pattern-${payload.pattern}-${from}-${to}.${payload.reportType}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getMountlyTransportationReport(action: any) {
  const { payload: params = {} } = action


  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getMonthlyTransportationReportLobby'],
      params
    )
    const { data, status } = res

    const to = new Date(params.to).toLocaleDateString()
    const from = new Date(params.from).toLocaleDateString()

    if (status === 200) {
      saveAs(
        data,
        `mountly-transportation-report-lobby-${from}-${to}.${params.reportType}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getEntranceLoadReportPdfCsv(action: any) {
  const { payload: params = {} } = action


  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getEntranceLoadReportApi'],
      params
    )
    const { data, status } = res

    const to = new Date(params.to).toLocaleDateString()
    const from = new Date(params.from).toLocaleDateString()

    if (status === 200) {
      saveAs(
        data,
        `entrance-load-report-${from}-${to}.${params.reportType}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getConfigurationReportPdfXls(action: any) {
  const { payload: params = {} } = action
  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getConfigurationHallReporpsApi'],
      params
    )
    const { data, status } = res

    if (status === 200) {
      saveAs(
        data,
        `configuration-hall-report.${params.reportType}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getConfigurationTurnstilesReportPdfXls(action: any) {
  const { payload: params = {} } = action
  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getConfigurationTurnstileReporpsApi'],
      params
    )
    const { data, status } = res

    if (status === 200) {
      saveAs(
        data,
        `configuration-turnstile-report-report.${params.reportType}`
      )
    }////turnstile-report
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getMonthlyTransactionReportXlsSaga(action: any) {
  const { payload: params = {} } = action
  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getMonthlyTransportationReport'],
      params
    )
    const { data, status } = res

    const to = new Date(params.to).toLocaleDateString()
    const from = new Date(params.from).toLocaleDateString()

    if (status === 200) {
      saveAs(
        data,
        `monthly-transaction-report-${from}-${to}.${params.type}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getPeriodTransportationNodeReportXlsSaga(action: any) {
  const { payload: params = {} } = action
  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getMonthlyTransportationReportByNode'],
      params
    )
    const { data, status } = res

    const to = new Date(params.to).toLocaleDateString()
    const from = new Date(params.from).toLocaleDateString()

    if (status === 200) {
      saveAs(
        data,
        `period-transportation-node-report-${from}-${to}.${params.type}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getMetricsStateOnDeviceReportXlsSaga(action: any) {
  const { payload: params = {} } = action
  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getMetricsStateOfDeviceReportApi'],
      params
    )
    const { data, status } = res

    const to = new Date(params.to).toLocaleDateString()
    const from = new Date(params.from).toLocaleDateString()

    if (status === 200) {
      saveAs(
        data,
        `metrics-state-on-device-report-${from}-${to}.${params.reportType}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getEventsTurnstilesReportXlsSaga(action: any) {
  const { payload: params = {} } = action
  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [reports, 'getEventTurnstilesReport'],
      params
    )
    const { data, status } = res

    const to = new Date(params.to).toLocaleDateString()
    const from = new Date(params.from).toLocaleDateString()

    if (status === 200) {
      saveAs(
        data,
        `events-turnstiles-report-${from}-${to}.${params.reportType}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}

function* getAuditSettingsReportSaga(action: any) {
  const { payload: params = {} } = action
  try {
    yield put(setIsFetchingCsvReport(true))
    const res = yield call(
      [audit, 'getSettingAuditReport'],
      params
    )
    const { data, status } = res

    const to = new Date(params.to).toLocaleDateString()
    const from = new Date(params.from).toLocaleDateString()

    if (status === 200) {
      saveAs(
        data,
        `settings-audit-report-${from}-${to}.${params.reportType}`
      )
    }
  } catch (error) {
    // to do
  } finally {
    yield put(setIsFetchingCsvReport(false))
  }
}



export default function* transactionReportWorker() {
  yield takeEvery(
    GET_UNPROCESSED_TRANSACTION_REPORT_CSV_PDF,
    getUnprocessedTransactionReportCsv
  )
  yield takeEvery(GET_DEALY_TRANSACTION_REPORT, getDealyTransactionReport)
  yield takeEvery(GET_TRANSACTIONS_REPORT_PDF_CSV, getTransactionReportCsvPdf)
  yield takeEvery(
    GET_TRANSACTIONS_LIST_BY_PATTERN_PDF_CSV,
    getTransactionListByPatternCsvPdf
  )
  yield takeEvery(
    GET_MOUNTLY_TRANSPORTATIO_LOBBY_REPORT_PDF_CSV,
    getMountlyTransportationReport
  )
  yield takeEvery(GET_ENTRANCE_LOAD_REPORT_PDF_CSV, getEntranceLoadReportPdfCsv)
  yield takeEvery(GET_CONFIGURATION_HALL_REPORT_PDF_XLS, getConfigurationReportPdfXls)
  yield takeEvery(GET_CONFIGURATION_TURNSTYLIES_REPORT_PDF_XLS, getConfigurationTurnstilesReportPdfXls)

  yield takeEvery(GET_MONTHLY_TRANSACTION_REPORT_XLS, getMonthlyTransactionReportXlsSaga)
  yield takeEvery(GET_PERIOD_TRANSPORTATION_NODE_REPORT_XLS, getPeriodTransportationNodeReportXlsSaga)
  yield takeEvery(GET_METRICS_STATE_ON_DEVICE_REPORT_XLS, getMetricsStateOnDeviceReportXlsSaga)
  yield takeEvery(GET_EVENTS_TURNSTILES_REPORT_XLS, getEventsTurnstilesReportXlsSaga)
  yield takeEvery(GET_SETTING_AUDIT_REPORT_XLS, getAuditSettingsReportSaga)
}


//GET_SETTING_AUDIT_REPORT_XLS
