import React from 'react'
import { DateBox } from 'devextreme-react'
import cx from 'clsx'

//import DropDownDevicesTree from '../dropDownDevicesTree/DropDownDevicesTree'
import styles from './Filter.module.scss'
import { SelectedPlaceT } from '../../TransactionsProcesingReportPage'
const DATE_BOX_WIDTH = 280

interface FilterInterface {
  onChangePeriod: (key: string, value: any) => void
  from: any
  to: any
  setSelectedPlace: (data: SelectedPlaceT) => void
  selectedPlace: string | number
}

const Filter = ({
  onChangePeriod,
  /* setSelectedPlace, selectedPlace, */ from,
  to,
}: FilterInterface) => {
  function updateDataFrom(event: any) {
    if (event.value) {
      onChangePeriod('from', event.value)
    } else {
      onChangePeriod('from', new Date().setHours(0, 0, 0, 0))
    }
  }

  function updateDataTo(event: any) {
    if (event.value) {
      onChangePeriod('to', event.value)
    } else {
      onChangePeriod('to', new Date().setHours(23, 59, 59, 999))
    }
  }

  const _dateFrom = new Date(from)
  const _dateTo = new Date(to)

  return (
    <div className="row ml-0">
      <div className={'column'}>
        <div className="font-weight-bold">Початок</div>
        <DateBox
          width={DATE_BOX_WIDTH}
          type="date"
          onValueChanged={updateDataFrom}
          showClearButton
          max={typeof to === 'number' && to !== 0 ? to : undefined}
          placeholder={_dateFrom.toLocaleDateString()}
        />
      </div>

      <div className={cx('column', styles.filterItem)}>
        <div className="font-weight-bold">Кінець</div>
        <DateBox
          width={DATE_BOX_WIDTH}
          type="date"
          onValueChanged={updateDataTo}
          showClearButton
          min={from}
          max={_dateFrom.setMonth(_dateFrom.getMonth() + 1)}
          placeholder={_dateTo.toLocaleDateString()}
        />
      </div>
      {/* <div className={cx('column', styles.filterItem, styles.dropDown)}>
        <div className={cx('column', styles.filterItem)}>
          <div className="font-weight-bold">Пристрої</div>

          <DropDownDevicesTree value={selectedPlace} setSelectedPlace={setSelectedPlace} />
        </div>

      </div> */}
    </div>
  )
}

export default Filter
