import { AxiosInstance, AxiosPromise } from 'axios';
import CRUD from '../base/crud';

export type KeyT = {
  id: number;
  userId: number;
  credentialID: number;
  publicKey: string;
  userName: string;
  createAt: string;
};

export type CreateKey = {
  data: any;
};

export class UsersKeysCRUD extends CRUD<KeyT> {
  usersKeys(): AxiosPromise<KeyT[]> {
    return this.r({
      method: 'GET',
      url: `${this.config.url}`,
    });
  }
}

export default function userCrud(request: AxiosInstance): UsersKeysCRUD {
  const crud = new UsersKeysCRUD({
    url: '/users/keys',
    request,
    id: 'id',
  });

  return crud;
}
