import React, { useRef, useEffect, useState } from 'react'
import { Modal, Button, Form, Spinner, InputGroup } from 'react-bootstrap'
import { BsEyeSlash, BsEye } from 'react-icons/bs'

//

import styles from './Dashboard.module.scss'

const EyeIcon = ({ isShowPass }: any) => {
  if (isShowPass) {
    return <BsEyeSlash color="#495057" />
  }
  return <BsEye color="#495057" />
}

const PasswordModal = ({
  handleClose,
  handleSubmit,
  updating,
  isSuccess,
  error,
  validMessage,
}: any) => {
  const formRef: any = useRef(null)

  useEffect(() => {
    if (isSuccess && !error) {
      if (formRef && formRef.current) {
        formRef?.current?.reset()
      }
    }
  }, [isSuccess, formRef, error])

  return (
    <Modal show={true} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Зміна пароля</Modal.Title>
      </Modal.Header>
      <Form name="newPass" onSubmit={handleSubmit} ref={formRef}>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <InputPass
              placeholder="Введіть поточний пароль"
              label="Поточний пароль"
              id="currentPassword"
              name="currentPassword"
            />
            <InputPass
              placeholder="Введіть новий пароль"
              label="Новий пароль"
              id="newPassword"
              name="newPassword"
            />
            {(error || isSuccess || !!validMessage) && (
              <Form.Text
                className={
                  !!validMessage || error ? styles.error : styles.success
                }
              >
                {validMessage ||
                  error ||
                  (isSuccess ? 'Пароль успішно оновлений' : '')}
              </Form.Text>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {updating && <Spinner animation="border" />}
          <Button variant="secondary" onClick={handleClose}>
            Закрити
          </Button>
          <Button variant="primary" type="submit">
            Змінити
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const InputPass = ({ placeholder, label, id, name }: any) => {
  const [isShowPass, setisShowPass] = useState(false)

  const handleIsShowPAssClick = (e: any) => {
    e.preventDefault()
    setisShowPass(!isShowPass)
  }
  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <InputGroup className="mb-2">
        <Form.Control
          type={isShowPass ? 'text' : 'password'}
          placeholder={placeholder}
          className={styles.passwordInput}
          name={name}
          required={true}
        />
        <InputGroup.Append>
          <InputGroup.Text className={styles.showPassBtnContainer}>
            <button
              className={styles.passShowBtn}
              onClick={handleIsShowPAssClick}
              type="button"
            >
              <EyeIcon isShowPass={isShowPass} />
            </button>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  )
}

export default PasswordModal
