import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, { Column, Paging, Pager } from 'devextreme-react/data-grid'
import { Template } from 'devextreme-react/core/template'
import Spinner from 'react-bootstrap/Spinner'
import ScrollView from 'devextreme-react/scroll-view'
import { Popup } from 'devextreme-react/popup'

import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import { getUnprocessedTransactionReportCsvPdfAction } from '../../store/sagas/transaction-report'

import styles from './NotificationReportsPage.module.scss'

import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'

interface FoundData {
  isOpen: boolean
  data: {
    data: string
    deviceName: string
    createdAt?: Date | string | number
  }
}

const toTimeStamp = (date: any) => new Date(date).getTime()

export type SelectedPlaceT = {
  name?: string | number
  id?: number
}

const UnprocessedTransactionsReportPage: React.FC = () => {
  const dataGridRef = useRef<DataGrid>(null)
  const dispatch = useDispatch()
  const isFetchingCsv = useSelector(
    (state: any) => state?.transactionState?.isFetchingCsv
  )
  const [transactionDeviceData, setTransactionDeviceData] = useState([])
  const [transactionDataPopup, settransactionDataPopup] = useState<FoundData>({
    isOpen: false,
    data: { data: '[]', deviceName: '', createdAt: '' },
  })

  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState({
    from: new Date().setHours(0, 0, 0, 0),
    to: new Date().setHours(23, 59, 59, 999),
  })

  const [allDate, setAllDate] = useState([])

  const transactionsDevice: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: ({ skip }) => {
          const _page = !skip ? 1 : skip / 100 + 1
          return api.transactionsDevice
            .getUnprocessedTransactionsReport({
              nodeId: place.id,
              ...period,
              page: _page,
              perPage: 100,
            })
            .then(result => {
              // @ts-ignore
              const { data } = result

              const parsedData = data?.message?.map(
                (transaction: {
                  createdAt: any
                  deviceId: any
                  id: any
                  processedAt: any
                  data: string
                  error: any
                  device: any
                }) => {
                  // @ts-ignore
                  const {
                    createdAt,
                    data,
                    deviceId,
                    id,
                    processedAt,
                    device,
                    error,
                  } = transaction

                  return {
                    createdAt,
                    data,
                    deviceId,
                    processedAt,
                    transactionsCount: JSON.parse(data)?.length,
                    deviceName: device?.name,
                    error,
                    id,
                  }
                }
              )
              setTransactionDeviceData(parsedData as any)
              return {
                data: parsedData,
                totalCount: data.count,
              }
            })
            .catch(storeErrorHandler)
        },
      }),
    [place, period]
  )

  function TransactionDataButton(props: { value?: '' | undefined; key: any }) {
    const { value = '', key } = props
    function handleToopgePopup() {
      const findingData: any = allDate.find(
        (item: { id: number }) => item.id === key
      )

      settransactionDataPopup({
        isOpen: true,
        data: {
          data: findingData.data,
          createdAt: new Date(findingData.createdAt).toLocaleString(),
          deviceName: findingData.deviceName,
        },
      })
    }
    return (
      <button
        onClick={handleToopgePopup}
        className={styles.transactions_data_content_button}
      >
        {value}
      </button>
    )
  }

  function handleHidenPopup() {
    settransactionDataPopup({
      isOpen: false,
      data: { data: '[]', createdAt: '', deviceName: '' },
    })
  }

  function onChangePeriod(key: string, value: string) {
    setPeriod({
      ...period,
      [key]: toTimeStamp(value),
    })
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const downloadReport = () => {
    dispatch(
      getUnprocessedTransactionReportCsvPdfAction({
        nodeId: place?.id,
        ...period,
      })
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: updateData,
        },
      }
    )
  }

  useEffect(updateData, [period, place])

  useEffect(() => {
    setAllDate([...allDate, ...transactionDeviceData])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionDeviceData])

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column">
        <PageTitle title="Необробленi транзакції" className="mb-4" />
        <ForPeriodTitleSection
          from={period.from}
          to={period.to}
          isUpdateButton={false}
        />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          setSelectedPlace={setPlace}
          selectedPlace={place.name || ''}
        />
      </div>
      <div className="row">
        {place?.id ? (
          <div className="d-flex flex-column">
            <DataGrid
              ref={dataGridRef}
              dataSource={transactionsDevice}
              showBorders
              allowColumnReordering={true}
              className={styles.transactionTableContainer}
              remoteOperations={true}
              onToolbarPreparing={onToolbarPreparing}
            >
              <Paging defaultPageSize={100} />
              <Pager showPageSizeSelector={false} />
              <Column
                dataField="createdAt"
                caption="Дата"
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
                width={150}
              />
              <Column
                dataField="processedAt"
                caption="Оброблено"
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
                width={150}
              />
              <Column
                dataField="deviceName"
                caption="Пристрій"
                width={'auto'}
              />
              <Column
                dataField="transactionsCount"
                caption="Кількість Транзакцій"
                width={150}
              />
              <Column dataField="error" caption="Помилки" width={'auto'} />
              <Column
                dataField="data"
                caption="Транзакції"
                cellRender={TransactionDataButton}
              />
              <Template name="spinner" render={spinnerComponent} />
            </DataGrid>
          </div>
        ) : (
          <div>
            <span>Необхідно вибрати пристрій</span>
          </div>
        )}
      </div>
      <Popup
        visible={transactionDataPopup.isOpen}
        onHiding={handleHidenPopup}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title={`Транзакції ${transactionDataPopup.data.deviceName} за ${transactionDataPopup.data.createdAt}`}
        width={700}
        height={'auto'}
        maxHeight={'90vh'}
      >
        <ScrollView width="100%" height="100%">
          <code>
            {JSON.parse(transactionDataPopup.data.data).map(
              (item: any, index: string | number | undefined) => {
                return (
                  <pre className="raw_tax_summary_text" key={index}>
                    {JSON.stringify(item, null, 2)}
                  </pre>
                )
              }
            )}
          </code>
        </ScrollView>
      </Popup>
    </div>
  )
}

export default UnprocessedTransactionsReportPage
