import { AxiosInstance, AxiosPromise } from 'axios';

export type CrudConfigT = {
  url: string;
  request: AxiosInstance;
  id: string;
};

class CRUD<M extends Record<string, any>> {
  config: CrudConfigT;

  r: AxiosInstance;

  constructor(config: CrudConfigT) {
    this.config = config;
    this.r = this.config.request;
  }

  get(params?: any): AxiosPromise<Array<M>> {
    return this.r({
      method: 'GET',
      url: this.config.url,
      params,
    });
  }

  one(id: number): AxiosPromise<M> {
    return this.r({
      method: 'GET',
      url: `${this.config.url}/${id}`,
    });
  }

  update(data: Partial<M>): AxiosPromise<M> {
    return this.r({
      method: 'PUT',
      url: `${this.config.url}/${data[this.config.id]}`,
      data,
    });
  }

  create(data: Partial<M>): AxiosPromise<M> {
    return this.r({
      method: 'POST',
      url: this.config.url,
      data,
    });
  }

  delete(id: number): AxiosPromise<void> {
    return this.r({
      method: 'DELETE',
      url: `${this.config.url}/${id}`,
    });
  }
}

export default CRUD;
