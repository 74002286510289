type JSONT = Record<string, string>;

export const localizedServerErrors: JSONT = {
  'u-403': 'Невірний логін або пароль',
  'u-405': 'Невірний поточний пароль',
  'u-404': 'Користувача не існує',
  'u-001': 'Такий email вже використовується',
  'r-401': 'Ролі не існує',
  'r-001': 'Роль має корістувачів',
  'd-404': 'Пристрій не існує',
  'd-001': 'Пристрій вже використовується',
  'p-404': 'Параметр не існує',
  'p-001': 'Батьківський параметр не існує',
  'p-002': 'Корінь вже існує',
  'p-003': 'Не вдалося створити новий параметр для цього пристрою',
  'a-404': 'Атрибут не існує',
  'a-001': 'Помилка типу атрибута',
  'pd-404': 'Дані параметрів не існують',
  'hw-404': 'Турнікета не існує',
  'fwg-404': 'Групи прошивки не існує',
  'fw-404': 'Прошивка не існує',
  '???': 'Щось пішло не так. Спробуйте ще раз пізніше',
  'tr-404': 'Немає даних'
};

export const localizedValidationErrors: JSONT = {
  password: 'Пароль повинен бути не менше 6 символів',
  groupName: "Ім'я групи повинено бути не більше 45 символів",
  version: 'Не повинно перевищувати 6 символів',
  keyName: "Ім'я ключа повинено бути не більше 255 символів",
  userId: 'Не повинено бути пустим',
};
