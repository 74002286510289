import React, { useMemo, useState, useEffect, useRef } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import { CheckBox } from 'devextreme-react/check-box'
import DataGrid, { Column } from 'devextreme-react/data-grid'
import { useLocation } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import SelectBox from 'devextreme-react/select-box'
import { GrUpdate } from 'react-icons/gr'
import { Button as DevExtremeButton } from 'devextreme-react/button'
import api from '../../api'

import { storeErrorHandler } from '../../helpers/errorHandling'
import PageTitle from '../../components/PageTitle/PageTitle'
import styles from './DevicesState.module.scss'
interface tableData {
  deviceName: string
  data: any[]
  deviceConnectState: string
  deviceConnectStateTime: string | number
  color: string
  id: string | number | null
}

const MIN = 1000 * 60

const convertSecondOnDate = (sec: number) => {
  if (!sec) {
    return "З'єднання ще не було"
  }

  let _second = sec
  let _minute = 0
  let _hours = 0
  let _day = 0

  if (_second > 59) {
    _minute = Math.floor(_second / 60)
    _second = _second - _minute * 60
  }
  if (_minute > 59) {
    _hours = Math.floor(_minute / 60)
    _minute = _minute - _hours * 60
  }
  if (_hours > 23) {
    _day = Math.floor(_hours / 24)
    _hours = _hours - _day * 24
  }

  return `${_day}дн ${_hours}год ${_minute}хв ${_second}cек`
}

const DevicesStatePage = () => {
  const location: any = useLocation()
  const query = new URLSearchParams(location.search)
  const _deviceId = query.get('device-id')
  const dataGridRef = useRef<DataGrid>(null)
  const [isOnlyAlarm, setIsOnlyAlarm] = useState(true)
  const [tableData, setTableData] = useState<tableData>({
    deviceName: '',
    data: [],
    deviceConnectState: '',
    deviceConnectStateTime: 0,
    color: '',
    id: null,
  })
  const [intervalOfUpdates, setIntervalOfUpdates] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const deviceMetrics: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: () => {
          setIsFetching(true)
          return api.metric
            .getDeviceMetrics(_deviceId)
            .then(result => {
              // @ts-ignore
              const { data } = result
              const {
                metrics,
                name,
                deviceOnline,
                deviceOfflineSeconds,
                color,
                nodeId,
              } = data
              const normalizeData = metrics.reduce((res: [any], i: any) => {
                if (i.value === null || i.value === undefined) return res
                res.push({
                  id: i.id,
                  metricName: i.attribute.name,
                  metricDescription: i.attribute.description,
                  metricValue: i.value,
                  alarmName: i.alarm?.alarmType?.name || '',
                  updatedAt: i.updatedAt,
                  alarmId: i.alarmId,
                  color: i.alarm?.alarmType?.color,
                })
                return res
              }, [])

              const _formatedDate = convertSecondOnDate(deviceOfflineSeconds)

              setTableData({
                deviceName: name,
                data: normalizeData,
                deviceConnectState: deviceOnline,
                deviceConnectStateTime: _formatedDate,
                color: color || '',
                id: nodeId,
              })
              return normalizeData
            })
            .catch(storeErrorHandler)
            .finally(() => {
              setIsFetching(false)
            })
        },
      }),
    [_deviceId]
  )

  const handleChangeMetricsView = () => {
    setIsOnlyAlarm(!isOnlyAlarm)
  }

  const handleClickLinkToTransactionReport = (e: any) => {
    e.preventDefault()
    window.open(
      `${window.location.origin}/reports/transactions-report?device-id=${tableData.id}&device-name=${tableData.deviceName}`,
      '_blank'
    )
  }

  const handleClickLinkToNotificationsReport = (e: any) => {
    e.preventDefault()
    window.open(
      `${window.location.origin}/reports/notifications?device-id=${tableData.id}&device-name=${tableData.deviceName}`,
      '_blank'
    )
  }

  const handleClickLinkToTehnikalEventReport = (e: any) => {
    e.preventDefault()
    window.open(
      `${window.location.origin}/reports/events-turnstiles-report?device-id=${tableData.id}&device-name=${tableData.deviceName}`,
      '_blank'
    )
  }

  const handleChangeUpdateInterval = (e: any) => {
    setIntervalOfUpdates(e.value)
  }

  const handlerClickUpdate = () => {
    deviceMetrics.load()
  }

  useEffect(() => {
    let timeout = null
    if (intervalOfUpdates) {
      timeout = setInterval(() => {
        deviceMetrics.load()
      }, intervalOfUpdates)
    } else {
      clearInterval(timeout)
    }

    return () => {
      clearInterval(timeout)
    }
  }, [intervalOfUpdates, deviceMetrics])

  useEffect(() => {
    dataGridRef.current?.instance.refresh()
  }, [isOnlyAlarm])

  useEffect(() => {
    deviceMetrics.load()
  }, [deviceMetrics])

  const _dataTabel: any[] = isOnlyAlarm
    ? tableData.data.filter((i: any) => i.alarmId)
    : tableData.data

  return (
    <div className="container">
      <div className="row justify-content-between align-items-center mb-4">
        <PageTitle title={`Метрики ${tableData.deviceName}`} className="mb-4" />
        <div className={styles.updateSection}>
          <DevExtremeButton
            width={40}
            type="normal"
            stylingMode="contained"
            onClick={handlerClickUpdate}
            className={styles.updateButton}
          >
            <GrUpdate
              color="#495057"
              className={isFetching && styles.updateIconFetching}
            />
          </DevExtremeButton>
          <SelectBox
            value={intervalOfUpdates}
            onValueChanged={handleChangeUpdateInterval}
            items={[
              { name: 'ніколи', value: null },
              { name: '1 хв', value: MIN },
              { name: '5 хв', value: MIN * 5 },
              { name: '10 хв', value: MIN * 10 },
              { name: '30 хв', value: MIN * 30 },
            ]}
            grouped={false}
            displayExpr="name"
            valueExpr="value"
            width={90}
            className={styles.selectBox}
          />
        </div>
      </div>
      <div className="row justify-content-start mb-4 flex-column align-items-start">
        <Button
          className="pl-0"
          variant="link"
          onClick={handleClickLinkToTransactionReport}
        >
          Перейти до звіту з транзакцій пристрою
        </Button>
        <Button
          className="pl-0"
          variant="link"
          onClick={handleClickLinkToNotificationsReport}
        >
          Перейти до технологічних повідомленнь
        </Button>
        <Button
          className="pl-0"
          variant="link"
          onClick={handleClickLinkToTehnikalEventReport}
        >
          Перейти до звіту з подій
        </Button>
      </div>
      <div className="row justify-content-start mb-4 flex-column">
        <span>
          <b>Стан з'єднання пристрою:</b>
          <span className="badge" style={{ backgroundColor: tableData.color }}>
            <span
              className={styles.fontStyleBadge}
              style={{ color: tableData.color }}
            >
              {tableData.deviceConnectState}
            </span>
          </span>
        </span>
        <span>
          <b>Час знаходження в стані з'єднання:</b>{' '}
          {tableData.deviceConnectStateTime}
        </span>
      </div>
      <div className="row dx-field mb-4 justify-content-start">
        <div className="row justify-content-start align-items-center">
          <CheckBox
            value={isOnlyAlarm}
            text="Тільки з тривогами"
            onValueChanged={handleChangeMetricsView}
          />
        </div>
      </div>
      <div className="row justify-content-between mb-4">
        <DataGrid
          ref={dataGridRef}
          id="id"
          showBorders={true}
          dataSource={_dataTabel}
          repaintChangesOnly
        >
          <Column
            dataField="metricName"
            caption="Метрика"
            width={'auto'}
            defaultSortOrder="asc"
          />
          <Column
            dataField="updatedAt"
            caption="Оновлено"
            dataType="date"
            format="dd/MM/yyyy HH:mm"
            width={150}
          />
          <Column
            dataField="metricValue"
            caption="Значення метрики"
            width={160}
            cssClass={styles.wordWrapCell}
          />
          <Column
            dataField="metricDescription"
            caption="Опис"
            cssClass={styles.wordWrapCell}
            width={'50%'}
          />
          <Column
            dataField="alarmName"
            caption="Тривога"
            cssClass={styles.wordWrapCell}
            width={'50%'}
            cellRender={CellAlarmState}
          />
        </DataGrid>
      </div>
    </div>
  )
}

const CellAlarmState = (props: any) => {
  const {
    data: { color, alarmName },
  } = props

  return (
    <span className="badge" style={{ backgroundColor: color }}>
      <span className={styles.fontStyleBadge} style={{ color: color }}>
        {alarmName}
      </span>
    </span>
  )
}

export default DevicesStatePage

////http://localhost:3000/device-state/?device-id=533
////api/metric/device/{id}
