/* eslint-disable no-param-reassign */
import React from 'react';
import DataGrid, { Column, Editing, Lookup, Scrolling } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import PageTitle from '../../components/PageTitle/PageTitle';
import PermissionsTagBoxComponent from './PermissionsTagBoxComponent/PermissionsTagBoxComponent';
import api from '../../api';
import { PermissionT, RoleT } from '../../api/modules/roles';
import { storeErrorHandler } from '../../helpers/errorHandling';

function cellTemplate(container: any, options: any): void {
  const text = options.value.map((element: PermissionT) => element.action).join(', ');
  container.textContent = text || '';
  container.title = text;
}

const rolesData: any = new CustomStore({
  key: 'id',
  load: () =>
    api.role
      .get({})
      .then(result => result.data)
      .catch(storeErrorHandler),
  remove: id => api.role.delete(id).catch(storeErrorHandler),
  update: (id, data) => {
    const values = { id, ...data };

    if (data.permissions && data.permissions.length > 0) {
      const permissions = (data.permissions || []).map((item: any) => item.id);
      values.permissions = permissions;
    }

    return api.role.update(values).catch(storeErrorHandler);
  },
  insert: data => {
    const permissions = (data.permissions || []).map((item: any) => item.id);
    return api.role.create({ ...data, permissions }).catch(storeErrorHandler);
  },
});

const permissionsData: any = new CustomStore({
  key: 'id',
  loadMode: 'raw',
  load: () =>
    api.role
      .permissions()
      .then(result => result.data)
      .catch(storeErrorHandler),
});

function canDelete({ row: { data } }: { row: { data: RoleT } }): boolean {
  return !data?.haveUsers;
}

const RolesPage: React.FC = () => {
  return (
    <div className="container">
      <PageTitle title="Ролі" />

      <p className="text-muted">Видалення ролі можливо тільки якщо вона не використовуються.</p>

      <DataGrid id="grid" showBorders dataSource={rolesData} repaintChangesOnly activeStateEnabled>
        <Editing
          refreshMode="full"
          mode="form"
          allowAdding
          allowDeleting={canDelete}
          allowUpdating
          useIcons
        />

        <Scrolling mode="virtual" />

        <Column dataField="name" caption="Ім'я" />
        <Column
          dataField="permissions"
          caption="Доступи"
          allowSorting={false}
          editCellComponent={PermissionsTagBoxComponent}
          cellTemplate={cellTemplate}
        >
          <Lookup dataSource={permissionsData} valueExpr="id" displayExpr="action" />
        </Column>
        <Column dataField="haveUsers" caption="Користувачів" />
      </DataGrid>
    </div>
  );
};

export default RolesPage;
