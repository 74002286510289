import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

import api from '../../../api';
import { ConfigT } from '../../../api/modules/config';
import { ConfigParamT } from '../../../api/modules/configParam';

import PageTitle from '../../../components/PageTitle/PageTitle';
import Layout from '../Layout';
import Params from './Params/Params';
import Tree from './Tree/Tree';

import './ConfigurationPage.module.scss';

interface StateI {
  selectedNode: ConfigT | undefined;
  isCollapsed: boolean;
}

export default class ConfigurationPage extends Component<any, StateI> {
  state: StateI = {
    isCollapsed: false,
    selectedNode: undefined,
  };

  attributes: ConfigParamT[] = [];

  async componentDidMount(): Promise<void> {
    this.attributes = (await api.configParam.get({})).data;
  }

  setSelection = (selectedNode: ConfigT | undefined) => {
    this.setState({ selectedNode });
  };

  setCollapseStatus = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render(): React.ReactNode {
    const { isCollapsed } = this.state;
    return (
      <Layout>
        <div className="container">
          <div className="row justify-content-between mb-4">
            <PageTitle title="Конфігурації" />

            <div className="column justify-content-end mt-3">
              <div className="container">
                <Button onClick={() => this.setCollapseStatus()} variant="outline-primary">
                {!isCollapsed ? 'Розгорнути' : 'Згорнути'}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <Tree isCollapsed={isCollapsed} onSelect={this.setSelection} />
          <Params selectedNode={this.state.selectedNode} attributes={this.attributes} />
        </div>
      </Layout>
    );
  }
}
