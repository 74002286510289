import React from 'react'
import { DateBox } from 'devextreme-react'
import cx from 'clsx'

import { SelectedPlaceT } from '../../UnprocessedTransactionsReportPage'
import DropDownDevicesTree from '../dropDownDevicesTree/DropDownDevicesTree'

import styles from './Filter.module.scss'

const DATE_BOX_WIDTH = 280

interface FilterInterface {
  onChangePeriod: (key: string, value: any) => void
  setSelectedPlace: (data: SelectedPlaceT) => void
  from: any
  to: any
  selectedPlace: string | number
}

const Filter = ({
  onChangePeriod,
  setSelectedPlace,
  selectedPlace,
  from,
  to,
}: FilterInterface) => {
  function updateDataFrom(event: any) {
    if (event.value) {
      onChangePeriod('from', event.value)
    } else {
      onChangePeriod('from', new Date().setHours(0, 0, 0, 0))
    }
  }

  function updateDataTo(event: any) {
    if (event.value) {
      onChangePeriod('to', event.value)
    } else {
      onChangePeriod('to', new Date().setHours(23, 59, 59, 999))
    }
  }

  return (
    <div className="d-flex flex-row mb-4">
      <div className={'column'}>
        <div className="font-weight-bold">Початок</div>
        <DateBox
          width={DATE_BOX_WIDTH}
          type="datetime"
          onValueChanged={updateDataFrom}
          showClearButton
          max={typeof to === 'number' && to !== 0 ? to : undefined}
          placeholder={new Date(from).toLocaleString()}
        />
      </div>

      <div className={cx('column', styles.filterItem)}>
        <div className="font-weight-bold">Кінець</div>
        <DateBox
          width={DATE_BOX_WIDTH}
          type="datetime"
          onValueChanged={updateDataTo}
          showClearButton
          min={from}
          placeholder={new Date(to).toLocaleString()}
        />
      </div>

      <div className={cx('column', styles.filterItem, styles.dropDown)}>
        <div className={cx('column', styles.filterItem)}>
          <div className="font-weight-bold">Пристрої</div>

          <DropDownDevicesTree
            value={selectedPlace}
            setSelectedPlace={setSelectedPlace}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
