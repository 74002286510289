import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';

export type ProductsT = {
  id: number;
  data: any
};

export class ProductsCRUD extends CRUD<ProductsT> {
  updateProductsCode(id: any, data: any, params?: any) {
   let url = `${this.config.url}/${id}`

    return this.r({
      method: 'PUT',
      url,
      data,
      params
    })
  }
  createProduct(data: any, params?: any) {
    let url = `${this.config.url}`
     return this.r({
       method: 'POST',
       url,
       data,
       params
     })
   }
}

export default function productsCRUD(request: AxiosInstance): ProductsCRUD {
  return new ProductsCRUD({
    url: '/product-code',
    request,
    id: 'id',
  });
}
