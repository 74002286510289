import React from 'react'
import cx from 'clsx'
import { CheckBox } from 'devextreme-react/check-box'

import { SelectedPlaceT } from '../../TransactionsReportPage'
import DropDownDevicesTree from '../dropDownDevicesTree/DropDownDevicesTree'
import CalendarPicker from '../../../../components/CalendarPicker/CalendarPicker'

import styles from './Filter.module.scss'

interface FilterInterface {
  onChangePeriod: (key: string, value: any) => void
  setSelectedPlace: (data: SelectedPlaceT) => void
  from: any
  to: any
  selectedPlace: string | number
  handleChangeDateType?: (data: any) => void
  withCounter: boolean
  handleChangeWithCounter: (e: any) => void
  isDeviceId: boolean
}

const Filter = ({
  onChangePeriod,
  setSelectedPlace,
  selectedPlace,
  from,
  to,
  handleChangeDateType,
  withCounter,
  handleChangeWithCounter,
  isDeviceId,
}: FilterInterface) => {
  return (
    <div className="d-flex flex-row align-items-end mb-4">
      <div className="d-flex flex-column">
        <CalendarPicker
          to={to}
          from={from}
          onChangePeriod={onChangePeriod}
          withFilter={true}
          handleChangeDateType={handleChangeDateType}
        />
      </div>
      <div className={cx('column', styles.filterItem, styles.dropDown)}>
        <div className={cx('column', styles.filterItem)}>
          {isDeviceId && (
            <div className="w-100 mb-3 d-flex justify-content-end align-items-center">
              <CheckBox
                value={withCounter}
                text="З лічильниками"
                onValueChanged={handleChangeWithCounter}
              />
            </div>
          )}
          <div className="font-weight-bold">Пристрої</div>
          <DropDownDevicesTree
            value={selectedPlace}
            setSelectedPlace={setSelectedPlace}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
