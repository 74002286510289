import React, { forwardRef } from 'react'
import DataGrid, {
  Column,
  Scrolling,
  LoadPanel,
} from 'devextreme-react/data-grid'
import clsx from 'clsx'

import styles from './Table.module.scss'

interface Props {
  data: any
  ref: any
}

const onAlarmIdPress = (deviceIds: string) => {
  if (deviceIds) {
    localStorage.setItem('deviceIds', JSON.stringify(deviceIds))

    window.open(`${window.location.origin}/alarms`, '_blank')
  }
}

const RenderAlarmNameColumn = (props: any) => {
  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => onAlarmIdPress(props.data.devicesIds)}
    >
      {props.data.alarmType}
    </div>
  )
}

const Budge = ({ data }: any) => {
  return (
    <div className={clsx(styles.budgeWrapper)}>
      <div
        className={clsx(styles.budge)}
        style={{
          backgroundColor: data.data.color,
        }}
      />
    </div>
  )
}

const Table = forwardRef(({ data }: Props, ref: any) => {
  return (
    <DataGrid
      ref={ref}
      className={clsx(styles.tableWrapper)}
      id="id"
      showBorders
      dataSource={data}
      repaintChangesOnly
    >
      <LoadPanel showIndicator={data} enabled={true} />
      <Scrolling mode="virtual" />

      <Column
        alignment="center"
        width="60"
        caption="Колір"
        cellComponent={props => <Budge {...props} />}
      />
      <Column
        dataField="alarmType"
        caption="Тип тривоги"
        cellRender={RenderAlarmNameColumn}
      />
      <Column dataField="count" caption="Кількість пристроїв" width="auto" />
    </DataGrid>
  )
})

export default Table
