import { AxiosInstance } from 'axios'
import CRUD from '../base/crud'

export type StateSystemT = {
  query: any
}

export class StateSystemCRUD extends CRUD<StateSystemT> {
  getUnprocessedTransactionsInfo() {
    let url = `${this.config.url}/unprocessed-transactions-info`
    return this.r({
      method: 'GET',
      url,
    })
  }

  getProcessingTransactionsInfo(params: any) {
    let url = `${this.config.url}/processing-transactions-info`
 
    return this.r({
      method: 'GET',
      url,
      params
    })
  }
}

export default function stateSystemCRUD(
  request: AxiosInstance
): StateSystemCRUD {
  return new StateSystemCRUD({
    url: `/reports`,
    request,
    id: 'id',
  })
}
