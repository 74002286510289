export const FILTER_FIELDS = {
  ALL_FILTER_STATE: 'overall',
  BCARD_FILTER_STATE: 'bank',
  KCARD_FILTER_STATE: 'card',
  QR_FILTER_STATE: 'qr',
  SCARD_FILTER_STATE: 'ridango',
}


export const DEFAULT_PARAMS_GISTOGRAM_DATA = {
  hoverinfo: 'y+text',
  histfunc: 'sum',
  type: 'histogram',
}

export const ENTRANCE_TYPES = {
  [FILTER_FIELDS.ALL_FILTER_STATE]: {
    label: 'Всі',
    key: FILTER_FIELDS.ALL_FILTER_STATE,
  },
  [FILTER_FIELDS.BCARD_FILTER_STATE]: {
    label: 'Банківська картка',
    key: FILTER_FIELDS.BCARD_FILTER_STATE,
  },
  [FILTER_FIELDS.KCARD_FILTER_STATE]: {
    label: 'Картка Киянина',
    key: FILTER_FIELDS.KCARD_FILTER_STATE,
  },
  [FILTER_FIELDS.QR_FILTER_STATE]: {
    label: 'Квиток QR',
    key: FILTER_FIELDS.QR_FILTER_STATE,
  },
  [FILTER_FIELDS.SCARD_FILTER_STATE]: {
    label: 'Смарт Карта',
    key: FILTER_FIELDS.SCARD_FILTER_STATE,
  },
}

export const DEfAULT_GISTOGRAMS_DATA = {
  x: [],
  text: [],
  [FILTER_FIELDS.ALL_FILTER_STATE]: {
    y: [],
  },
  [FILTER_FIELDS.BCARD_FILTER_STATE]: {
    y: [],
  },
  [FILTER_FIELDS.KCARD_FILTER_STATE]: {
    y: [],
  },
  [FILTER_FIELDS.QR_FILTER_STATE]: {
    y: [],
  },
  [FILTER_FIELDS.SCARD_FILTER_STATE]: {
    y: [],
  },
}

export const INTERVAL_MIN = 15
