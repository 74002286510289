import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';

export type AlarmTypeT = {
  id: number;
  color: string;
  description: string;
  name: string;
  alarmId: string;
};
export class AlarmTypeCRUD extends CRUD<AlarmTypeT> {}

export default function alarmTypeCRUD(request: AxiosInstance): AlarmTypeCRUD {
  return new AlarmTypeCRUD({
    url: '/alarm-type',
    request,
    id: 'id',
  });
}
