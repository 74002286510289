import React from 'react'
import { DateBox } from 'devextreme-react'
import cx from 'clsx'
import styles from './Filter.module.scss'
import DropDownDevicesTree from '../dropDownDevicesTree/DropDownDevicesTree'
import { SelectedPlaceT } from '../../MountlyTransportationLobby'

const DATE_BOX_WIDTH = 280

interface FilterInterface {
  onChangePeriod: (values: { from: number; to: number }) => void
  setSelectedPlace: (data: SelectedPlaceT) => void
  from: any
  to: any
  selectedPlace: string | number
}

const Filter = ({
  onChangePeriod,
  from,
  setSelectedPlace,
  selectedPlace,
}: FilterInterface) => {
  function updateDataFrom(event: any) {
    const _fromDate = new Date(event.value).setHours(0, 0, 0, 0)
    const _toDate = new Date(
      new Date(_fromDate).getFullYear(),
      new Date(_fromDate).getMonth() + 1,
      0
    ).setHours(23, 59, 59, 999)

    onChangePeriod({ to: _toDate, from: _fromDate })
  }

  return (
    <div className="d-flex flex-row">
      <div className={'column'}>
        <div className="font-weight-bold">Місяць</div>
        <DateBox
          width={DATE_BOX_WIDTH}
          type="date"
          onValueChanged={updateDataFrom}
          showClearButton={false}
          max={new Date()}
          value={from}
          displayFormat="MMM yyyy"
          calendarOptions={{
            maxZoomLevel: 'year',
            minZoomLevel: 'decade',
          }}
        ></DateBox>
      </div>
      <div className={cx('column', styles.filterItem, styles.place)}>
        <div className="font-weight-bold">Пристрої</div>

        <DropDownDevicesTree
          value={selectedPlace}
          setSelectedPlace={setSelectedPlace}
        />
      </div>
    </div>
  )
}

export default Filter
