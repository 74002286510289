import { AxiosInstance, AxiosPromise } from 'axios';
import CRUD from '../base/crud';

export type UserT = {
  id: number;
  username: string;
  email: string;
  role: {
    id: number;
    name: string;
  } | number;
  createdAt: string;
  password?: string;
};

export type ChangeUserPassT = {
  id: string;
  oldPassword: string;
  newPassword: string;
}

export type AuthDataT = {
  email: string;
  password: string;
};

export type GoogleAuthDataT = {
  googleToken: {
    access_token: string
  };
};

export class UserCRUD extends CRUD<UserT> {
  webauthnLogin(data: any): AxiosPromise<UserT> {
    return this.r({
      method: 'POST',
      url: `${this.config.url}/webauthn/login`,
      data,
    });
  }

  googleAuthnLogin(data: any): AxiosPromise<UserT> {
    return this.r({
      method: 'POST',
      url: `${this.config.url}/google/auth`,
      data,
    });
  }

  auth(data: AuthDataT): AxiosPromise<UserT> {
    return this.r({
      method: 'POST',
      url: `${this.config.url}/auth`,
      data,
    });
  }

  me(token: string): AxiosPromise<UserT> {
    return this.r({
      method: 'GET',
      url: `${this.config.url}/me`,
      headers: { 'x-access-token': token },
    });
  }

  logout(): AxiosPromise<UserT> {
    return this.r({
      method: 'GET',
      url: `${this.config.url}/logout`,
    });
  }

  changePass(data: ChangeUserPassT): AxiosPromise<UserT> {
    return this.r({
      method: 'POST',
      url: `${this.config.url}/change-pass`,
      data
    });
  }

}

export default function userCrud(request: AxiosInstance): UserCRUD {
  const crud = new UserCRUD({
    url: '/user',
    request,
    id: 'id',
  });

  return crud;
}
