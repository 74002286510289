import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './modules';
import sagas from './sagas';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducer(history),
  composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
);

sagas.forEach(saga => sagaMiddleware.run(saga));

export default store;
