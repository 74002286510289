import React, { useEffect, useState, useCallback } from 'react'
import clsx from 'clsx'
import { Link, Route, Redirect } from 'react-router-dom'
import { Nav, NavDropdown, Card, Dropdown } from 'react-bootstrap'
import Gravatar from 'react-gravatar'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { defineFont } from '@progress/kendo-drawing/pdf'
import { isEqual } from 'lodash'

import { logout, StateT as AuthState } from '../../store/modules/auth/auth'
import { AppState } from '../../store/modules'
import { checkPermission } from '../../helpers/permissions'

import NotificationReportsPage from '../NotificationReportsPage/NotificationReportsPage'
import UsersPage from '../UsersPage/UsersPage'
import RolesPage from '../RolesPage/RolesPage'
import ConfigurationPage from '../configuration/ConfigurationPage/ConfigurationPage'
import ConfigurationParamsPage from '../configuration/ConfigurationParamsPage'
import ProtectedRoute from '../../components/ProtectedRoute/ProtectedRoute'
import FirmwarePage from '../FirmwarePage/FirmwarePage'
import MetricsPage from '../MetricsPage/MetricsPage'
import AlarmPage from '../AlarmPage/AlarmPage'
import VersionsReportsPage from '../VersionsReports/VersionsReportsPage'
import AlarmTypesPage from '../AlarmTypesPage/AlarmTypesPage'
import FeedingPage from '../FeedingPage/FeedingPage'
import DevicesListPage from '../DevicesListPage/DevicesListPage'
import MainPage from '../MainPage/MainPage'
import MyKeysPage from '../MyKeysPage/MyKeysPage'
import UsersKeysPage from '../UsersKeysPage/UsersKeysPage'
import DeviceMonitoring from '../DeviceMonitoringPage/DeviceMonitoringPage'
import TransactionsReportPage from '../TransactionsReportPage/TransactionsReportPage'
import UnprocessedTransactionsReportPage from '../UnprocessedTransactionsReportPage/UnprocessedTransactionsReportPage'
import HallConfigurationReportPage from '../DeviceConfigurationReportsPages/HallConfigurationReportPage'
import TurnstilesConfigurationReportPage from '../DeviceConfigurationReportsPages/TurnstilesConfigurationReportPage'
import TransactionsProcesingReportPage from '../TransactionsProcesingReport/TransactionsProcesingReportPage'
import DevicesStatePage from '../DevicesStatePage/DevicesStatePage'
import SystemStatusPage from '../SystemStatusPage/SystemStatusPage'
import MonthlyTransportationReport from '../MonthlyTransportationReport/MonthlyTransportationReport'
import MonthlyTransportationReportByNode from '../MonthlyTransportationReportByNode/MonthlyTransportationReportByNode'
import PasswordModal from './PasswordModal'
import { changePass, clearErrorUpdate } from '../../store/modules/user/update'
import TransactionsDailyReport from '../TransactionsDailyReport/TransactionsDailyReport'
import RouteSheetByNumberReport from '../RouteSheetByNumberReport/RouteSheetByNumberReport'
import MountlyTransportationLobby from '../MountlyTransportationLobby/MountlyTransportationLobby'
import EntranceLoadReportPage from '../EntranceLoadReportPage/EntranceLoadReportPage'
import ProductCodePage from '../ProductCodePage/ProductCodePage'
import EventsFromTurnstilesReport from '../EventsFromTurnstilesReportPage/EventsFromTurnstilesReport'
import MetricsStateOnDeviceReport from '../MetricsStateOnDeviceReport/MetricsStateOnDeviceReport'
import AuditOfSettings from '../AuditOfSettings/AuditOfSettings'
import KeysPage from '../KeysPage/KeysPage'

import { usePrevious } from '../../custom-hooks/usePrevious'

import styles from './Dashboard.module.scss'

const validatePassword = (value: string): boolean =>
  value.trim() ? value.trim().length >= 6 : true
interface HasAccessI {
  resource: string
  children: React.ReactNode
}

const HasAccess: React.FC<HasAccessI> = ({
  children,
  resource,
}: HasAccessI) => {
  const { user } = useSelector<AppState, AuthState>(state => state.auth)
  const roleName = get(user, 'role.name')

  const granted = checkPermission(resource, roleName)

  if (!granted) {
    return null
  }

  return <>{children}</>
}

const AppMenu = ({ logOut }: any) => {
  const dispatch = useDispatch()
  const { email, username, id } =
    useSelector((state: any) => state?.auth?.user, isEqual) || {}

  const { updating, error } = useSelector((state: any) => state.user.update)

  const prevUpdating = usePrevious(updating)

  const [show, setShow] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const [validMessage, setValidMessage] = useState<String>('')

  const handleClose = useCallback(() => {
    dispatch(clearErrorUpdate())
    setShow(false)
  }, [dispatch])

  const handleShow = () => setShow(true)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const oldPassword = e.target.elements.currentPassword.value || ''
    const newPassword = e.target.elements.newPassword.value || ''
    const isValidNewPass = validatePassword(newPassword)

    if (oldPassword && isValidNewPass) {
      if (validMessage) {
        setValidMessage('')
      }
      dispatch(
        changePass({
          id: String(id),
          oldPassword,
          newPassword,
        })
      )
    } else {
      setValidMessage('Новий пароль повинен бути більше 6 символів')
    }
  }

  useEffect(() => {
    if (updating !== prevUpdating && prevUpdating && !error) {
      setIsSuccess(true)
    }
    if (error && isSuccess) {
      setIsSuccess(false)
    }

    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false)
      }, 5000)
    }
  }, [updating, isSuccess, error, prevUpdating])

  return (
    <div className="row align-items-center justify-content-between">
      <Link to="/main" className={clsx('row align-items-center', styles.logo)}>
        <Card.Img variant="top" src="/logo.png" className={styles.logoImg} />
        <Card.Text className={styles.logoText}>
          Київський <br />
          Метрополітен
        </Card.Text>
      </Link>
      <div className="col-6">
        <Nav activeKey="/stations" className="justify-content-center">
          <Nav.Item>
            <Nav.Link as={Link} to="/main">
              Головна
            </Nav.Link>
          </Nav.Item>
          <NavDropdown title="Монiторинг" id="nav-dropdown">
            {/* <NavDropdown.Item as={Link} to="/device-monitoring">
            Пристрої
          </NavDropdown.Item> */}
            <NavDropdown.Item as={Link} to="/feeding">
              Живлення
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/alarms">
              Тривоги
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/system-status">
              Cтатус системи
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Звіти" id="nav-dropdown">
            {/* Hardcode */}
            <NavDropdown.Item as={Link} to="/reports/transactions-report">
              Звіт з транзакцій
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/reports/events-turnstiles-report">
              Звіт з подій від турнікетів
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/reports/transactions-daily-report">
              Звіт по денним транзакціям
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/reports/route-sheet-by-number">
              Маршрутний лист за номером
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/entrance-load-report">
              Звіт по завантаженню проходів
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="/reports/notifications">
              Технологічні повідомлення
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/state-metrics-device-report">
              Звіт зі змін стану метрик на пристрої
            </NavDropdown.Item>
            <HasAccess resource="audit">
              <NavDropdown.Item as={Link} to="/settings-audit">
                Аудит зміни налаштувань
              </NavDropdown.Item>
            </HasAccess>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="/reports/monthly-transportation">
              Звіт з перевезень за місяць
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/reports/period-transportation-node"
            >
              Звіт з перевезень за період вестибюль
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/reports/monthly-transportation-lobby"
            >
              Звіт з перевезень за місяць вестибюль
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="/reports/transactions-processing">
              Звіт з обробки транзакцій
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/reports/versions">
              Версії ПЗ
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/reports/unprocessed-transactions-report"
            >
              Звіт з необроблених транзакцій пристроїв
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/hall-configuration-report">
              Конфігурації вестибюлів
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/turnstiles-configuration-report">
              Конфигурацiя пристроїв
            </NavDropdown.Item>
          </NavDropdown>
          <HasAccess resource="user">
            <NavDropdown title="Налаштування" id="nav-dropdown">
              <HasAccess resource="user">
                <NavDropdown.Item as={Link} to="/users">
                  Користувачі
                </NavDropdown.Item>
              </HasAccess>
              <HasAccess resource="key">
                <NavDropdown.Item as={Link} to="/users-keys">
                  Ключі користувачів
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/devices_keys">
                  Ключі пристроїв
                </NavDropdown.Item>
              </HasAccess>
              <HasAccess resource="role">
                <NavDropdown.Item as={Link} to="/roles">
                  Ролі
                </NavDropdown.Item>
              </HasAccess>
              <HasAccess resource="parameter">
                <div className={styles.sub}>
                  <NavDropdown.Item as={Link} to="/configuration">
                    Конфігурації
                  </NavDropdown.Item>
                  <ul className={clsx('dropdown-menu', styles.subDropdown)}>
                    <NavDropdown.Item as={Link} to="/configuration">
                      Конфігурації
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/configuration/parameters">
                      Параметри
                    </NavDropdown.Item>
                  </ul>
                </div>
              </HasAccess>

              <HasAccess resource="parameter">
                <div className={styles.sub}>
                  <NavDropdown.Item as={Link} to="/devices">
                    Пристрої
                  </NavDropdown.Item>
                  <ul className={clsx('dropdown-menu', styles.subDropdown)}>
                    <NavDropdown.Item as={Link} to="/devices">
                      Пристрої
                    </NavDropdown.Item>

                    <NavDropdown.Item as={Link} to="/devices-list">
                      Всi пристрої
                    </NavDropdown.Item>
                  </ul>
                </div>
              </HasAccess>
              <HasAccess resource="firmware">
                <NavDropdown.Item as={Link} to="/firmware">
                  Прошивки
                </NavDropdown.Item>
              </HasAccess>
              <NavDropdown.Item as={Link} to="/metrics">
                Метрики
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/alarm-types">
                Тривоги
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/products">
                Продукти
              </NavDropdown.Item>
            </NavDropdown>
          </HasAccess>
        </Nav>
      </div>

      <div className="row text-right">
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <Gravatar
              email={email}
              className={styles.gravatarClass}
              size={50}
              rating="g"
              style={{ margin: 0 }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.userDropdownMenu}>
            <Dropdown.Header>Account</Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Header as={CustomHeader} email={email} name={username} />
            <Dropdown.Divider />
            <Dropdown.Item eventKey="1" onClick={handleShow}>
              Змінити пароль
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" as={Link} to="/keys">
              Мої ключі
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="3" onClick={logOut}>
              Вийти
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {show && (
        <PasswordModal
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          updating={updating}
          isSuccess={isSuccess}
          error={error}
          validMessage={validMessage}
        />
      )}
    </div>
  )
}

const CustomToggle = React.forwardRef(
  ({ children, onClick }: any, ref: any) => (
    <button
      className={styles.profileRoundButton}
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </button>
  )
)

const CustomHeader = React.forwardRef(({ email, name }: any, ref: any) => (
  <div ref={ref} className={styles.userData}>
    <Gravatar
      email={email}
      className={styles.gravatarClassHeader}
      size={40}
      rating="g"
    />
    <div className={styles.nameSection}>
      <span className={styles.headerName} title={name}>
        {name}
      </span>
      <span className={styles.email} title={email}>
        {email}
      </span>
    </div>
  </div>
))

const Dashboard: React.FC = () => {
  const dispatch = useDispatch()
  const { user } = useSelector<AppState, AuthState>(state => state.auth)
  const roleName = get(user, 'role.name')

  const logOut = () => dispatch(logout())

  useEffect(() => {
    defineFont({
      'DejaVu Sans':
        'https://kendo.cdn.telerik.com/2020.2.617/styles/fonts/DejaVu/DejaVuSans.ttf',
    })
  }, [])

  return (
    <div className="container pt-4">
      <AppMenu logOut={logOut} />
      <div className="row mt-5">
        <Route exact path="/">
          <Redirect to="/main" />
        </Route>
        <Route path="/main" component={MainPage} />
        <Route path="/feeding" component={FeedingPage} />
        <Route path="/system-status" component={SystemStatusPage} />
        <Route path="/settings-audit" component={AuditOfSettings} />
        <Route
          exact
          path="/reports/notifications"
          component={NotificationReportsPage}
        />
        <Route
          exact
          path="/reports/transactions-report"
          component={TransactionsReportPage}
        />
        <Route
          exact
          path="/reports/transactions-daily-report"
          component={TransactionsDailyReport}
        />

        <Route
          exact
          path="/reports/unprocessed-transactions-report"
          component={UnprocessedTransactionsReportPage}
        />
        <Route
          exact
          path="/reports/transactions-processing"
          component={TransactionsProcesingReportPage}
        />
        <Route
          exact
          path="/reports/events-turnstiles-report"
          component={EventsFromTurnstilesReport}
        />
        <Route
          exact
          path="/reports/route-sheet-by-number"
          component={RouteSheetByNumberReport}
        />
        <Route
          exact
          path="/reports/monthly-transportation"
          component={MonthlyTransportationReport}
        />
        <Route
          exact
          path="/entrance-load-report"
          component={EntranceLoadReportPage}
        />
        <Route
          exact
          path="/reports/period-transportation-node"
          component={MonthlyTransportationReportByNode}
        />
        <Route
          exact
          path="/reports/monthly-transportation-lobby"
          component={MountlyTransportationLobby}
        />
        <Route exact path="/reports/versions" component={VersionsReportsPage} />
        <Route exact path="/keys" component={MyKeysPage} />
        <ProtectedRoute
          exact
          resource="key"
          role={roleName}
          path="/users-keys"
          component={UsersKeysPage}
        />
        <ProtectedRoute
          exact
          resource="key"
          role={roleName}
          path="/devices_keys"
          component={KeysPage}
        />
        <ProtectedRoute
          path="/users"
          component={UsersPage}
          resource="user"
          role={roleName}
        />
        <ProtectedRoute
          path="/roles"
          component={RolesPage}
          resource="role"
          role={roleName}
        />
        <Route
          path="/configuration/parameters"
          component={ConfigurationParamsPage}
        />
        <ProtectedRoute
          path="/configuration"
          component={ConfigurationPage}
          resource="parameter"
          exact
          role={roleName}
        />
        {/* <Route path="/device-monitoring" component={DevicesPage} /> */}
        <Route path="/device-state" component={DevicesStatePage} />
        <Route path="/devices" component={DeviceMonitoring} />
        <Route path="/devices-list" component={DevicesListPage} />
        <Route path="/alarms" component={AlarmPage} />
        <Route path="/metrics" component={MetricsPage} />
        <Route path="/alarm-types" component={AlarmTypesPage} />
        <Route path="/products" component={ProductCodePage} />
        <Route
          path="/state-metrics-device-report"
          component={MetricsStateOnDeviceReport}
        />
        <Route
          path="/hall-configuration-report"
          component={HallConfigurationReportPage}
        />
        <Route
          path="/turnstiles-configuration-report"
          component={TurnstilesConfigurationReportPage}
        />
        <ProtectedRoute
          path="/firmware"
          component={FirmwarePage}
          resource="firmware"
          role={roleName}
        />
      </div>
    </div>
  )
}

export default Dashboard
