import api from '../../../api';
import { UserT, ChangeUserPassT } from '../../../api/modules/user';
import errorHandling from '../../../helpers/errorHandling'
import { API_REQUEST, ApiRequestT } from '../../apiAction';

const UPDATE: 'user/UPDATE' = 'user/UPDATE';
export const UPDATE_SUCCESS: 'user/UPDATE_SUCCESS' = 'user/UPDATE_SUCCESS';
const UPDATE_FAILURE: 'user/UPDATE_FAILURE' = 'user/UPDATE_FAILURE';
const CLEAR_ERROR_UPDATE_FAILURE: 'user/CLEAR_ERROR_UPDATE_FAILURE' = 'user/CLEAR_ERROR_UPDATE_FAILURE';

type UpdateT = {
  type: typeof UPDATE;
};

export type UpdateSuccessT = {
  type: typeof UPDATE_SUCCESS;
  result: {
    data: UserT;
  };
};
type UpdateFailureT = {
  type: typeof UPDATE_FAILURE;
  error: any;
};

type ClearErrorT = {
  type: typeof CLEAR_ERROR_UPDATE_FAILURE
}

type ActionTypeT = UpdateT | UpdateSuccessT | UpdateFailureT | ClearErrorT;

export type StateT = {
  updating: boolean;
  error?: string | null;
};

const initialState: StateT = {
  updating: false,
  error: null,
};

export default function exampleUpdateReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {
  switch (action.type) {
    case UPDATE:
      return {
        ...initialState,
        updating: true,
      };

    case UPDATE_SUCCESS:
      return {
        ...initialState,
      };

    case UPDATE_FAILURE:
      return {
        ...initialState,
        error: errorHandling(action.error),
      };
    case CLEAR_ERROR_UPDATE_FAILURE: 
      return {
        ...initialState,
      }
    default:
      return state;
  }
}

export const clearErrorUpdate = () => ({
  type: CLEAR_ERROR_UPDATE_FAILURE
})

export function updateExample(data: UserT): ApiRequestT<UserT> {
  return {
    type: API_REQUEST,
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAILURE],
    call: () => api.user.update(data),
  };
}

export function changePass(data: ChangeUserPassT): ApiRequestT<UserT> {
  return {
    type: API_REQUEST,
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAILURE],
    call: () => api.user.changePass(data),
  };
}
