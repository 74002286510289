import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';

export type HardwareT = {
  id: number;
  versionMajor: number;
  versionMinor: number;
  versionPatch: number;
};

export class HardwareCRUD extends CRUD<HardwareT> {}

export default function firmwareGroupCrud(request: AxiosInstance): HardwareCRUD {
  const crud = new HardwareCRUD({
    url: '/hardware',
    request,
    id: 'id',
  });

  return crud;
}
