import { AccessControl } from 'accesscontrol';

export type GrantT = {
  role: string;
  resource: string;
  action: string;
};

let ac = new AccessControl();

export function setGrants(grants: GrantT[]): void {
  ac = new AccessControl();
  ac.setGrants(grants).lock();
}

export function checkPermission(resource: string, roleName: string): boolean {
  if (!roleName || !resource) {
    return false;
  }

  return ac.can(roleName).read(resource).granted;
}
