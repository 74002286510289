import React from 'react'
import { DateBox } from 'devextreme-react'
import cx from 'clsx'

import { SelectedPlaceT } from '../../NotificationReportsPage'
import DropDownDevicesTree from '../dropDownDevicesTree/DropDownDevicesTree'

import styles from './Filter.module.scss'

const DATE_BOX_WIDTH = 200

interface FilterInterface {
  onChangePeriod: (key: string, value: string | number) => void
  setSelectedPlace: (data: SelectedPlaceT) => void
  from: any
  to: any
  selectedPlace: string | number
}

const Filter = ({
  onChangePeriod,
  setSelectedPlace,
  selectedPlace,
  from,
  to,
}: FilterInterface) => {
  const onChangePeriodTo = event => {
    const _to = new Date(event.value).setHours(23, 59, 59, 999)

    onChangePeriod('to', _to)
  }
  return (
    <div className="row d-flex justify-content-between">
      <div className="d-flex">
        <div className="column">
          <div className="font-weight-bold">Початок</div>
          <DateBox
            width={DATE_BOX_WIDTH}
            type="datetime"
            onValueChanged={event => {
              onChangePeriod('from', event.value)
            }}
            showClearButton
            defaultValue={new Date().setHours(0, 0, 0, 0)}
            max={typeof to === 'number' && to !== 0 ? to : undefined}
          />
        </div>
        <div className={cx('column', styles.filterItem)}>
          <div className="font-weight-bold">Кінець</div>
          <DateBox
            width={DATE_BOX_WIDTH}
            type="datetime"
            onValueChanged={onChangePeriodTo}
            showClearButton
            min={from}
            value={to}
          />
        </div>
      </div>
      <div>
        <div className={cx('column', styles.filterItem)}>
          <div className="font-weight-bold">Пристрої</div>
          <DropDownDevicesTree
            value={selectedPlace}
            setSelectedPlace={setSelectedPlace}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
