import React, { useState } from 'react';
import DataGrid, { Column, Editing, Scrolling, RequiredRule } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import ColorBox from 'devextreme-react/color-box';
import { Form } from 'devextreme-react/tree-list';
import { GroupItem, Item } from 'devextreme-react/form';

import PageTitle from '../../components/PageTitle/PageTitle';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { storeErrorHandler } from '../../helpers/errorHandling';
import api from '../../api';
import EditTextInput from '../../components/EditTextInput/EditTextInput';

const alarmTypesData: any = new CustomStore({
  key: 'id',
  load: () =>
    api.alarmType
      .get({})
      .then(result => result.data)
      .catch(storeErrorHandler),
  remove: id => api.alarmType.delete(id).catch(storeErrorHandler),
  update: (id, data) => api.alarmType.update({ id, ...data }).catch(storeErrorHandler),
  insert: data => api.alarmType.create(data).catch(storeErrorHandler),
});

const ColorCellComponent = ({ data, isEdit }: { data: any; isEdit: boolean }) => {
  const [color, setColor] = useState(data.value);
  return (
    <ColorBox
      value={color}
      readOnly={!isEdit}
      onValueChanged={(e: any) => {
        data.setValue(e.value);
        setColor(e.value);
      }}
    />
  );
};

const AlarmTypesPage: React.FC = () => {
  return (
    <>
      <PageTitle title="Тривоги" />

      <DataGrid id="grid" showBorders dataSource={alarmTypesData} repaintChangesOnly>
        <Editing refreshMode="full" mode="form" allowAdding allowDeleting allowUpdating useIcons>
          <Form activeStateEnabled focusStateEnabled colCount="1">
            <GroupItem colCount="1">
              <Item dataField="alarmId" />
            </GroupItem>
            <GroupItem colCount="2">
              <Item dataField="name" />
              <Item dataField="color" />
            </GroupItem>

            <Item dataField="description" />
          </Form>
        </Editing>

        <Scrolling mode="virtual" />

        <Column
          editCellRender={cellOption => (
            <EditTextInput cellOption={cellOption} textBoxParams={{ maxLength: 20 }} />
          )}
          dataField="alarmId"
          caption="Alarm ID"
        >
          <RequiredRule />
        </Column>

        <Column
          dataField="name"
          editCellRender={cellOption => (
            <EditTextInput cellOption={cellOption} textBoxParams={{ maxLength: 100 }} />
          )}
          caption="Назва"
        />

        <Column
          dataField="description"
          editCellRender={cellOption => (
            <EditTextInput cellOption={cellOption} textBoxParams={{ maxLength: 250 }} />
          )}
          caption="Опис тривоги"
        />

        <Column
          cellComponent={props => <ColorCellComponent {...props} isEdit={false} />}
          editCellComponent={props => <ColorCellComponent {...props} isEdit />}
          dataField="color"
          caption="Колір"
        />
      </DataGrid>
    </>
  );
};

export default AlarmTypesPage;
