import React from 'react';
import { FieldProps } from 'formik';
import Form from 'react-bootstrap/Form';

type InputFieldProps = {
  type?: string;
  placeholder?: string;
} & FieldProps;

const InputField: React.FC<InputFieldProps> = (props: InputFieldProps) => {
  const {
    type = 'text',
    placeholder = '',
    field,
    form: { errors, touched },
  } = props;

  const error = touched[field.name] && errors[field.name];

  return (
    <Form.Group controlId={field.name}>
      <Form.Control {...field} type={type} placeholder={placeholder} isInvalid={!!error} />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputField;
