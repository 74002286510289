import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter as Router } from 'connected-react-router';
import AuthorizationPage from '../AuthorizationPage/AuthorizationPage';
import Dashboard from '../Dashboard/Dashboard';
import { StateT as AuthState } from '../../store/modules/auth/auth';
import { AppState } from '../../store/modules';
import { history } from '../../store';

const Loading: React.FC = () => <div>loading...</div>;

// TODO: replace with real page after login
const NotFoundPage = (): JSX.Element => <div>Not found page... </div>;

const App: React.FC = () => {
  const { user } = useSelector<AppState, AuthState>(state => state.auth);
  const Component = user ? Dashboard : Loading;

  useEffect(() => {
    const url = window.location.origin;
    if (
      window.location.protocol !== 'https:' &&
      !window.location.toString().includes('localhost')
    ) {
      window.location.replace(`https:${url.split(':')[1]}`);
    }
  });

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" component={AuthorizationPage} />
        <Route path="/" component={Component} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default App;
