import api from '../../../api';
import { API_REQUEST, ApiRequestT } from '../../apiAction';
import { UserT } from '../../../api/modules/user';

const LOAD: 'user/LOAD' = 'user/LOAD';
export const LOAD_SUCCESS: 'user/LOAD_SUCCESS' = 'user/LOAD_SUCCESS';
const LOAD_FAILURE: 'user/LOAD_FAILURE' = 'user/LOAD_FAILURE';

type LoadT = {
  type: typeof LOAD;
};

export type LoadSuccessT = {
  type: typeof LOAD_SUCCESS;
  result: {
    data: Array<UserT>;
  };
};

type LoadFailureT = {
  type: typeof LOAD_FAILURE;
  error: string | null;
};

type ActionTypeT = LoadT | LoadSuccessT | LoadFailureT;

export type StateT = {
  loading: boolean;
  loaded: boolean;
  error: string | null;
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  error: null,
};

export default function exampleDataReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {
  switch (action.type) {
    case LOAD:
      return {
        ...initialState,
        loading: true,
      };

    case LOAD_SUCCESS:
      return {
        ...initialState,
        loaded: true,
      };

    case LOAD_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
}

export function loadUser(params: any): ApiRequestT<Array<UserT>> {
  return {
    type: API_REQUEST,
    types: [LOAD, LOAD_SUCCESS, LOAD_FAILURE],
    call: () => api.user.get(params),
  };
}
