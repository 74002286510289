/* eslint-disable no-loop-func */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react'
import {
  Column,
  Editing,
  Selection,
  TreeList,
  Lookup,
  RowDragging,
  Form,
  Item,
  RequiredRule,
} from 'devextreme-react/tree-list'
import { Template } from 'devextreme-react/core/template'
import CustomStore from 'devextreme/data/custom_store'

import api from '../../../../api'
import { ConfigT } from '../../../../api/modules/config'
import { DeviceT } from '../../../../api/modules/device'
import { storeErrorHandler } from '../../../../helpers/errorHandling'
import EditTextInput from '../../../../components/EditTextInput/EditTextInput'

const expandedRowKeys = [1]

function NameCell({ data }: { data: ConfigT }): React.ReactFragment {
  const icon = data.deviceId ? 'fa fa-microchip' : 'fa fa-object-group'

  return (
    <>
      <i className={icon} /> {data.name}
    </>
  )
}

const allowDeleting = (e: any): boolean => {
  return e.row.data.parentId !== 0 && !e.row.node.children.length
}

const allowAdding = (e: any): boolean => !e.row.data.deviceId

const deviceName = (d: DeviceT): string =>
  d.deviceId ? d.deviceId : d.deviceMac

function collectLinkedDevices(tree: any, devices: any): number[] {
  const visited = []
  const queue = []
  let devicesWithoutParent = devices
  let current: ConfigT | void = tree

  queue.push(current)
  while (queue.length) {
    current = queue.shift()
    if (!current) {
      continue
    }
    if (current.deviceId) {
      devicesWithoutParent = devicesWithoutParent.filter(
        (device: any) => current && current.deviceId !== device.id
      ) as any
      visited.push(current.deviceId)
    }

    queue.push(...current.children)
  }
  return [visited, devicesWithoutParent]
}

function disableAddingDevice({ dataField, row, editorOptions }: any): void {
  if (dataField === 'deviceId' && row.node.children.length) {
    // eslint-disable-next-line no-param-reassign
    editorOptions.readOnly = true
  }
}

interface PropsI {
  isCollapsed: boolean

  onSelect: (node: ConfigT | undefined) => void
}

export default function Tree(props: PropsI): React.ReactElement {
  const [treeDevisesWithoutParent, setDevices] = useState<any>({})
  const deviceTreeRef = useRef<TreeList>(null)
  const parameterTreeRef = useRef<TreeList>(null)
  const handleSelectionChanged = (event: {
    selectedRowsData?: Array<ConfigT>
  }) => {
    const selectedNode = event?.selectedRowsData?.[0]
    // selectedNode.id is undefined for new nodes (creating)
    if (!selectedNode || !selectedNode.id) {
      return
    }
    props.onSelect(selectedNode)
  }

  // I can't use useEffect here because component is not updates after tree was modified

  useEffect(() => {
    deviceTreeRef.current?.instance.refresh()
  }, [treeDevisesWithoutParent])

  useEffect(() => {
    parameterTreeRef.current?.instance.forEachNode((node: any) => {
      parameterTreeRef.current?.instance[
        !props.isCollapsed ? 'collapseRow' : 'expandRow'
      ](node.key)
    })
  }, [props.isCollapsed])

  let tree: ConfigT
  let devices: DeviceT[] = []
  let linked: number[] = []

  const configStore: any = useRef(
    new CustomStore({
      key: 'id',
      load: async () => {
        const batch = [
          api.config
            .get({})
            .then((result: any) => result.data)
            .catch(storeErrorHandler),
          api.device
            .get({})
            .then(result => result.data)
            .catch(storeErrorHandler),
        ]
        ;[tree, devices] = await Promise.all(batch)

        const [visited, devisesWithoutParent]: any = collectLinkedDevices(
          tree,
          devices
        )
        linked = visited
        const devisesWithoutParentTree = {
          name: 'Пристрої без призначення',
          deviceId: null,
          children: devisesWithoutParent.map((device: any) => {
            const updateDevice = { ...device }
            updateDevice.children = []
            return updateDevice
          }),
          order: 0,
          id: 'devicesTree',
        }
        setDevices(devisesWithoutParentTree)

        return tree
      },
      remove: id =>
        api.config
          .delete(id)
          .then(() => {
            props.onSelect(undefined)
          })
          .catch(storeErrorHandler),
      update: (id, data) =>
        api.config.update({ id, ...data }).catch(storeErrorHandler),
      insert: data => api.config.create(data).catch(storeErrorHandler),
    })
  ).current

  function getDeviceData({ data }: { data: ConfigT }): DeviceT[] {
    if (!data) {
      return []
    }

    return devices.filter(d => !linked.includes(d.id) || d.id === data.deviceId)
  }

  const onReorder = (e: any): void => {
    const visibleRows = e.component.getVisibleRows()
    const sourceData = e.itemData
    const targetData = visibleRows[e.toIndex].data

    if (e.dropInsideItem && sourceData.deviceId && !targetData.deviceId) {
      const reorderIds = visibleRows.reduce((prev: any, current: any) => {
        if (current.data.parentId === targetData.parentId) {
          if (prev) {
            return [...prev, current.data.id]
          }
          return [current.data.id]
        }
        return prev
      }, 0)
      const targetItemIndex = reorderIds.findIndex(
        (item: number) => item === targetData.id
      )
      const resolve: number[] = [
        ...reorderIds.slice(0, targetItemIndex + 1),
        sourceData.id,
        ...reorderIds.slice(targetItemIndex + 1),
      ]

      configStore
        .update(targetData.id, {
          parameterIds: resolve,
          deviceId: sourceData.deviceId,
        })
        .then(() => {
          configStore.load()
        })
        .then(() => {
          const selectedNode = parameterTreeRef.current?.instance.getSelectedRowsData()
          props.onSelect(selectedNode?.length ? selectedNode[0] : undefined)
          parameterTreeRef.current?.instance['expandRow'](targetData.id)
          e.component.refresh()
        })
    } else if (targetData.parentId === sourceData.parentId) {
      const reorderIds = visibleRows.reduce((prev: any, current: any) => {
        if (current.data.parentId === sourceData.parentId) {
          if (prev) {
            return [...prev, current.data.id]
          }
          return [current.data.id]
        }
        return Array.isArray(prev) ? [...prev] : []
      }, 0)

      const sourceItemIndex = reorderIds.indexOf(sourceData.id)
      const targetItemIndex = reorderIds.indexOf(targetData.id)
      const dragValues = reorderIds.splice(sourceItemIndex, 1)
      reorderIds.splice(targetItemIndex, 0, ...dragValues)

      configStore
        .update(sourceData.parentId, {
          parameterIds: reorderIds,
          deviceId: sourceData.deviceId,
        })
        .then(() => {
          configStore.load()
        })
        .then(() => {
          const selectedNode = parameterTreeRef.current?.instance.getSelectedRowsData()
          parameterTreeRef.current?.instance['expandRow'](targetData.id)
          props.onSelect(selectedNode?.length ? selectedNode[0] : undefined)
          e.component.refresh()
        })
    } else if (sourceData.deviceId) {
      const reorderIds = visibleRows.reduce((prev: any, current: any) => {
        if (current.data.parentId === targetData.parentId) {
          if (prev) {
            return [...prev, current.data.id]
          }
          return [current.data.id]
        }
        return prev
      }, 0)

      const targetItemIndex = reorderIds.findIndex(
        (item: number) => item === targetData.id
      )
      const resolve: number[] = [
        ...reorderIds.slice(0, targetItemIndex + 1),
        sourceData.id,
        ...reorderIds.slice(targetItemIndex + 1),
      ]

      configStore
        .update(targetData.parentId, {
          parameterIds: resolve,
          deviceId: sourceData.deviceId,
        })
        .then(() => {
          configStore.load()
        })
        .then(() => {
          const selectedNode = parameterTreeRef.current?.instance.getSelectedRowsData()
          parameterTreeRef.current?.instance['expandRow'](targetData.id)
          props.onSelect(selectedNode?.length ? selectedNode[0] : undefined)
          e.component.refresh()
        })
    }
  }

  const onChangeDeviceTree = (e: any): void => {
    const visibleRows = e.component.getVisibleRows()
    const sourceData = e.itemData
    const targetData = visibleRows[e.toIndex]?.data

    if (!targetData || !sourceData.deviceId) return
    if (sourceData.parentId === 'devicesTree') {
      configStore
        .insert({
          parentId:
            e.dropInsideItem && !targetData.deviceId
              ? targetData.id
              : targetData.parentId,
          name: sourceData.name,
          deviceId: sourceData.id,
        })
        .then(() => {
          configStore.load()
        })
        .then(() => {
          e.component.refresh()
        })
    } else {
      configStore
        .remove(sourceData.id)
        .then(() => {
          configStore.load()
        })
        .then(() => {
          parameterTreeRef.current?.instance.refresh()
          e.component.refresh()
        })
    }
  }

  return (
    <div className="col-6">
      <TreeList
        ref={parameterTreeRef}
        id="configTree"
        dataSource={configStore}
        defaultExpandedRowKeys={expandedRowKeys}
        showRowLines
        showBorders
        columnAutoWidth
        itemsExpr="children"
        dataStructure="tree"
        onSelectionChanged={handleSelectionChanged}
        onEditorPreparing={disableAddingDevice}
      >
        <Selection mode="single" />
        <Editing
          refreshMode="full"
          mode="form"
          allowUpdating
          allowDeleting={allowDeleting}
          allowAdding={allowAdding}
          useIcons
        >
          <Form activeStateEnabled focusStateEnabled colCount="1">
            <Item dataField="name" />
          </Form>
        </Editing>
        <RowDragging
          onReorder={onReorder}
          group="tasksGroup"
          allowDropInsideItem
          allowReordering
          showDragIcons
          onAdd={onChangeDeviceTree}
        />
        <Column
          editCellRender={cellOption => (
            <EditTextInput
              cellOption={cellOption}
              textBoxParams={{ maxLength: 255 }}
            />
          )}
          dataField="name"
          caption="Ім'я"
          cellTemplate="nameTemplate"
        >
          <RequiredRule />
        </Column>
        <Column dataField="deviceId" caption="Турнікет" visible={false}>
          <Lookup
            dataSource={getDeviceData}
            valueExpr="id"
            displayExpr={deviceName}
            allowClearing
          />
        </Column>

        <Template name="nameTemplate" render={NameCell} />
      </TreeList>

      <TreeList
        ref={deviceTreeRef}
        id="devicesTree"
        defaultExpandedRowKeys={expandedRowKeys}
        dataSource={[treeDevisesWithoutParent]}
        showRowLines
        showBorders
        columnAutoWidth
        itemsExpr="children"
        dataStructure="tree"
      >
        <RowDragging
          group="tasksGroup"
          onAdd={onChangeDeviceTree}
          allowDropInsideItem
          allowReordering
          showDragIcons
        />
        <Column
          editCellRender={cellOption => (
            <EditTextInput
              cellOption={cellOption}
              textBoxParams={{ maxLength: 255 }}
            />
          )}
          dataField="name"
          caption="Ім'я"
          cellTemplate="nameTemplate"
        >
          <RequiredRule />
        </Column>
        <Column dataField="deviceId" caption="Турнікет" visible={false} />
        <Template name="nameTemplate" render={NameCell} />
      </TreeList>
    </div>
  )
}
