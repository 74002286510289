import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';
import { FileT } from './fw-file';

export type FirmwareT = {
  id: number;
  versionMajor: number;
  versionMinor: number;
  versionPatch: number;
  enabled: boolean;
  group: any;
  hardware: any;
  files: FileT[];
};

export class FirmwareCRUD extends CRUD<FirmwareT> {}

export default function firmwareCrud(request: AxiosInstance): FirmwareCRUD {
  const crud = new FirmwareCRUD({
    url: '/firmware',
    request,
    id: 'id',
  });

  return crud;
}
