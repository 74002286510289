import api from '../../../api';
import { API_REQUEST, ApiRequestT } from '../../apiAction';

const DELETE: 'user/DELETE' = 'user/DELETE';
export const DELETE_SUCCESS: 'user/DELETE_SUCCESS' = 'user/DELETE_SUCCESS';
const DELETE_FAILURE: 'user/DELETE_FAILURE' = 'user/DELETE_FAILURE';

type DeleteT = {
  type: typeof DELETE;
};
export type DeleteSuccessT = {
  type: typeof DELETE_SUCCESS;
  result: {
    data: any;
  };
};
type DeleteFailureT = {
  type: typeof DELETE_FAILURE;
  error: string | null;
};

type ActionTypeT = DeleteT | DeleteSuccessT | DeleteFailureT;

export type StateT = {
  deleting: boolean;
  error: string | null;
};

const initialState: StateT = {
  deleting: false,
  error: null,
};

export default function userDeleteReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {
  switch (action.type) {
    case DELETE:
      return {
        ...initialState,
        deleting: true,
      };

    case DELETE_SUCCESS:
      return {
        ...initialState,
      };

    case DELETE_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
}

export function deleteuser(id: number): ApiRequestT<void> {
  return {
    type: API_REQUEST,
    types: [DELETE, DELETE_SUCCESS, DELETE_FAILURE],
    call: () => api.user.delete(id),
  };
}
