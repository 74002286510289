import React, { memo, useMemo, useState, useRef } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source'

import PivotGrid, { FieldChooser } from 'devextreme-react/pivot-grid'

import { uniqueId } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spinner } from 'react-bootstrap'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'

import styles from './MountlyTransportationLobby.module.scss'

import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'
import { getTransportationLobbyReportCsvPdfAction } from '../../store/sagas/transaction-report'

export type SelectedPlaceT = {
  name?: string | number
  deviceId?: number
  id?: number
}

const date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth()
const firstDay = new Date(y, m, 1)
const lastDay = new Date(y, m + 1, 0)

function MountlyTransportationLobby() {
  const dataGridRef = useRef<PivotGrid>(null)

  const dispatch = useDispatch()

  const isFetchingCsv = useSelector(
    (store: any) => store.transactionState.isFetchingCsv
  )
  const [isFetching, setIsFetching] = useState(false)
  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState({
    from: firstDay.setHours(0, 0, 0, 0),
    to: lastDay.setHours(23, 59, 59, 999),
  })
  const [isError, setIsError] = useState(false)

  const monthlyTransportation: any = useMemo(() => {
    return new CustomStore({
      key: 'id',
      load: async () => {
        setIsFetching(true)
        const res = await api.reports
          .getMonthlyTransportationReportLobby({
            ...period,
            nodeId: place.id,
          })
          .then((result: any) => {
            setIsError(false)
            return result.data
          })
          .catch((e: any) => {
            setIsError(true)
            storeErrorHandler(e)
          })
          .finally(() => {
            setIsFetching(false)
          })

        const _mappedRes = res?.map((item: any) => {
          return {
            ...item,
            id: uniqueId(),
            dt: new Date(item.dt).toLocaleDateString(),
          }
        })

        return _mappedRes
      },
    })
  }, [period, place])

  const dataSource = useMemo(() => {
    return new PivotGridDataSource({
      fields: [
        {
          caption: 'Станция',
          width: 120,
          dataField: 'sname',
          area: 'row',
        },
        {
          caption: 'Вестибюль',
          dataField: 'hname',
          width: 150,
          area: 'row',
        },
        {
          dataField: 'type',
          width: 150,
          area: 'row',
          selector: function(data) {
            switch (data.type) {
              case 'kcard':
                return 'Картка Киянина'
              case 'bcard':
                return 'Банківська Картка'
              case 'scard':
                return 'Смарт Картка'
              case 'qr':
                return 'QR Картка'
              default:
                return 'Інше'
            }
          },
        },
        {
          dataField: 'count',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',
        },
        {
          dataField: 'dt',
          dataType: 'string',
          area: 'column',
          sortBy: 'none',
        },
      ],
      store: monthlyTransportation,
    })
  }, [monthlyTransportation])

  function onChangePeriod(values: { from: number; to: number }) {
    setPeriod({
      from: values.from,
      to: values.to,
    })
  }

  function updateData() {
    if (place.id) {
      monthlyTransportation.load()
    }
  }

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column mb-4">
        <PageTitle
          title="Звіт з перевезень за місяць вестибюль"
          className="mb-4"
        />
        <ForPeriodTitleSection
          from={period.from}
          to={period.to}
          onUpdate={updateData}
          isFetching={isFetching}
          isUpdateButton
        />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          setSelectedPlace={setPlace}
          selectedPlace={place.name || ''}
        />
        {!!period.from && !!period.to && !!place.id && (
          <div className="row justify-content-end align-items-center pr-3 mt-3">
            {isFetchingCsv && <Spinner animation="border" variant="primary" />}
            {!isError && (
              <Button
                onClick={() => {
                  dispatch(
                    getTransportationLobbyReportCsvPdfAction({
                      ...period,
                      nodeId: place.id,
                      reportType: 'xls',
                    })
                  )
                }}
                disabled={isFetchingCsv}
                variant="outline-primary"
                className={styles.downloadButton}
              >
                XLS
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="row">
        {!!period.from && !!period.to && !!place.id ? (
          <div className="d-flex flex-column pr-3 mb-3">
            <PivotGrid
              ref={dataGridRef}
              dataSource={dataSource}
              allowSorting={true}
              allowFiltering={true}
              width={1140}
              showBorders={true}
            >
              <FieldChooser enabled={false} />
            </PivotGrid>
          </div>
        ) : (
          <div>
            <span>Необхідно вибрати початкову і кінцеву дату та Станцію</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MountlyTransportationLobby)
