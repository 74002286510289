import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  ColumnChooser,
} from 'devextreme-react/data-grid'
import { Template } from 'devextreme-react/core/template'
import { uniqueId } from 'lodash'

import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'
import styles from './EventsFromTurnstilesReport.module.scss'
import Filter from './components/filter/Filter'
import PageTitle from '../../components/PageTitle/PageTitle'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import { getEventsTurnstilesReportXls } from '../../store/sagas/transaction-report'

export type SelectedPlaceT = {
  name?: any
  id?: any
}

export type SelectedDateT = {
  from?: string | number | null
  to?: number | string | null
}

const defaultColumns = ['_time', 'device_id', 'event', 'description', 'id']

const EventsFromTurnstilesReport: React.FC = () => {
  const dispatch = useDispatch()
  const location: any = useLocation()
  const query = new URLSearchParams(location.search)
  const _deviceId = query.get('device-id')
  const _deviceName = query.get('device-name')
  const isFetchingCsv = useSelector(
    (state: any) => state?.transactionState?.isFetchingCsv
  )
  const dataGridRef = useRef<DataGrid>(null)

  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState<SelectedDateT>({
    from: null,
    to: null,
  })

  const [columns, setColumns] = useState<String[]>([])

  const eventReport: any = useMemo(
    () =>
      new CustomStore({
        key: '_id',
        load: async ({ skip }) => {
          const _page = !skip ? 1 : skip / 100 + 1
          try {
            const res = await api.reports.getEventTurnstilesReport({
              page: _page,
              perPage: 100,
              nodeId: place?.id,
              ...period,
            })

            const _columns = [...columns, ...(res?.data?.columns || [])].reduce(
              (res: any, item: any) => {
                if (defaultColumns.some(d => d === item)) return res
                res[item] = item
                return res
              },
              {}
            )
            setColumns(Object.keys(_columns))
            const _data = res?.data?.rows?.map(i => ({ ...i, _id: uniqueId() }))

            return {
              data: _data,
              totalCount: res.data.count,
            }
          } catch (error) {
            storeErrorHandler(error)
          }
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [place, period]
  )

  function onChangePeriod(key: string, value: any) {
    setPeriod({
      ...period,
      [key]: value,
    })
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const downloadReport = () => {
    dispatch(
      getEventsTurnstilesReportXls({
        nodeId: place.id,
        reportType: 'xls',
        ...period,
        perPage: 10000,
        page: 1,
      })
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: updateData,
        },
      }
    )
  }

  useEffect(updateData, [period, place])

  useLayoutEffect(() => {
    if (_deviceId) {
      const nowDay = new Date()

      const to = nowDay.getTime()
      let from = nowDay.setHours(nowDay.getHours() - 2)

      setPlace({ id: _deviceId, name: _deviceName })
      setPeriod({
        from,
        to,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (place.id) {
      query.set('device-id', String(place?.id))
      query.set('device-name', String(place?.name))
      window.history.replaceState(null, '', '?' + query.toString())
    }
    if (!place.id) {
      window.history.replaceState(null, '', 'events-turnstiles-report')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place])

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column">
        <PageTitle title="Звіт з подій від турнікетів" className="mb-4" />
        <ForPeriodTitleSection from={period.from} to={period.to} />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          setSelectedPlace={setPlace}
          selectedPlace={place.name || ''}
        />
      </div>
      <div className="row">
        {place?.id && period.from && period.to ? (
          <div className="d-flex flex-column">
            <DataGrid
              ref={dataGridRef}
              dataSource={eventReport}
              showBorders
              allowColumnReordering={true}
              className={styles.transactionTableContainer}
              remoteOperations={true}
              sorting={{ mode: 'none' }}
              onToolbarPreparing={onToolbarPreparing}
            >
              <ColumnChooser enabled={true} />
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Paging defaultPageSize={100} />
              <Column
                dataField="_time"
                //caption="Дата Транзакції"
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
                width={150}
              />
              <Column
                dataField="device_id"
                //caption="Номер Транзакції"
                width={'auto'}
              />
              <Column
                dataField="event"
                //caption="Залишок грошей"
                width={'auto'}
              />
              <Column
                dataField="description"
                //caption="Тип Транзакції"
                width={'30%'}
              />
              <Column dataField="id" caption="Card ID" width={'auto'} />
              {columns.map((item: string) => {
                return (
                  <Column
                    dataField={item}
                    width={'auto'}
                    visible={false}
                    key={item}
                  />
                )
              })}
              {/* <Column dataField="pan" width={'auto'} visible={false} />
              <Column
                dataField="Amount"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="AuthCode"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="InvoiceNum"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="Pan"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="RRN"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="TermId"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="bookletsleft"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="counter"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="id"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="request"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="response"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="result"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="table"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="uid"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              />
              <Column
                dataField="_measurement"
                //caption="Залишок поїздок"
                visible={false}
                width={'auto'}
              /> */}
              <Template name="spinner" render={spinnerComponent} />
            </DataGrid>
          </div>
        ) : (
          <div>
            <span>Необхідно вибрати пристрій та дату</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default EventsFromTurnstilesReport
