import React from 'react'

function GoogleIcon() {
  return (
    <svg
      width="35px"
      height="35px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Log-in"
          transform="translate(-888.000000, -365.000000)"
          fillRule="nonzero"
        >
          <g id="Group-6-Copy" transform="translate(746.000000, 247.000000)">
            <g id="Group-5" transform="translate(0.000000, 59.000000)">
              <g id="Group-2" transform="translate(62.000000, 48.000000)">
                <g id="Group-3" transform="translate(80.000000, 11.000000)">
                  <g id="search">
                    <path
                      d="M3.98917969,10.877625 L3.362625,13.2166406 L1.07258203,13.2650859 C0.388195312,11.9956992 0,10.5433594 0,9 C0,7.50758203 0.362953125,6.10020703 1.0063125,4.86098438 L1.00680469,4.86098438 L3.04558594,5.23476563 L3.93869531,7.2613125 C3.75176953,7.80626953 3.64988672,8.39126953 3.64988672,9 C3.64995703,9.66065625 3.76962891,10.2936445 3.98917969,10.877625 Z"
                      id="Path"
                      fill="#FBBB00"
                    ></path>
                    <path
                      d="M17.8427461,7.3186875 C17.9461055,7.86311719 18,8.42537109 18,9 C18,9.64434375 17.9322539,10.2728672 17.8031953,10.8791367 C17.3650781,12.9422109 16.2202852,14.7436875 14.6344219,16.0185234 L14.6339297,16.0180312 L12.0659766,15.8870039 L11.7025312,13.6181953 C12.7548281,13.0010625 13.5772031,12.0352852 14.0103984,10.8791367 L9.19785937,10.8791367 L9.19785937,7.3186875 L14.0806055,7.3186875 L17.8427461,7.3186875 L17.8427461,7.3186875 Z"
                      id="Path"
                      fill="#518EF8"
                    ></path>
                    <path
                      d="M14.6338945,16.0180312 L14.6343867,16.0185234 C13.0920469,17.2582383 11.1327891,18 9,18 C5.57258203,18 2.59270312,16.0843008 1.07258203,13.2651211 L3.98917969,10.8776602 C4.74922266,12.9061055 6.70598437,14.3500781 9,14.3500781 C9.98602734,14.3500781 10.909793,14.0835234 11.7024609,13.6181953 L14.6338945,16.0180312 Z"
                      id="Path"
                      fill="#28B446"
                    ></path>
                    <path
                      d="M14.7446719,2.07196875 L11.8290586,4.4589375 C11.0086875,3.94614844 10.0389375,3.64992187 9,3.64992187 C6.65405859,3.64992187 4.66069922,5.16012891 3.93873047,7.2613125 L1.00680469,4.86098437 L1.0063125,4.86098437 C2.50417969,1.97307422 5.52164062,0 9,0 C11.1837305,0 13.1859844,0.777867187 14.7446719,2.07196875 Z"
                      id="Path"
                      fill="#F14336"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default GoogleIcon
