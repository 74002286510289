import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';

export type MetricT = {
  id: number;
  name: string;
  type: string;
  timeSeries: boolean;
};

export class MetricAttributeCRUD extends CRUD<MetricT> {}

export default function metricAttributeCRUD(request: AxiosInstance): MetricAttributeCRUD {
  return new MetricAttributeCRUD({
    url: '/metric/attribute',
    request,
    id: 'id',
  });
}
