import React from 'react'
import DataGrid, {
  Column,
  Editing,
  Scrolling,
  Button,
  Lookup,
  RequiredRule,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import { CheckBox } from 'devextreme-react'
import clsx from 'clsx'

import PageTitle from '../../components/PageTitle/PageTitle'
import { storeErrorHandler } from '../../helpers/errorHandling'
import api from '../../api'

import styles from './MetricsPage.module.scss'
import CodeEditor from '../../components/CodeEditor/CodeEditor'

const typesData = ['string', 'number', 'boolean', 'timestamp']

const metricsData: any = new CustomStore({
  key: 'id',
  load: () =>
    api.metricAttribute
      .get({})
      .then(result => result.data)
      .catch(storeErrorHandler),
  remove: id => api.metricAttribute.delete(id).catch(storeErrorHandler),
  update: (id, data) =>
    api.metricAttribute.update({ id, ...data }).catch(storeErrorHandler),
  insert: data => api.metricAttribute.create(data).catch(storeErrorHandler),
})

const ExpandText: React.FC = (data: any) => {
  return (
    <span className={clsx(styles.descriptionText)}>
      {data.value ? data.value : ''}
    </span>
  )
}

const renderCheckboxForScript: React.FC = (data: any) => {
  return data.value && <CheckBox value={!!data.value} />
}

const renderCodeEditingButton: React.FC = (cellOptions: any) => {
  return <CodeEditor cellOptions={cellOptions} />
}

const MetricsPage: React.FC = () => {
  function onEditorPreparing(e: any) {
    if (e.parentType === 'dataRow' && e.dataField === 'script') {
      e.editorName = 'dxTextArea'
    }
  }

  return (
    <>
      <PageTitle title="Метрики" />

      <DataGrid
        onEditorPreparing={onEditorPreparing}
        id="grid"
        showBorders
        dataSource={metricsData}
        repaintChangesOnly
      >
        <Editing
          refreshMode="full"
          mode="form"
          allowAdding
          allowDeleting
          allowUpdating
          useIcons
        />

        <Scrolling mode="virtual" />

        <Column dataField="name" caption="Назва метрики">
          <RequiredRule />
        </Column>
        <Column dataField="type" caption="Тип">
          <RequiredRule />
          <Lookup dataSource={typesData} />
        </Column>
        <Column
          cellRender={ExpandText}
          dataField="description"
          caption="Опис"
        />
        <Column dataField="timeSeries" caption="Логування" />
        <Column
          dataField="script"
          caption="Скрипт"
          cellRender={renderCheckboxForScript}
          alignment="center"
          editCellRender={cellOptions => renderCodeEditingButton(cellOptions)}
        />
        <Column type="buttons">
          <Button name="edit" />
          <Button name="delete" />
        </Column>
      </DataGrid>
    </>
  )
}

export default MetricsPage
