import { AxiosInstance, AxiosPromise } from 'axios';
import CRUD from '../base/crud';
import { AlarmT } from './alarm';

export type ConfigT = {
  id: number;
  name: string;
  deviceId: number;
  parentId: number;
  line: any;
  metro: any;
  children: ConfigT[];
  order: number;
};

export interface ConfigWithAlarm extends ConfigT {
  alarms?: AlarmT[];
}

export class ConfigCRUD extends CRUD<ConfigT> {
  state(): AxiosPromise<ConfigWithAlarm> {
    return this.r({
      method: 'GET',
      url: `${this.config.url}/state`,
    });
  }

  withoutMetrics() {
    return this.r({
      method: 'GET',
      url: `${this.config.url}/without-metrics`,
    })
  }
}

export default function configCRUD(request: AxiosInstance): ConfigCRUD {
  return new ConfigCRUD({
    url: '/parameter',
    request,
    id: 'id',
  });
}
