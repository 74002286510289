import base64url from 'base64url';

const publicKeyCredentialToJSON = (
  pubKeyCred: string | Buffer,
): string | Buffer | Array<string | Buffer> | any => {
  if (pubKeyCred instanceof Array) {
    const arr = [];
    for (const i of pubKeyCred) arr.push(publicKeyCredentialToJSON(i));

    return arr;
  }

  if (pubKeyCred instanceof ArrayBuffer) {
    return base64url.encode(pubKeyCred);
  }

  if (pubKeyCred instanceof Object) {
    const obj: any = {};

    // eslint-disable-next-line guard-for-in
    for (const key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key] as any);
    }

    return obj;
  }
  
  return pubKeyCred;
};

const generateRandomBuffer = (len: number): Uint8Array => {
  const length = len || 32;

  const randomBuffer = new Uint8Array(length);
  window.crypto.getRandomValues(randomBuffer);

  return randomBuffer;
};

export const preformatMakeCredReq = (makeCredReq: any) => {
  const challenge = { ...makeCredReq };
  challenge.challenge = base64url.toBuffer(makeCredReq.challenge);
  challenge.user.id = base64url.toBuffer(makeCredReq.user.id);

  return challenge;
};

const preformatGetAssertReq = (getAssert: any) => {
  const publecKey = { ...getAssert };

  publecKey.challenge = base64url.toBuffer(getAssert.challenge);

  publecKey.allowCredentials = publecKey.allowCredentials.map((item: any) => {
    const updateCredectial = { ...item };
    updateCredectial.id = base64url.toBuffer(item.id);
    return updateCredectial;
  });

  publecKey.userVerification = 'discouraged';
  return publecKey;
};

const isWebAuthAvailable = !(
  window.PublicKeyCredential === undefined ||
  typeof window.PublicKeyCredential !== 'function' ||
  typeof window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== 'function'
);

export {
  publicKeyCredentialToJSON,
  generateRandomBuffer,
  preformatGetAssertReq,
  isWebAuthAvailable,
};
