import React, { useEffect, useState, memo } from 'react'
import Plotly from 'react-plotly.js'
import { cloneDeep, isEmpty } from 'lodash'

import { INTERVAL_MIN } from './defaultParams'

const parseMin = (min: number) => (min < 10 ? `0${min}` : min)

const convertUtcIntervalToLocal = (numberInterval: number) => {
  const _offset = Math.abs(new Date().getTimezoneOffset()) / INTERVAL_MIN

  return numberInterval + _offset > 96
    ? numberInterval + _offset - 96
    : numberInterval + _offset
}

const setTimeOfInterval = (numberInterval: any, type?: any) => {
  const startDayTime = new Date().setHours(0, 0, 0, 0)

  const _intervalLocal = convertUtcIntervalToLocal(numberInterval)

  const startDate = new Date(
    new Date(startDayTime).setMinutes(INTERVAL_MIN * _intervalLocal)
  )

  const endDate = new Date(
    new Date(startDayTime).setMinutes(
      INTERVAL_MIN * _intervalLocal + INTERVAL_MIN
    )
  )

  const res = `${startDate.getHours()}:${parseMin(
    startDate.getMinutes()
  )} - ${endDate.getHours()}:${parseMin(endDate.getMinutes())}`

  return type === 'start'
    ? `${startDate.getHours()}:${parseMin(startDate.getMinutes())}`
    : res
}

const parseData = (data: any, entranceTypes: any) => {
  const defaultTamplate = { x: [], text: [] }

  for (const [key] of Object.entries(entranceTypes)) {
    defaultTamplate[key] = {
      y: [],
    }
  }

  const _res = data.reduce((res: any, item: any) => {
    let countEvent = 0
    for (const [key] of Object.entries(entranceTypes)) {
      countEvent = countEvent + item[key]
    }

    for (const [key] of Object.entries(res)) {
      if (countEvent) {
        if (key === 'x') {
          res[key]?.push(setTimeOfInterval(Number(item.mint), 'start'))
        } else if (key === 'text') {
          res[key]?.push(
            `<b>Інтервал: </b>${setTimeOfInterval(Number(item.mint))}`
          )
        } else {
          res[key]?.y?.push(Number(item[key]))
        }
      }
    }
    return res
  }, cloneDeep(defaultTamplate))

  return _res
}

const Histogram = (props: any) => {
  const { data, filterCheckedState, titleDate, barmode, entranceTypes } = props

  const [hystogramData, setHystogramData] = useState([])
  const [dataHistogram, setDataHistogram] = useState({ x: [], text: [] })

  const makeHystogramDataByKey = (key: any) => {
    const _key: any = dataHistogram[key]
    const _y: any = _key?.y

    if (Math.max.apply(null, _key?.y) === 0) return false
    const data: any = {
      x: dataHistogram?.x,
      text: dataHistogram?.text,
      y: _y,
      name: entranceTypes[key]?.label,
      xbins: {
        end: 96,
        size: 1,
        start: 0,
      },
      marker: {
        line: {
          width: 1,
        },
      },
      hoverinfo: 'y+text',
      histfunc: 'sum',
      type: 'histogram',
    }
    return data
  }

  useEffect(() => {
    if (!isEmpty(entranceTypes) && data.length) {
      setDataHistogram(parseData(data, entranceTypes))
    }
  }, [entranceTypes, data])

  useEffect(() => {
    const _hystogramData = filterCheckedState.reduce((res: any, f: any) => {
      const _data = makeHystogramDataByKey(f)
      if (_data) {
        res.push(_data)
      }

      return res
    }, [])

    setHystogramData(_hystogramData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCheckedState, data, dataHistogram])

  return (
    <div className="d-flex">
      <Plotly
        data={hystogramData as any}
        layout={{
          width: 1140,
          margin: { b: 120 },
          barmode: barmode,
          boxmode: 'group',
          hovermode: 'closest',
          title: titleDate ? new Date(titleDate).toLocaleDateString() : '',
          yaxis: { title: 'Кількість проходів' },
          xaxis: {
            title: 'Час (доба з інтервалом 15 хвилин)',
            rangemode: 'normal',
          },
        }}
      />
    </div>
  )
}

export default memo(Histogram)
