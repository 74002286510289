import React, { useEffect, useRef, useState } from 'react';
import { DropDownBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import { Column, Selection, TreeList } from 'devextreme-react/tree-list';

import api from '../../api';
import { ConfigT } from '../../api/modules/config';
import { storeErrorHandler } from '../../helpers/errorHandling';
import { Scrolling } from 'devextreme-react/data-grid';

const expandedRowKeys = [1];

const groupListStore: any = new CustomStore({
  key: 'id',
  loadMode: 'raw',
  load: async () =>
    api.firmwareGroup
      .get({})
      .then(result => {
        return result.data;
      })
      .catch(storeErrorHandler),
});

interface DropDownDevicesTreeI {
  cellOption: any;
  dataSource?: any;
}

interface Value {
  name: string;
  value: string | number | null;
}

const GroupsListDropDown = ({ cellOption, dataSource }: DropDownDevicesTreeI) => {
  const [value, setValue] = useState<Value>({
    name: '',
    value: null,
  });

  const dropDownRef = useRef<DropDownBox>(null);
  const treeListRef = useRef<TreeList>(null);

  const handleSelectionChanged = (event: { selectedRowsData?: Array<ConfigT> }) => {
    const selectedNode = event?.selectedRowsData?.[0];
    if (!selectedNode || !selectedNode.id) {
      return;
    }

    const { name, id } = selectedNode;

    setValue({
      name,
      value: id,
    });
    if (dropDownRef.current) {
      dropDownRef.current.instance.close();
    }
  };

  useEffect(() => {
    if (cellOption?.data.firmwareGroupId) {
      const group = dataSource.find((g: any) => g.id === cellOption?.data.firmwareGroupId);

      if (group) {
        setValue({
          name: group.name,
          value: group.id,
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    cellOption.setValue(value.value);
    // eslint-disable-next-line
  }, [value]);

  return (
    <DropDownBox
      ref={dropDownRef}
      value={value.name}
      valueExpr="ID"
      displayExpr="name"
      placeholder="Виберіть группу..."
      showClearButton
      onValueChanged={() => {
        // eslint-disable-next-line no-unused-expressions
        treeListRef.current?.instance.deselectAll();
        return setValue({
          name: '',
          value: null,
        });
      }}
      contentRender={() => (
        <TreeList
          ref={treeListRef}
          id="configTree"
          dataSource={dataSource || groupListStore}
          defaultExpandedRowKeys={expandedRowKeys}
          columnAutoWidth
          itemsExpr="children"
          dataStructure="tree"
          onSelectionChanged={handleSelectionChanged}
          height={'100%'}
        >
          <Selection mode="single" />
          <Scrolling mode="virtual" />
          <Column dataField="name" caption="Ім'я" />
        </TreeList>
      )}
    />
  );
};

export default React.memo(GroupsListDropDown);
