import React from 'react'
import cx from 'clsx'

import { SelectedPlaceT } from '../../TransactionsDailyReport'
import DropDownDevicesTree from '../dropDownDevicesTree/DropDownDevicesTree'
import CalendarPicker from '../../../../components/CalendarPicker/CalendarPicker'

import styles from './Filter.module.scss'

interface FilterInterface {
  onChangePeriod: (key: string, value: any) => void
  setSelectedPlace: (data: SelectedPlaceT) => void
  from: any
  to: any
  selectedPlace: string | number
  handleChangeDateType?: (data: any) => void
}

const Filter = ({
  onChangePeriod,
  setSelectedPlace,
  selectedPlace,
  from,
  to,
  handleChangeDateType,
}: FilterInterface) => {
  return (
    <div className="d-flex flex-row align-items-end mb-4">
      <CalendarPicker
        to={to}
        from={from}
        onChangePeriod={onChangePeriod}
        withFilter={true}
        handleChangeDateType={handleChangeDateType}
      />

      <div className={cx('column', styles.filterItem, styles.dropDown)}>
        <div className={cx('column', 'pl-0', styles.filterItem)}>
          <div className="font-weight-bold">Пристрої</div>

          <DropDownDevicesTree
            value={selectedPlace}
            setSelectedPlace={setSelectedPlace}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
