import React, { useRef, useState } from 'react';
import { DropDownBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';

import api from '../../api';
import { storeErrorHandler } from '../../helpers/errorHandling';

const UsersList = ({ user, setUser, cellOptions }: any) => {
  const [localUsers, setLocalUsers] = useState<any>([]);
  const dropDownRef = useRef<DropDownBox>(null);
  const dataGridRef = useRef<DataGrid>(null);

  const configStore: any = useRef(
    new CustomStore({
      key: 'id',
      load: async () => {
        const batch = [
          api.user
            .get({})
            .then(result => result.data)
            .catch(error => storeErrorHandler(error)),
        ];
        const [users] = await Promise.all(batch);
        setLocalUsers(users);
        return users;
      },
    }),
  ).current;

  const handleSelectionChanged = (event: { selectedRowsData?: any }) => {
    const selectedNode = event?.selectedRowsData?.[0];
    // selectedNode.id is undefined for new nodes (creating)
    if (!selectedNode || !selectedNode.id) {
      return;
    }
    cellOptions.data.setValue(selectedNode.id);
    setUser(selectedNode);
    if (dropDownRef.current) {
      dropDownRef.current.instance.close();
    }
  };

  const lookupConfigId = {
    dataSource: localUsers,
    valueExpr: 'id',
    displayExpr: 'id',
    allowClearing: true,
  };

  const lookupConfigUsername = {
    dataSource: localUsers,
    valueExpr: 'username',
    displayExpr: 'username',
    allowClearing: true,
  };

  return (
    <DropDownBox
      ref={dropDownRef}
      valueExpr="id"
      value={user.id ? `id: ${user.id}, username: ${user.username}` : undefined}
      displayExpr="id"
      placeholder="Виберіть пристрiй..."
      showClearButton
      width={300}
      onValueChanged={() => {
        // eslint-disable-next-line no-unused-expressions
        dataGridRef.current?.instance.deselectAll();
        setUser({});
      }}
      contentRender={() => (
        <DataGrid
          height="100%"
          ref={dataGridRef}
          id="usersData"
          dataSource={configStore}
          columnAutoWidth
          onSelectionChanged={handleSelectionChanged}
          selectedRowKeys={[user.id]}
        >
          <Selection mode="single" />
          <Column dataField="id" lookup={lookupConfigId} />
          <Column dataField="username" lookup={lookupConfigUsername} />
        </DataGrid>
      )}
    />
  );
};

export default UsersList;
