import React, { useEffect, useMemo, useRef, useState } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import { Column, TreeList } from 'devextreme-react/tree-list'
import { uniqueId } from 'lodash'
import { Form } from 'react-bootstrap'
import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import styles from './TransactionsProcesingReport.module.scss'
import clsx from 'clsx'
import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'

export type SelectedPlaceT = {
  name?: string | number
  id?: number
}

export type tErrorT = {
  id: number
  type: number
  name: string
}

const leftOffset = 24
const secretKey = 1

const checkStateWithTError = (key: number | string) => {
  const keyNumber = Number(key)
  const res = {
    value: null,
    isError: false,
  }

  if ((keyNumber >> leftOffset) - secretKey < 0) {
    res.value = keyNumber
  } else {
    res.value = (keyNumber >> leftOffset) - secretKey
    res.isError = true
  }
  return res
}

const filterEmptyChildren = (node: any) => {
  const _req = (nodeItem: any) => {
    let ch = [...nodeItem]

    return ch.reduce((res: any, item: any) => {
      if (item.devName) {
        res.push(item)
        return res
      }

      if (item.children && item?.children?.length) {
        item.children = _req(item?.children)
      }

      if (item.children && !item.children.length) {
        return res
      }

      res.push(item)

      return res
    }, [])
  }

  return _req(node)
}

const mapReporttoTree = (report: any, tree: any) => {
  const newTree = { ...tree }

  const _reqF = (node: any) => {
    node.forEach((n: any) => {
      if (n.deviceId) {
        const _reportOfDevice = report.filter((r: any) => r.devName === n.name)
        if (_reportOfDevice.length) {
          const refReport = _reportOfDevice.map((i: any) => {
            return {
              ...i,
              id: i.id + uniqueId(),
              name: '',
            }
          })
          n.children = [...refReport]
        }
      } else {
        _reqF(n.children)
      }
    })
  }

  _reqF(newTree.children)
  return newTree.children
}

const serializeReportData = (
  report: any,
  filterCheckedState: any,
  tError: tErrorT[]
) => {
  const _data = report?.message?.reduce((res: any, item: any) => {
    const isTrue = filterCheckedState.some((f: any) => {
      const checkedFilterItem = checkStateWithTError(f)

      let isError = false

      if (checkedFilterItem.isError) {
        isError = tError.some(
          e => item.calcerr & e.id && e.type === checkedFilterItem.value
        )
      }

      return (
        isError ||
        (!checkedFilterItem.isError &&
          checkedFilterItem.value === Number(item.state)) ||
        (!checkedFilterItem.isError &&
          checkedFilterItem.value & Number(item.state))
      )
    })

    if (isTrue) {
      res.push({ ...item, name: item.devName, id: uniqueId() })
    }
    return res
  }, [])

  return _data
}

const stateNameField: any = {
  0: 'немає помилок',
  1: 'немає транзакцій',
  2: 'є транзакції немає добових',
  4: 'є добові, але не співпадають дані',
  16777216: 'Помилка перерахунку',
  33554432: 'Застереження перерахунку',
}

const errDateNameField: any = {
  0: 'дані коректні',
  1: 'CIPURSE та метро',
  2: 'KYIV SMART CARD',
  4: 'банківськи картки',
  8: 'QR-квитки',
  16: 'відсутні або транзакції або добова',
}

const toTimeStamp = (date: any) => new Date(date).getTime()

const TransactionsProcesingReportPage: React.FC = () => {
  const dataGridRef = useRef<TreeList>(null)
  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState({
    from: new Date().setHours(0, 0, 0, 0),
    to: new Date().setHours(23, 59, 59, 999),
  })
  const [tError, setTError] = useState<tErrorT[]>([])

  const [filterCheckedState, setFilterCheckeState] = useState(
    Object.keys(stateNameField)
  )

  const transactionsDevice: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: async () => {
          try {
            const batch = [
              api.transactionsDevice
                .getTransactionsProcessingReport({ ...period })
                .then((result: any) => result.data)
                .catch(storeErrorHandler),
              api.config
                .withoutMetrics()
                .then(result => result.data)
                .catch(storeErrorHandler),
              api.tranalitic
                .getTranaliticError()
                .then(result => result.data)
                .catch(storeErrorHandler),
            ]
            const [
              transactionsDevice,
              devicesTree,
              tranaliticErrors,
            ] = await Promise.all(batch)

            setTError(tranaliticErrors)

            const serializedReportData = serializeReportData(
              transactionsDevice,
              filterCheckedState,
              tranaliticErrors
            )

            const mappedReportToTree = mapReporttoTree(
              serializedReportData,
              devicesTree
            )
            const res = filterEmptyChildren(mappedReportToTree)
            return res
          } catch (error) {
            ///
          }
        },
      }),
    [period, filterCheckedState]
  )

  function onChangePeriod(key: string, value: string) {
    setPeriod({
      ...period,
      [key]: toTimeStamp(value),
    })
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const handleChangeCheckbox = (e: any) => {
    if (e.target.checked) {
      setFilterCheckeState([...filterCheckedState, e.target.id])
    } else {
      const _uncheked = filterCheckedState.filter((f: any) => f !== e.target.id)
      setFilterCheckeState(_uncheked)
    }
  }

  const toggleExpand = (isExpand: boolean, key: number | string) => {
    !isExpand
      ? dataGridRef.current.instance['collapseRow'](key)
      : dataGridRef.current.instance['expandRow'](key)
  }

  const expandChildren = (node: any, isExpand: boolean) => {
    if (node?.hasChildren) {
      node?.children.forEach((ch: any) => {
        if (ch?.hasChildren) {
          toggleExpand(isExpand, ch?.key)
          expandChildren(ch, isExpand)
        }
      })
    }
  }

  const _expandInner = (column: any) => {
    const _key = column?.row?.key
    const _node = column?.row?.node
    const _isExpanded = column?.row?.isExpanded

    toggleExpand(!_isExpanded, _key)
    expandChildren(_node, !_isExpanded)
  }

  const renderCustomCell = (column: any) => {
    return (
      <div className={clsx(styles.customCellWrapper)}>
        <span className={styles.columnText}>{column.text}</span>
        {column.row.node.hasChildren &&
          !(
            column?.row?.data?.deviceId || column?.row?.data?.device?.devId
          ) && (
            <div
              onClick={() => _expandInner(column)}
              className={clsx(styles.customCellBtn)}
            >
              <span>
                <i
                  className={
                    column.row.isExpanded
                      ? 'dx-icon-chevronup'
                      : 'dx-icon-chevrondown'
                  }
                ></i>
              </span>
            </div>
          )}
      </div>
    )
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: updateData,
      },
    })
  }

  useEffect(updateData, [period])

  return (
    <>
      <div className="container">
        <div className="row justify-content-between mb-4">
          <div className="d-flex flex-column w-100 mb-2">
            <PageTitle title="Обробка транзакцій" />
            <ForPeriodTitleSection
              from={period.from}
              to={period.to}
              isUpdateButton={false}
            />
          </div>
          <div className="container">
            <div className="row justify-content-between align-items-end mb-2">
              <Filter
                from={period.from}
                to={period.to}
                onChangePeriod={onChangePeriod}
                setSelectedPlace={setPlace}
                selectedPlace={place.name || ''}
              />
            </div>
            <Form name="type-form" className="mb-2 row">
              <div className="font-weight-bold mr-2">Стан: </div>
              {Object.entries(stateNameField).map(([key, typeData]: any) => {
                return (
                  <Form.Check inline key={key}>
                    <Form.Check.Input
                      type={'checkbox'}
                      name={key}
                      id={key}
                      checked={filterCheckedState.some((i: any) => i === key)}
                      style={{ cursor: 'pointer' }}
                      onChange={handleChangeCheckbox}
                    />
                    <Form.Check.Label htmlFor={key}>
                      {typeData}
                    </Form.Check.Label>
                  </Form.Check>
                )
              })}
            </Form>
          </div>
        </div>
      </div>
      <TreeList
        ref={dataGridRef}
        dataSource={transactionsDevice}
        showBorders
        showRowLines={true}
        className={styles.transactionTableContainer}
        itemsExpr="children"
        dataStructure="tree"
        onToolbarPreparing={onToolbarPreparing}
      >
        <Column
          dataField="name"
          caption="Пристрій"
          width={300}
          cellRender={column => renderCustomCell(column)}
        />
        <Column
          dataField="chDate"
          caption="Дата"
          dataType="date"
          format="dd/MM/yyyy"
          width={90}
        />
        <Column dataField="num" caption="№ Турнікета" width={'auto'} />
        <Column
          dataField="errDateNameField"
          caption="Помилка"
          width={'55%'}
          cssClass={styles.wordWrapCell}
          cellRender={column => ErrorCell({ ...column, tError })}
        />
        <Column
          dataField="stateName"
          caption="Стан"
          width={'55%'}
          cssClass={styles.wordWrapCell}
          cellRender={column => StateCell({ ...column, tError })}
        />
      </TreeList>
    </>
  )
}

const StateCell = (props: any) => {
  const { data, tError }: { data: any; tError: tErrorT[] } = props

  const makeStyle = (key: any) => {
    let _styles: any = {}
    switch (key) {
      case 0:
        _styles.color = 'white'
        _styles.backgroundColor = 'green'
        break
      // case 1:
      //   _styles.borderColor = 'black'
      //   _styles.borderWidth = '1px'
      //   _styles.borderStyle = 'solid'
      //   break
      case 2:
        _styles.color = 'black'
        _styles.backgroundColor = 'yellow'
        break
      case 4:
        _styles.color = 'white'
        _styles.backgroundColor = 'red'
        break
      default:
        _styles.backgroundColor = '#d2d0d0'
        break
    }

    return _styles
  }

  const isTypeError = tError.some(e => data.calcerr & e.id && e.type === 0)

  return (
    <>
      {Object.keys(stateNameField)?.map((s, index) => {
        if (+s === +data.state) {
          return (
            <React.Fragment key={index}>
              <span
                //key={index}
                className={`badge mb-1 ${styles.stateCell}`}
                style={makeStyle(+data.state)}
              >
                {stateNameField[+data.state]}
              </span>
              <br />
            </React.Fragment>
          )
        }
        if (+data.state && +data.state & +s) {
          return (
            <React.Fragment key={index}>
              <span
                //key={index}
                className={`badge mb-1 ${styles.stateCell}`}
                style={makeStyle(+data.state & +s)}
              >
                {stateNameField[+data.state & +s]}
              </span>
              <br />
            </React.Fragment>
          )
        }
        return null
      })}
      {isTypeError
        ? tError.map((te, index) => {
            if (data.calcerr & te.id && te.type === 0) {
              return (
                <React.Fragment key={index}>
                  <span
                    //key={index}
                    className={`badge mb-1 ${styles.stateCell}`}
                    style={{
                      backgroundColor: 'red',
                      color: 'yellow',
                    }}
                  >{`${'Помилка перерахунку'}`}</span>
                  <br />
                </React.Fragment>
              )
            }
            return null
          })
        : tError.map((te, index) => {
            if (data.calcerr & te.id) {
              return (
                <React.Fragment key={index}>
                  <span
                    //key={index}
                    className={`badge mb-1 ${styles.stateCell}`}
                    style={{
                      backgroundColor: te.type ? 'yellow' : 'red',
                      color: te.type ? 'black' : 'yellow',
                    }}
                  >{`${
                    te.type ? 'Застереження перерахунку' : 'Помилка перерахунку'
                  }`}</span>
                  <br />
                </React.Fragment>
              )
            }
            return null
          })}
    </>
  )
}

const ErrorCell = (props: any) => {
  const { data, tError }: { data: any; tError: tErrorT[] } = props

  return (
    <>
      {Object.keys(errDateNameField)?.map((err, index) => {
        if (data.errdata && (+data.errdata === +err || +data.errdata & +err)) {
          return (
            <React.Fragment key={index}>
              <span
                //key={index}
                className="badge mb-1"
                style={{ backgroundColor: '#d2d0d0' }}
              >{`${errDateNameField[+err]}`}</span>
              <br />
            </React.Fragment>
          )
        }
        return null
      })}

      {tError.map((te, index) => {
        if (data.calcerr & te.id) {
          return (
            <React.Fragment key={index}>
              <span
                //key={index}
                className={`badge mb-1 ${styles.stateCell}`}
                style={{
                  backgroundColor: te.type ? 'yellow' : 'red',
                  color: te.type ? 'black' : 'yellow',
                }}
              >{`${te.name}`}</span>
              <br />
            </React.Fragment>
          )
        }
        return null
      })}
    </>
  )
}

export default TransactionsProcesingReportPage
