import React from 'react';
import DataGrid, {
  Column,
  Editing,
  Lookup,
  Scrolling,
  Summary,
  TotalItem,
  EmailRule,
  RequiredRule,
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import { CustomRule } from 'devextreme-react/validator';
import { alert } from 'devextreme/ui/dialog';
import { StateT as AuthState } from '../../store/modules/auth/auth';
import { AppState } from '../../store/modules';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/PageTitle/PageTitle';
import { localizedValidationErrors } from '../../constants/errorsLocalization';
import { storeErrorHandler } from '../../helpers/errorHandling';
import api from '../../api';

const usersData: any = new CustomStore({
  key: 'id',
  load: () =>
    api.user
      .get({})
      .then(result => result.data)
      .catch(storeErrorHandler),
  remove: id => api.user.delete(id).catch(storeErrorHandler),
  update: (id, data) =>
    api.user
      .update({ id, ...data, ...(data.role && { role: data.role.id }) })
      .catch(storeErrorHandler),
  insert: data => api.user.create({ ...data, role: data.role.id }).catch(storeErrorHandler),
});

const validatePassword = ({ value }: any): boolean => (value ? value.length >= 6 : true);


const UsersPage: React.FC = () => {
  const { user } = useSelector<AppState, AuthState>(state => state.auth);
  const onRowUpdating = (cellOption: any) => {

    if (
      user?.id === cellOption?.oldData?.id &&
      cellOption?.oldData?.role?.id === 1 &&
      cellOption?.newData?.role?.id === 2
    ) {
      cellOption.cancel = true
      alert(
        '<i>Ви не можете змінити свою роль адміністратора на користувача.</i>',
        'Попередження!'
      );
    }
  };

  const validateRole = ({ value, data }: any): boolean => {
    if (
      user?.id === data?.id &&
      value === 2
    ) {
      return false
    }
    return true
  };

  const rolesData: any = new CustomStore({
    key: 'id',
    loadMode: 'raw',
    load: () =>
      api.role
        .get({})
        .then(result => result.data)
        .catch(storeErrorHandler),
  });

  return (
    <>
      <PageTitle title="Користувачі" />

      <DataGrid
        id="grid"
        showBorders
        dataSource={usersData}
        repaintChangesOnly
        onRowUpdating={onRowUpdating}
        className='mb-3'
      >
        <Editing refreshMode="full" mode="form" allowAdding allowDeleting allowUpdating useIcons />

        <Scrolling mode="virtual" />

        <Column dataField="username" caption="ПІБ">
          <RequiredRule />
        </Column>
        <Column dataField="email">
          <RequiredRule />
          <EmailRule />
        </Column>
        <Column dataField="password" caption="Пороль" visible={false}>
          <CustomRule
            validationCallback={validatePassword}
            message={localizedValidationErrors.password}
          />
        </Column>
        <Column dataField="role.id" caption="Роль" >
          <Lookup dataSource={rolesData} valueExpr="id" displayExpr="name" />
          <RequiredRule />
          <CustomRule
            validationCallback={validateRole}
            message="Ви не можете змінити свою роль адміністратора на користувача."
          />
        </Column>
        <Summary>
          <TotalItem column="CustomerID" summaryType="count" />
        </Summary>
      </DataGrid>
    </>
  );
};

export default UsersPage;
