import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { locale, loadMessages } from 'devextreme/localization';
import ukMessages from './localization/uk.json';

import store from './store';

import App from './containers/App/App';

import './index.css';

loadMessages(ukMessages);
locale('uk');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
