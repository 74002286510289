import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import appConfig from '../config'; /// config.baseUrl

import user, { UserCRUD } from './modules/user';
import role, { RoleCRUD } from './modules/roles';
import config, { ConfigCRUD } from './modules/config';
import configParam, { ConfigParamCRUD } from './modules/configParam';
import configData, { ConfigDataCRUD } from './modules/configData';
import deviceCRUD, { DeviceCRUD } from './modules/device';
import firmware, { FirmwareCRUD } from './modules/firmware';
import firmwareGroup, { FirmwareGroupCRUD } from './modules/firmwareGroup';
import hardware, { HardwareCRUD } from './modules/hardware';
import fwFile, { FwFileCRUD } from './modules/fw-file';
import metricAttribute, { MetricAttributeCRUD } from './modules/metricAttribute';
import metric, { MetricCRUD } from './modules/metric';
import alarm, { AlarmCRUD } from './modules/alarm';
import transactionsDevice, {TransactionsDevicesCRUD} from './modules/transactionsDeveces'
import alarmType, { AlarmTypeCRUD } from './modules/alarmType';
import key, { KeyCRUD } from './modules/keys';
import usersKeys, { UsersKeysCRUD } from './modules/usersKeys';
import stateSystemCRUD , {StateSystemCRUD} from './modules/stateSystem'
import reportsCRUD, {ReportsCRUD} from './modules/reports'
import productsCRUD, {ProductsCRUD} from './modules/products'
import tranaliticCRUD, {TranaliticCRUD} from './modules/tranalitic'
import auditCRUD, {AuditCRUD} from './modules/audit'
import keysApiCRUD, {KeyApiCRUD} from './modules/keysApi'

class Client {
  token?: string | null;

  req: AxiosInstance;
  // modules

  transactionsDevice: TransactionsDevicesCRUD;

  user: UserCRUD;

  role: RoleCRUD;

  config: ConfigCRUD;

  reports: ReportsCRUD;

  configParam: ConfigParamCRUD;

  configData: ConfigDataCRUD;

  device: DeviceCRUD;

  firmware: FirmwareCRUD;

  firmwareGroup: FirmwareGroupCRUD;

  hardware: HardwareCRUD;

  fwFile: FwFileCRUD;

  metricAttribute: MetricAttributeCRUD;

  metric: MetricCRUD;

  alarm: AlarmCRUD;

  alarmType: AlarmTypeCRUD;

  key: KeyCRUD;

  usersKeys: UsersKeysCRUD;

  stateSystem: StateSystemCRUD;

  products: ProductsCRUD;

  tranalitic: TranaliticCRUD;

  audit: AuditCRUD

  keyApi: KeyApiCRUD

  constructor(baseURL?: string) {
    this.req = axios.create({
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Client-Time-Zone-Offset': new Date().getTimezoneOffset()
      },
      baseURL,
    });

    this.req.interceptors.request.use((confg: AxiosRequestConfig) => {
      if (!this.token) {
        return confg;
      }

      // eslint-disable-next-line no-param-reassign
      confg.headers = { ...confg.headers, 'x-access-token': this.token };

      return confg;
    });

    this.user = user(this.req);
    this.role = role(this.req);
    this.config = config(this.req);
    this.configParam = configParam(this.req);
    this.configData = configData(this.req);
    this.device = deviceCRUD(this.req);
    this.firmware = firmware(this.req);
    this.firmwareGroup = firmwareGroup(this.req);
    this.hardware = hardware(this.req);
    this.fwFile = fwFile(this.req);
    this.metricAttribute = metricAttribute(this.req);
    this.metric = metric(this.req);
    this.alarm = alarm(this.req);
    this.alarmType = alarmType(this.req);
    this.key = key(this.req);
    this.usersKeys = usersKeys(this.req);
    this.transactionsDevice = transactionsDevice(this.req);
    this.stateSystem = stateSystemCRUD(this.req)
    this.reports = reportsCRUD(this.req)
    this.products = productsCRUD(this.req)
    this.tranalitic = tranaliticCRUD(this.req)
    this.audit = auditCRUD(this.req)
    this.keyApi = keysApiCRUD(this.req)
  }

  setToken(token: string | null): void {
    this.token = token;
  }
}

export default new Client(appConfig.baseUrl);
