import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';

export enum TYPES {
  'string' = 'string',
  'number' = 'number',
  'boolean' = 'boolean',
}

export type ConfigParamT = {
  id: number;
  name: string;
  type: TYPES;
  purpose: string;
};

export class ConfigParamCRUD extends CRUD<ConfigParamT> {
  TYPES = TYPES;
}

export default function configParamCRUD(request: AxiosInstance): ConfigParamCRUD {
  const crud = new ConfigParamCRUD({
    url: '/parameter/attribute',
    request,
    id: 'id',
  });

  return crud;
}
