import React from 'react'
import DataGrid, {
  Column,
  Editing,
  Scrolling,
  RequiredRule,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'

import api from '../../api'
import PageTitle from '../../components/PageTitle/PageTitle'
import { storeErrorHandler } from '../../helpers/errorHandling'

const productData: any = new CustomStore({
  key: 'id',
  load: () =>
    api.products
      .get({})
      .then(result => result.data)
      .catch(storeErrorHandler),
  remove: id => api.products.delete(id).catch(storeErrorHandler),
  update: (id, data) => {
    return api.products.updateProductsCode(id, data).catch(storeErrorHandler)
  },
  insert: data => api.products.createProduct(data).catch(storeErrorHandler),
})

const ProductCodePage = () => {
  return (
    <div className="mb-3">
      <PageTitle title="Продукти" />
      <DataGrid id="id" showBorders dataSource={productData} repaintChangesOnly>
        <Editing
          refreshMode="full"
          mode="form"
          allowAdding
          allowDeleting
          allowUpdating
          useIcons
        />

        <Scrolling mode="virtual" />

        <Column dataField="name" caption="Продукт">
          <RequiredRule />
        </Column>
        <Column dataField="code" caption="Код продуку">
          <RequiredRule />
        </Column>
      </DataGrid>
    </div>
  )
}

export default ProductCodePage
