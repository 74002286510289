import React from 'react'
import { DateBox } from 'devextreme-react'
import cx from 'clsx'
import styles from './Filter.module.scss'
import DropDownDevicesTree from '../dropDownDevicesTree/DropDownDevicesTree'
import { SelectedPlaceT } from '../../MonthlyTransportationReportByNode'

const DATE_BOX_WIDTH = 280

interface FilterInterface {
  onChangePeriod: (key: string, value: any) => void
  setSelectedPlace: (data: SelectedPlaceT) => void
  from: any
  to: any
  selectedPlace: string | number
}

const Filter = ({
  onChangePeriod,
  from,
  to,
  setSelectedPlace,
  selectedPlace,
}: FilterInterface) => {
  function updateDataFrom(event: any) {
    if (event.value) {
      onChangePeriod('from', event.value)
    } else {
      onChangePeriod('from', new Date().setHours(0, 0, 0, 0))
    }
  }

  function updateDataTo(event: any) {
    if (event.value) {
      onChangePeriod('to', event.value)
    } else {
      onChangePeriod('to', new Date().setHours(23, 59, 59, 999))
    }
  }

  return (
    <div className="d-flex ml-0">
      <div className={'column'}>
        <div className="font-weight-bold">Початок</div>
        <DateBox
          width={DATE_BOX_WIDTH}
          type="date"
          onValueChanged={updateDataFrom}
          showClearButton={false}
          max={typeof to === 'number' && to !== 0 ? to : undefined}
          defaultValue={from}
          //placeholder={_dateFrom.toLocaleDateString()}
        />
      </div>
      <div className={cx('column', styles.filterItem)}>
        <div className="font-weight-bold">Кінець</div>
        <DateBox
          width={DATE_BOX_WIDTH}
          type="date"
          onValueChanged={updateDataTo}
          showClearButton={false}
          min={from}
          //max={_dateFrom.setMonth(_dateFrom.getMonth() + 2)}
          defaultValue={to}
          //placeholder={_dateTo.toLocaleDateString()}
        />
      </div>
      <div className={cx('column', styles.filterItem, styles.place)}>
        <div className="font-weight-bold">Пристрої</div>
        <DropDownDevicesTree
          value={selectedPlace}
          setSelectedPlace={setSelectedPlace}
        />
      </div>
    </div>
  )
}

export default Filter
