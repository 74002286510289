import { SagaIterator } from 'redux-saga';
import { takeEvery, put } from 'redux-saga/effects';
import { API_REQUEST } from '../apiAction';

type ApiActionT = {
  type: typeof API_REQUEST;
  types: [string, string, string];
  call: () => Promise<{}>;
};

function* api({ types, call, ...rest }: ApiActionT): Iterator<{}> {
  const [REQUEST, SUCCESS, FAILURE] = types;
  try {
    yield put({ ...rest, type: REQUEST });
    const result = yield call();
    yield put({ ...rest, type: SUCCESS, result });
  } catch (error) {
    yield put({ ...rest, type: FAILURE, error });
  }
}

export default function* apiSaga(): SagaIterator {
  yield takeEvery(API_REQUEST, api);
}
