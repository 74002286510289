import React, { ReactNode } from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface ConfigurationLayoutI {
  children?: ReactNode
}

const ConfigurationLayout: React.FC = ({ children }: ConfigurationLayoutI) => {
  return (
    <div className="container">
      <div className="row">
        <Nav
          activeKey="/turnstiles-configuration-report"
          className="justify-content-center"
        >
          <Nav.Item>
            <Nav.Link as={Link} to="/hall-configuration-report">
              Конфігурації вестибюлів
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/turnstiles-configuration-report">
              Конфигурацiя пристроїв
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>

      {children}
    </div>
  )
}

export default ConfigurationLayout
