
import {SET_IS_FETCHING_REPORT} from '../../sagas/alarm'

export const alarmStore = (state: any = {isFetchingReport: false}, actions: {
  type: string,
  payload: any
}) => {
  switch (actions.type) {
    case SET_IS_FETCHING_REPORT:
      
      return {...state, isFetchingReport: actions.payload};
  
    default:
      return state;
  }
}
