import React from 'react';
import TagBox from 'devextreme-react/tag-box';

type Props = {
  data: any;
};

const PermissionsTagBoxComponent = (props: Props): JSX.Element => {
  const onValueChanged = (e: any) => props.data.setValue(e.value);
  const onSelectionChanged = () => props.data.component.updateDimensions();

  return (
    <TagBox
      dataSource={props.data.column.lookup.dataSource}
      defaultValue={props.data.value}
      displayExpr="action"
      showSelectionControls
      showMultiTagOnly={false}
      onValueChanged={onValueChanged}
      onSelectionChanged={onSelectionChanged}
      placeholder="Обери"
    />
  );
};

export default PermissionsTagBoxComponent;
