import React, { useEffect, useRef } from 'react'
import { TreeList } from 'devextreme-react/tree-list'
import { Column } from 'devextreme-react/data-grid'

interface TableInterface {
  data: any
  isCollapsed: boolean
}

const isActualDate = (value: any) => {
  const _nowDate = new Date().getTime()
  const _valueDate = value ? new Date(value).getTime() : null
  return _valueDate && _nowDate - _valueDate > 1000 * 60 * 60 * 24
}

function extendUpdatingData(options: any): void {
  options.newData = { ...options.oldData, ...options.newData }
}

const Table = ({ data, isCollapsed }: TableInterface) => {
  const treeListRef = useRef<TreeList>(null)

  function toggleCollapse(isCollapsed: boolean) {
    treeListRef.current?.instance.forEachNode((node: any) => {
      treeListRef.current?.instance[!isCollapsed ? 'collapseRow' : 'expandRow'](
        node.key
      )
    })
  }

  useEffect(() => {
    if (data) {
      setTimeout(() => toggleCollapse(isCollapsed), 400)
    }

    return () => {
      data.off && data.off('loaded')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    toggleCollapse(isCollapsed)
  }, [isCollapsed])

  return (
    <TreeList
      ref={treeListRef}
      id="firmwareTree"
      dataStructure="tree"
      dataSource={data}
      itemsExpr="children"
      keyExpr="key"
      showRowLines
      showBorders
      columnAutoWidth
      onRowUpdating={extendUpdatingData}
    >
      <Column
        //cellRender={RenderId}
        dataField="name"
        caption="Назва пристрою"
        allowEditing={false}
        showEditorAlways={false}
        width={300}
      />
      <Column
        width={300}
        dataField="lastFirmwareVersion"
        caption="Остання версія ПЗ"
        cellRender={renderLAstVersionCell}
      />
      <Column
        dataField="metricValue"
        caption="Версія ПЗ на пристрої"
        cellRender={renderVersionOnDeviceCell}
      />
      <Column
        dataField="metricUpdatedAt"
        caption="Дата останньої зміни"
        cellRender={renderLastUpdateDateCell}
      />
    </TreeList>
  )
}

const renderLastUpdateDateCell = props => {
  const { value } = props

  const isBadge = isActualDate(value)

  return (
    <span
      className={'badge'}
      style={{
        backgroundColor: isBadge ? 'red' : 'white',
        color: isBadge ? 'white' : 'black',
      }}
    >
      {value ? new Date(value).toLocaleString() : ''}
    </span>
  )
}

const renderVersionOnDeviceCell = props => {
  const { data } = props
  const { metricUpdatedAt, lastFirmwareVersion, metricValue } = data || {}

  const isBadge = lastFirmwareVersion !== metricValue

  return (
    <span
      className={'badge'}
      style={{
        backgroundColor:
          !isActualDate(metricUpdatedAt) && lastFirmwareVersion
            ? isBadge
              ? 'red'
              : 'green'
            : 'white',
        color:
          !isActualDate(metricUpdatedAt) && lastFirmwareVersion
            ? 'white'
            : 'black',
      }}
    >
      {metricValue}
    </span>
  )
}

const renderLAstVersionCell = props => {
  const { data } = props
  const { lastFirmwareVersion } = data || {}

  return (
    <span
      className={'badge'}
      style={{
        backgroundColor: 'white',
        color: 'black',
      }}
    >
      {lastFirmwareVersion}
    </span>
  )
}

export default Table
