import React from 'react'
import { DateBox } from 'devextreme-react'

const DATE_BOX_WIDTH = 280

interface FilterInterface {
  onChangePeriod: (values: { from: number; to: number }) => void
  from: any
  to: any
}

const Filter = ({ onChangePeriod, from, to }: FilterInterface) => {
  function updateDataFrom(event: any) {
    const _fromDate = new Date(event.value).setHours(0, 0, 0, 0)
    const _toDate = new Date(
      new Date(_fromDate).getFullYear(),
      new Date(_fromDate).getMonth() + 1,
      0
    ).setHours(23, 59, 59, 999)

    onChangePeriod({ to: _toDate, from: _fromDate })
  }

  return (
    <div className="row ml-0">
      <div className={'column'}>
        <div className="font-weight-bold">Місяць</div>
        <DateBox
          width={DATE_BOX_WIDTH}
          type="date"
          onValueChanged={updateDataFrom}
          showClearButton={false}
          max={new Date()}
          value={from}
          displayFormat="MMM yyyy"
          calendarOptions={{
            maxZoomLevel: 'year',
            minZoomLevel: 'decade',
          }}
        ></DateBox>
      </div>
    </div>
  )
}

export default Filter
