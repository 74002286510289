import { SagaIterator } from 'redux-saga';

///import { useGoogleLogout } from 'react-google-login'

import { takeEvery, put, spawn, select, take } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'connected-react-router';
import {
  LoginSuccessActionT,
  LoginSuccessResultT,
  UserMeLoginSuccessActionT,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  USER_ME_LOGIN_SUCCESS,
  WEBAUTHN_SUCCESS,
  LOGOUT_FAILURE,
  logoutFailure,
  userMe,
  userMeLogin,
} from '../modules/auth/auth';
import api from '../../api';
import { setGrants } from '../../helpers/permissions';

function initSession(result: LoginSuccessResultT) {
  const token = result.headers['x-access-token'] || localStorage.getItem('accessToken') || '';
  setGrants(result.data.grants);
  api.setToken(token);
  
  localStorage.setItem('accessToken', token);
}

function* webauthnLogin({ result }: UserMeLoginSuccessActionT): SagaIterator {
  initSession(result);
  yield put(userMeLogin(result));
  yield put(push('/'));
}

function* loginSuccess(action: LoginSuccessActionT): SagaIterator {
  if (!action.initialized) {
    initSession(action.result);
    yield put(push('/'));
  }
}

function* logoutSaga(): SagaIterator {
  try {
    api.setToken('');
    localStorage.removeItem('accessToken');
    yield put(push('/login'));
  } catch (error) {
    yield put(logoutFailure());
  }
}

function* userMeSuccessSaga({ result }: UserMeLoginSuccessActionT): SagaIterator {
  initSession(result);
  yield put(userMeLogin(result));
}

function* checkToken(): SagaIterator {
  yield take(
    (action: Record<string, any>) =>
      action.type === LOCATION_CHANGE && action.payload.action === 'POP',
  );

  const pathname = yield select(store => store.router?.location?.pathname);

  if (pathname === '/login') {
    return;
  }

  if (!api.token) {
    
    const token = localStorage.getItem('accessToken') || null;
    
    if (token) {
      yield put(userMe(token));
    } else {
      yield put(push('/login'));
    }
  }
}

export default function* authSaga(): SagaIterator {
  yield spawn(checkToken);
  yield takeEvery(WEBAUTHN_SUCCESS, webauthnLogin);
  yield takeEvery(LOGIN_SUCCESS, loginSuccess);
  yield takeEvery([LOGOUT_SUCCESS, LOGOUT_FAILURE], logoutSaga);
  yield takeEvery(USER_ME_LOGIN_SUCCESS, userMeSuccessSaga);
}
