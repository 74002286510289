import { takeEvery, call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver'

import api from '../../api';

const {alarm} = api

export const GET_ALARM_REPORT = 'GET_ALARM_REPORT'
export const SET_IS_FETCHING_REPORT = 'SET_IS_FETCHING_REPORT'

export const getAlarmReportAction= (payload: any) => ({
  type: GET_ALARM_REPORT,
  payload
})

export const setIsFetchingReport = (payload: any) => ({
  type: SET_IS_FETCHING_REPORT,
  payload
})

function* getAlarmReportCsv(action: any) {
    const {payload={}} = action
    try {
      yield put(setIsFetchingReport(true))
      const res = yield call([alarm, 'getReport'], payload)
      const { data, status } = res
      const to = new Date(payload.to).toLocaleDateString();
      const from = new Date(payload.from).toLocaleDateString();

      if (status === 200) {
        saveAs(
          data,
          `notifications-report-${from}-${to}.${payload.type}`
        )
      }
    } catch (error) {
      // to do
    } finally {
      yield put(setIsFetchingReport(false))
    }
}

export default function* alarmWorker() {
    yield takeEvery(GET_ALARM_REPORT, getAlarmReportCsv)
}