import React, { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Paging,
  ColumnChooser,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import { storeErrorHandler } from '../../helpers/errorHandling'
import Layout from './Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import api from '../../api'
import { getConfigurationHallReportAction } from '../../store/sagas/transaction-report'
import styles from './styles.module.scss'

const HallConfigurationReportPage = () => {
  const dataGridRef = useRef<DataGrid>(null)
  const [reportData, setReportData] = useState({
    columns: [],
    data: [],
  })

  const dispatch = useDispatch()

  const isFetchingCsv = useSelector(
    (store: any) => store.transactionState.isFetchingCsv
  )

  const report: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: () =>
          api.reports
            .getConfigurationHallReporpsApi()
            .then(result => {
              // @ts-ignore
              setReportData({
                data: result.data.rows,
                columns: result.data.presentAttributes,
              })
              return result.data.rows
            })
            .catch(storeErrorHandler),
      }),
    [setReportData]
  )

  return (
    <Layout>
      <div className="">
        <div className="justify-content-between mb-4">
          <div className="ml-0 mb-3">
            <PageTitle title="Конфігурації вестибюлів" />
          </div>
          <div className="">
            {!!reportData?.data?.length && (
              <div className="row justify-content-start align-items-center pr-3 mb-3">
                <Button
                  onClick={() => {
                    dispatch(
                      getConfigurationHallReportAction({
                        reportType: 'pdf',
                      })
                    )
                  }}
                  variant="outline-primary"
                  className={styles.downloadButton}
                  disabled={isFetchingCsv}
                >
                  PDF
                </Button>
                <Button
                  onClick={() => {
                    dispatch(
                      getConfigurationHallReportAction({
                        reportType: 'xls',
                      })
                    )
                  }}
                  disabled={isFetchingCsv}
                  variant="outline-primary"
                  className={`${styles.downloadButton} mr-3`}
                >
                  XLS
                </Button>
                {isFetchingCsv && (
                  <Spinner animation="border" variant="primary" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <DataGrid
        ref={dataGridRef}
        dataSource={report}
        showBorders
        allowColumnResizing={true}
        ///columnResizingMode={this.state.mode}
        columnMinWidth={50}
        columnAutoWidth={true}
      >
        <ColumnChooser enabled={true} />
        <Paging pageSize="100" />
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <Column
          dataField="line"
          caption="Лінія"
          width={'auto'}
          groupIndex={0}
        />
        <Column
          dataField="station"
          caption="Станція"
          width={'auto'}
          groupIndex={1}
        />
        <Column dataField="name" caption="Вестибюль" width={'auto'} />
        {reportData.columns?.map(column => {
          return <Column key={column} dataField={column} width={'auto'} />
        })}
      </DataGrid>
    </Layout>
  )
}

export default HallConfigurationReportPage
