import React, { ReactElement } from 'react';
import cx from 'clsx';
import styles from './ErrorMessage.module.css';

type PropsT = {
  error: string;
  className?: string;
};

const ErrorMessage = ({ error, className = '' }: PropsT): ReactElement => {
  return <div className={cx(styles.error, className)}>{error}</div>;
};

export default ErrorMessage;
