import React, { ReactNode } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface ConfigurationLayoutI {
  children?: ReactNode;
}

const ConfigurationLayout: React.FC = ({ children }: ConfigurationLayoutI) => {
  return (
    <div className="container">
      <div className="row">
        <Nav activeKey="/configuration" className="justify-content-center">
          <Nav.Item>
            <Nav.Link as={Link} to="/configuration">
              Конфігурації
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/configuration/parameters">
              Параметри
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>

      {children}
    </div>
  );
};

export default ConfigurationLayout;
