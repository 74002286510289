import React from 'react'
import { DateBox } from 'devextreme-react'
import cx from 'clsx'
import TextBox from 'devextreme-react/text-box'

import styles from './Filter.module.scss'

const DATE_BOX_WIDTH = 200

interface FilterInterface {
  onChangePeriod: (key: string, value: string) => void
  from: any
  to: any
  patternIdent: string
  setPatternIdent: any
}

const Filter = ({
  onChangePeriod,
  from,
  to,
  patternIdent,
  setPatternIdent,
}: FilterInterface) => {
  const _now_date = new Date()

  const onChangePattern = ({ value }: any) => {
    setPatternIdent(value.trim())
  }

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex">
        <div className="column">
          <div className="font-weight-bold">Початок</div>
          <DateBox
            width={DATE_BOX_WIDTH}
            type="datetime"
            onValueChanged={event => {
              onChangePeriod('from', event.value)
            }}
            showClearButton
            max={_now_date}
            value={from}
          />
        </div>
        <div className={cx('column', styles.filterItem)}>
          <div className="font-weight-bold">Кінець</div>
          <DateBox
            width={DATE_BOX_WIDTH}
            type="datetime"
            onValueChanged={event => onChangePeriod('to', event.value)}
            showClearButton
            min={from}
            value={to}
            max={_now_date}
          />
        </div>
      </div>
      <div className={cx('column', styles.filterItem)}>
        <div className="font-weight-bold">Номер Ідентифікатора</div>
        <TextBox
          showClearButton={true}
          width={250}
          onValueChanged={onChangePattern}
          value={patternIdent}
        />
      </div>
    </div>
  )
}

export default Filter
