import { AxiosInstance } from 'axios'
import CRUD from '../base/crud'

export type StateSystemT = {
  query: any
}

export class ReportsCRUD extends CRUD<StateSystemT> {
  getMonthlyTransportationReport(params: any) {
    let url = `${this.config.url}/transportation-month`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params.type ? 'blob': 'json',
    })
  }

  getSystemStatus() {
    let url = `${this.config.url}/systems-status`

    return this.r({
      method: 'GET',
      url,
    })
  }


  getMonthlyTransportationReportByNode(params: any) {
    let url = `${this.config.url}/transportation-month-by-node`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params.type ? 'blob': 'json',
    })
  }

  getMonthlyTransportationReportLobby(params: any) {
    let url = `${this.config.url}/transportation-month-by-node-period`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params.reportType ? 'blob': 'json',
    })
  }

  getTransportationListByPatternReport(params: any) {
    let url = `${this.config.url}/transactions-list-by-pattern`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params.reportType ? 'blob': 'json',
    })
  }

  getEntranceLoadReportApi(params: any) {
    let url = `${this.config.url}/entrance-load-report`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params.reportType ? 'blob': 'json',
    })
  }

  getMetricsStateOfDeviceReportApi(params: any) {
    let url = `${this.config.url}/device-metrics`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params.reportType ? 'blob': 'json',
    })
  }

  getConfigurationHallReporpsApi(params?: any) {
    let url = `${this.config.url}/halls-report`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params?.reportType ? 'blob': 'json',
    })
  }

  getConfigurationTurnstileReporpsApi(params?: any) {
    let url = `${this.config.url}/turnstile-report`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params?.reportType ? 'blob': 'json',
    })
  }

  getUnprocessedTransactionsReportCsv(params: any) {
    let url = `${this.config.url}/unprocessed-transactions`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: 'blob',
    })
  }

  getSoftwareVersionReport(params?: any) {
    let url = `${this.config.url}/software-versions-report`

    return this.r({
      method: 'GET',
      url,
      params,
    })
  }

  getEventTurnstilesReport(params: any) {
    let url = `${this.config.url}/turnstile-events`

    return this.r({
      method: 'GET',
      url,
      params,
    })
  }

}

export default function reportsCRUD(
  request: AxiosInstance
): ReportsCRUD {
  return new ReportsCRUD({
    url: `/reports`,
    request,
    id: 'id',
  })
}
