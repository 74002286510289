import React, { useEffect, useState, useMemo } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spinner, Form } from 'react-bootstrap'
import { uniqBy } from 'lodash'

import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import styles from './EntranceLoadReportPage.module.scss'
import { getEntranceLoadReportCsvPdfAction } from '../../store/sagas/transaction-report'
import api from '../../api'
import Histogram from './Histogram'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import { storeErrorHandler } from '../../helpers/errorHandling'

export type SelectedPlaceT = {
  name?: string | number
  deviceId?: number
  id?: number
}

const toTimeStamp = (date: any) => new Date(date).getTime()

const EntranceLoadReportPage = () => {
  const date = new Date()
  const dispatch = useDispatch()
  const isFetchingCsv = useSelector(
    (store: any) => store.transactionState.isFetchingCsv
  )
  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState({
    from: date.setHours(0, 0, 0, 0),
    to: date.setHours(23, 59, 59, 999),
  })
  const [data, setData] = useState(null)
  const [datasForDayTo, setDatasForDayTo] = useState([])
  const [isFetchingData, setIsFetchingData] = useState(false)
  const [showDayFilter, setShowDayFilter] = useState(false)
  const [checkedDayFilter, setCheckedDayFilter] = useState('sum')
  const [checkedStyleFilter, setCheckedStyleFilter] = useState('overlay')
  const [entranceTypes, setEntranceTypes] = useState({})

  const [filterCheckedState, setFilterCheckeState] = useState(['overall'])

  const reportData: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: async () => {
          setIsFetchingData(true)

          const batch = [
            api.reports
              .getEntranceLoadReportApi({ nodeId: place.id, ...period })
              .then(({ data }: any) => {
                setData(data || [])
                setIsFetchingData(false)
                return data
              })
              .catch(storeErrorHandler),
            api.transactionsDevice
              .getTransactionsTypes()
              .then(({ data }) => data)
              .catch(storeErrorHandler),
          ]

          const [reportsData, typesData] = await Promise.all(batch)

          const fieldsInReportData = reportsData.reduce(
            (res: any, item: any) => {
              for (const [key] of Object.entries(item)) {
                if (!res[key]) {
                  res[key] = ''
                }
              }
              return res
            },
            {}
          )

          const fieldsInReportDataInArr = Object.keys(fieldsInReportData)

          const filteredTypes = typesData.reduce((res: any, item: any) => {
            const check = fieldsInReportDataInArr.some(
              (r: any) => r === item.key
            )
            if (item.group === 1 && check) {
              res[item.key] = {
                label: item.label,
                key: item.key,
              }
            }
            return res
          }, {})

          setEntranceTypes(filteredTypes)

          return reportsData
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [period, place]
  )

  const updateData = () => {
    if (period.from && period.to && place.id) {
      reportData.load()
    }
  }

  function onChangePeriod(key: string, value: string) {
    setPeriod({
      ...period,
      [key]: toTimeStamp(value),
    })
  }

  const handleChangeCheckbox = (e: any) => {
    if (e.target.checked) {
      setFilterCheckeState([...filterCheckedState, e.target.id])
    } else {
      const _uncheked = filterCheckedState.filter((f: any) => f !== e.target.id)
      setFilterCheckeState(_uncheked)
    }
  }

  const handleChangeFilterDay = (e: any) => {
    setCheckedDayFilter(e.target.id)
  }

  const handleChangeFilterStyle = (e: any) => {
    setCheckedStyleFilter(e.target.id)
  }

  useEffect(() => {
    if (period && place.id) {
      const isValid =
        period.to - period.from <= 1000 * 60 * 60 * 24 * 10 &&
        period.to - period.from > 0

      isValid && reportData.load()
    }

    if (!place.id) {
      setData(null)
      setCheckedDayFilter('sum')
      setCheckedStyleFilter('overlay')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, place])

  useEffect(() => {
    const _fromDay = new Date(period.from).getDate()
    const _toDay = new Date(period.to).getDate()
    setShowDayFilter(_toDay !== _fromDay)
  }, [period])

  useEffect(() => {
    if (checkedDayFilter === 'to-day') {
      const rangeData = uniqBy(data, 'dt')
      const res = []
      rangeData.forEach((item: any) => {
        res.push(data?.filter((d: any) => item.dt === d.dt))
      })

      setDatasForDayTo(res)
    }
  }, [checkedDayFilter, data])

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column mb-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <PageTitle title="Звіт по завантаженню проходів" />
          <ForPeriodTitleSection
            isDate={false}
            onUpdate={updateData}
            isFetching={isFetchingData}
            isUpdateButton
          />
        </div>

        {isFetchingData && (
          <div className={styles.spinnerContainer}>
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          setSelectedPlace={setPlace}
          selectedPlace={place.name || ''}
        />
        {period.from && period.to && place.id && !!data?.length && (
          <div className="d-flex flex-row justify-content-between align-items-center mb-3">
            <div className={styles.filterGroup}>
              <Form name="type-form" className="mb-2">
                <div className="font-weight-bold">Проходи</div>
                {Object.entries(entranceTypes).map(([key, typeData]: any) => {
                  return (
                    <Form.Check inline key={key}>
                      <Form.Check.Input
                        type={'checkbox'}
                        name={key}
                        id={key}
                        checked={filterCheckedState.some((i: any) => i === key)}
                        style={{ cursor: 'pointer' }}
                        onChange={handleChangeCheckbox}
                      />
                      <Form.Check.Label htmlFor={key}>
                        {typeData.label}
                      </Form.Check.Label>
                    </Form.Check>
                  )
                })}
              </Form>
              {showDayFilter && (
                <Form
                  name="day-filter-form"
                  onChange={handleChangeFilterDay}
                  className="mb-2"
                >
                  <div className="font-weight-bold">
                    Відображення гістограми
                  </div>
                  <Form.Check inline>
                    <Form.Check.Input
                      type="radio"
                      name={'day-filter'}
                      id="sum"
                      defaultChecked={checkedDayFilter === 'sum'}
                      style={{ cursor: 'pointer' }}
                    />
                    <Form.Check.Label htmlFor="sum">
                      Сумма всіх
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check inline>
                    <Form.Check.Input
                      type="radio"
                      name="day-filter"
                      id="to-day"
                      defaultChecked={checkedDayFilter === 'to-day'}
                      style={{ cursor: 'pointer' }}
                    />
                    <Form.Check.Label htmlFor="to-day">
                      Окремо по днях
                    </Form.Check.Label>
                  </Form.Check>
                </Form>
              )}
              {filterCheckedState.length > 1 && (
                <Form
                  name="style-filter-form"
                  onChange={handleChangeFilterStyle}
                >
                  <div className="font-weight-bold">Стиль гістограми</div>
                  <Form.Check inline>
                    <Form.Check.Input
                      type="radio"
                      name="style-filter"
                      id="overlay"
                      defaultChecked={checkedStyleFilter === 'overlay'}
                      style={{ cursor: 'pointer' }}
                    />
                    <Form.Check.Label htmlFor="overlay">
                      Стовпці перекриті
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check inline>
                    <Form.Check.Input
                      type="radio"
                      name="style-filter"
                      id="group"
                      defaultChecked={checkedStyleFilter === 'group'}
                      style={{ cursor: 'pointer' }}
                    />
                    <Form.Check.Label htmlFor="group">
                      Стовпці згруповані
                    </Form.Check.Label>
                  </Form.Check>
                </Form>
              )}
            </div>
            <div className="d-flex align-items-center">
              {isFetchingCsv && (
                <Spinner animation="border" variant="primary" />
              )}
              <Button
                onClick={() => {
                  dispatch(
                    getEntranceLoadReportCsvPdfAction({
                      ...period,
                      nodeId: place.id,
                      reportType: 'pdf',
                    })
                  )
                }}
                variant="outline-primary"
                className={styles.downloadButton}
                disabled={isFetchingCsv}
              >
                PDF
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    getEntranceLoadReportCsvPdfAction({
                      ...period,
                      nodeId: place.id,
                      reportType: 'xls',
                    })
                  )
                }}
                disabled={isFetchingCsv}
                variant="outline-primary"
                className={styles.downloadButton}
              >
                XLS
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="row mb-3 justify-content-center">
        {period.from && period.to && place.id && !!data?.length ? (
          checkedDayFilter === 'to-day' ? (
            datasForDayTo.map((d: any, index: number) => {
              return (
                <Histogram
                  data={d}
                  filterCheckedState={filterCheckedState}
                  key={index}
                  titleDate={d[0].dt}
                  barmode={checkedStyleFilter}
                  entranceTypes={entranceTypes}
                />
              )
            })
          ) : (
            <Histogram
              data={data}
              filterCheckedState={filterCheckedState}
              barmode={checkedStyleFilter}
              entranceTypes={entranceTypes}
            />
          )
        ) : data &&
          !data?.length &&
          period.from &&
          period.to &&
          place.id &&
          !isFetchingData ? (
          <div>
            <span>Немає даних</span>
          </div>
        ) : (
          !isFetchingData && (
            <div>
              <span>
                Необхідно вибрати початкову і кінцеву дату та пристрій
              </span>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default EntranceLoadReportPage
