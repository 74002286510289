import React, { useEffect, useState } from 'react';
import { ScrollView } from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';
import api from '../../../api';
import { StatusT } from '../../../api/modules/device';
import errorHandling, { ErrorResponseT } from '../../../helpers/errorHandling';

import styles from './DevicePopUp.module.scss';

interface Props {
  device?: any;

  closeModal: () => void;
}

function renderMetrics(status: StatusT) {
  const lastDeviceUpdate = new Date(status.lastUpdated).toLocaleDateString();
  const lastDeviceUpdateTime = new Date(status.lastUpdated).toLocaleTimeString();
  const lastUpdated = new Date().toLocaleDateString();
  const lastTime = new Date().toLocaleTimeString();
  const metrics = Object.entries(status.data);
  return (
    <ScrollView className={styles.scroll}>
      <div className="mt-4 mb-2">
        Останнє оновлення: {lastUpdated} {lastTime}
      </div>
      <div className="mt-4 mb-2">
        Останнє оновлення пристрою: {lastDeviceUpdate} {lastDeviceUpdateTime}
      </div>
      <ul className="list-group">
        {metrics.map(([key, value]: any) => (
          <li className="list-group-item" key={key}>
            <b>{key}</b>: {value}
          </li>
        ))}
      </ul>
    </ScrollView>
  );
}

const DevicePopUp = (props: Props) => {
  const [status, setStatus] = useState<StatusT | undefined>();
  const [popUpVisible, setIsPopUpVisible] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);

  function hideInfo(e: any) {
    props.closeModal();
  }

  async function refreshData(id?: number): Promise<void> {
    if (id) {
      setLoading(true);
      try {
        const result = await api.device.status(`${id}`);
        setLoading(false);
        setStatus(result.data);
        // eslint-disable-next-line no-shadow
      } catch (error) {
        setLoading(false);
        if (typeof error.message === 'string') {
          setError(error.message);
        } else {
          setError('Невiдома помилка');
        }
      }
    }
  }

  useEffect(() => {
    if (props.device) {
      api.device
        .status(`${props.device.id}`)
        .then((res: { data: StatusT }) => {
          setIsPopUpVisible(true);
          setStatus(res.data);
          setError(undefined);
        })
        .catch((errorMessage: ErrorResponseT) => setError(errorHandling(errorMessage)));
    } else {
      setIsPopUpVisible(false);
    }
  }, [props.device]);

  return (
    <Popup
      visible={popUpVisible}
      onHiding={hideInfo}
      dragEnabled={false}
      closeOnOutsideClick
      showTitle
      title={`${props.device ? props.device.name : ''}`}
      width="75%"
      height="85%"
    >
      <div className={styles.container}>
        {error && (
          <div className="alert alert-danger mt-4 w-100" role="alert">
            {error}
          </div>
        )}
        <Button
          icon="refresh"
          type="normal"
          text="Оновити"
          disabled={isLoading}
          onClick={() => refreshData(props.device.deviceId)}
        />
        <LoadPanel visible={isLoading} />
        {!!status && !isLoading && renderMetrics(status)}
      </div>
    </Popup>
  );
};

export default DevicePopUp;
