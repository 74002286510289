import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';


// export type CreateKey = {
//   data: any;
// };

export class KeyApiCRUD extends CRUD<any> {

  getKeysTypes() {
    return this.r({
      method: 'GET',
      url: `${this.config.url}/types`,
    });
  }

  getKeyVersions(id: string) {
    return this.r({
      method: 'GET',
      url: `${this.config.url}/${id}/versions`,
    });
  }

  createKeyVersion(data:any) {
    return this.r({
      method: 'POST',
      url: `${this.config.url}/version`,
      data
    });
  }

  updateKeyVersion(id: string, data:any) {
    return this.r({
      method: 'PUT',
      url: `${this.config.url}/version/${id}`,
      data
    });
  }

  deleteKeyVersion(id: string) {
    return this.r({
      method: 'DELETE',
      url: `${this.config.url}/version/${id}`,
    });
  }

  updateKeys(id: string | number, data: any) {
    return this.r({
      method: 'PUT',
      url: `${this.config.url}/${id}`,
      data,
    });
  }

  deleteKeyVersionById(id: string) {
    return this.r({
      method: 'DELETE',
      url: `${this.config.url}/version/${id}`,
    });
  }

  getListPrivatKeyForDevice(data: any) {
    return this.r({
      method: 'POST',
      url: `${this.config.url}/for-device`,
      data
    });
  }
}

export default function keysApiCRUD(request: AxiosInstance): KeyApiCRUD {
  const crud = new KeyApiCRUD({
    url: '/keys',
    request,
    id: 'id',
  });

  return crud;
}
