import { AxiosInstance, AxiosPromise } from 'axios';
import CRUD from '../base/crud';

export type DeviceT = {
  id: number;
  deviceId: string;
  deviceMac: string;
  name: string;
  firmwareGroupId: number;
};

export type StatusT = {
  lastUpdated: Date;
  data: Record<string, any>;
};

export class DeviceCRUD extends CRUD<DeviceT> {
  status(id: string): AxiosPromise<StatusT> {
    return this.r({
      method: 'GET',
      url: `${this.config.url}/status/${id}`,
    });
  }
}

export default function deviceCRUD(request: AxiosInstance): DeviceCRUD {
  return new DeviceCRUD({
    url: '/device',
    request,
    id: 'id',
  });
}
