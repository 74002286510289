import { AxiosError } from 'axios';
import { localizedServerErrors } from '../constants/errorsLocalization';

export type ErrorResponseT = {
  response: {
    data: { message: string; code: string };
  };
  message: string;
};

export default function errorHandling(error: ErrorResponseT): string {
  const code = error.response?.data?.code;
  return code ? localizedServerErrors[code] : error.message;
}

export function storeErrorHandler(e: AxiosError): never {
  if (e.message.includes('code 403')) {
    window.localStorage.removeItem('accessToken');
    window.location.replace(window.location.origin);
  }
  const errorCode = e.response?.data?.code;
  const message =
    errorCode && localizedServerErrors[errorCode]
      ? localizedServerErrors[errorCode]
      : localizedServerErrors['???'];

  throw new Error(message);
}
