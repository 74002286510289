/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-ignore-file
// @ts-ignore-start
import React, { useMemo, useState, useEffect, useRef } from 'react'
import cx from 'clsx'
import CustomStore from 'devextreme/data/custom_store'
import { useHistory } from 'react-router-dom'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import DataGrid from 'devextreme-react/data-grid'

import Plotly from 'react-plotly.js'
import { GrUpdate } from 'react-icons/gr'
import SelectBox from 'devextreme-react/select-box'

import { Button } from 'devextreme-react/button'
import Table from './components/Table/Table'
import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'

import styles from './MainPage.module.scss'
import PageTitle from '../../components/PageTitle/PageTitle'

interface Diagram {
  type: string
  ids: any[]
  labels: any[]
  parents: any[]
  outsidetextfont: {
    size: number
    color: string
  }
  marker: {
    line: {
      width: number
    }
    colors: any[]
  }
  hovertext: any[]
  hoverinfo: string
  level?: number | string
}

const MIN = 1000 * 60

function buildTableData(parameters: any) {
  const { children } = parameters
  const allAlarm: any[] = []
  const alarmDevices: any = {}

  const getAlarmMetrics = (children: any) => {
    if (children && children.length) {
      children.forEach((ch: any) => {
        if (ch.metrics) {
          const _metrics = ch?.metrics?.length ? [...ch.metrics] : []

          const filteredMetrics = _metrics.filter((metric: any) => {
            if (
              _metrics.some(
                (a: any) =>
                  a.metricName === 'Virtual Metric' && a.alarmTypeId !== 'OK'
              ) &&
              metric.metricName !== 'Virtual Metric'
            ) {
              return false
            }
            return !!metric.idAlarmType && metric.alarmTypeId !== 'NA'
          })

          filteredMetrics.forEach((m: any) => {
            alarmDevices[m.idAlarmType] = alarmDevices[m.idAlarmType]
              ? [...alarmDevices[m.idAlarmType], ch.deviceId]
              : [ch.deviceId]
            if (
              (m.alarmId || m.metricName === 'Virtual Metric') &&
              m.alarmTypeId !== 'NA'
            ) {
              allAlarm.push({
                id: m.idAlarmType,
                color: m.color,
                alarmType: m.alarmDescription || m.alarmTypeId,
                alarmId: m.alarmTypeId,
              })
            }
          })
        } else {
          getAlarmMetrics(ch.children)
        }
      })
    }
  }

  getAlarmMetrics(children)

  const res = allAlarm.reduce((r: any[], i: any) => {
    if (r.some((a: any) => a.id === i.id)) {
      return r.map((a: any) => {
        if (a.id === i.id) {
          return {
            ...a,
            count: a.count + 1,
          }
        }
        return a
      })
    }
    r.push({
      color: i.color,
      alarmType: i.alarmType,
      count: 1,
      id: i.id,
      alarmId: i.alarmId,
      devicesIds: alarmDevices[i.id],
    })
    return r
  }, [])
  return res
}

const MainPage: React.FC = () => {
  const history = useHistory()
  const tableRefref = useRef<DataGrid>(null)
  const [diagramData, setDiagramData] = useState<Diagram>({
    type: 'sunburst',
    ids: [],
    labels: [],
    parents: [],
    outsidetextfont: { size: 20, color: '#377eb8' },
    marker: {
      line: { width: 2 },
      colors: [],
    },
    hovertext: [],
    hoverinfo: 'text',
  })

  const [intervalOfUpdates, setIntervalOfUpdates] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [curentLevel, setCurrentLevel] = useState('0')

  const alarmTypeData = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        //loadMode: 'processed',
        load: async () => {
          setIsFetching(true)
          return api.config
            .get()
            .then(({ data }) => {
              const filteredData = ConfigurationsFilterByAlarms(data)
              buildPieChartDataFromParameters(filteredData)
              return buildTableData(data)
            })
            .catch(storeErrorHandler)
            .finally(() => {
              setIsFetching(false)
            })
        },
      }),
    // eslint-disable-next-line
    []
  )

  function buildPieChartDataFromParameters(parameters: any): void {
    const arrayParents: any[] = ['']
    const idsPieChart: any[] = [parameters.id]
    const pieLabelsLocal: any[] = [parameters.name]
    const pieTextToolTipLocal: any[] = ['']
    const pieColors: string[] = ['']

    function makeAlarmBlocks(childrenNode: any) {
      const _metrics = childrenNode?.metrics?.length
        ? [...childrenNode.metrics]
        : []

      const _metricObject: any =
        _metrics.reduce((res: any, m: any) => {
          if (
            (m.alarmId || m.metricName === 'Virtual Metric') &&
            m.alarmTypeId !== 'NA'
          ) {
            const alarmTypeId = m.alarmTypeId
            const mDescription = m.metricDescription || ''
            res[alarmTypeId] = res[alarmTypeId]
              ? {
                  ...res[alarmTypeId],
                  description: [...res[alarmTypeId].description, mDescription],
                }
              : {
                  alarmId: m.alarmId,
                  deviceId: childrenNode.deviceId,
                  description: [mDescription],
                  color: m.color,
                }
          }
          return res
        }, {}) || {}

      let _metricObjectFiltered: any = {}

      if (_metricObject.ConnectError) {
        _metricObjectFiltered.ConnectError = { ..._metricObject.ConnectError }
      } else {
        _metricObjectFiltered = { ..._metricObject }
      }

      const _metricObjectEntries: any = Object.entries(_metricObjectFiltered)

      if (_metricObjectEntries.length > 1) {
        delete _metricObjectFiltered['OK']
      }

      if (Object.entries(_metricObjectFiltered).length) {
        for (const item of Object.entries(_metricObjectFiltered)) {
          const key: any = item[0]
          const value: any = item[1]
          const { alarmId, deviceId, description, color } = value

          idsPieChart.push(`${deviceId}-${alarmId}-${key}`)
          pieColors.push(color)
          arrayParents.push(childrenNode.id)
          pieLabelsLocal.push(key)

          const _desc = description.join('')

          let _conFormatte = ''

          description.forEach((c: any) => {
            _conFormatte = _conFormatte + `${c}<br>`
          })

          const _contentHoverInfo =
            `<b>Пристрій:</b> ${childrenNode.name}<br>` +
            (_desc
              ? `<b>Тривога:</b> ${key}.<br><b>Метрика(и):</b> ${_conFormatte}`
              : `<b>Тривога:</b> ${key}`)

          pieTextToolTipLocal.push(_contentHoverInfo)
        }
      }
    }

    function addFromParametersToPieChirt(node: any): void {
      if (node.children && node.children.length) {
        node.children.forEach((childrenNode: any) => {
          idsPieChart.push(childrenNode.id)
          pieColors.push('')
          arrayParents.push(node.id)
          pieLabelsLocal.push(childrenNode.name)
          if (childrenNode.deviceId) {
            pieTextToolTipLocal.push(childrenNode.name)
          } else {
            pieTextToolTipLocal.push('')
          }

          if (childrenNode.device && childrenNode.device.deviceId) {
            makeAlarmBlocks(childrenNode)
          }
          if (childrenNode.children && childrenNode.children.length) {
            addFromParametersToPieChirt(childrenNode)
          }
        })
      }
    }

    addFromParametersToPieChirt(parameters)
    setDiagramData({
      ...diagramData,
      ids: idsPieChart,
      labels: pieLabelsLocal,
      parents: arrayParents,
      hovertext: pieTextToolTipLocal,
      marker: {
        ...diagramData.marker,
        colors: pieColors,
      },
    })
  }

  const ConfigurationsFilterByAlarms = (tree: any) => {
    const updatedTree = { ...tree }

    const _filterTree = (children: any): any => {
      if (children && children.length) {
        return children.filter((n: any) => {
          if (n.device && n.deviceId) {
            return true
          } else {
            if (n.children && n.children.length) {
              n.children = _filterTree(n.children)
              if (n.children.length === 0) {
                return false
              }
              return true
            }
            return false
          }
        })
      }
      return children
    }

    return { ...updatedTree, children: _filterTree(updatedTree.children) }
  }

  function handleClickAlarm(event: any): void {
    const point: any = event?.points[0] || null
    if (point && point.id && typeof point.id === 'string') {
      const [id] = point?.id?.split('-')
      if (id) {
        //window.open(`${window.location.origin}/device-state/?device-id=${id}`);
        history.push(`/device-state/?device-id=${id}`)
      }
    }
  }

  const handleChangeUpdateInterval = (e: any) => {
    setIntervalOfUpdates(e.value)
  }

  const handlerClickUpdate = () => {
    tableRefref.current.instance.refresh()
  }

  const handleUpdateLevel = (e: any) => {
    const { data } = e || {}
    const { level } = data[0] || []
    setCurrentLevel(level || '0')
  }

  useEffect(() => {
    let timeout = null
    if (intervalOfUpdates) {
      timeout = setInterval(() => {
        tableRefref.current.instance.refresh()
      }, intervalOfUpdates)
    } else {
      clearInterval(timeout)
    }

    return () => {
      clearInterval(timeout)
    }
  }, [intervalOfUpdates, alarmTypeData])

  return (
    <>
      <div className={styles.titleContainer}>
        <PageTitle title="Тривоги" />
        <div className={styles.updateSection}>
          <Button
            width={40}
            type="normal"
            stylingMode="contained"
            onClick={handlerClickUpdate}
            className={styles.updateButton}
          >
            <GrUpdate
              color="#495057"
              className={isFetching && styles.updateIconFetching}
            />
          </Button>
          <SelectBox
            value={intervalOfUpdates}
            onValueChanged={handleChangeUpdateInterval}
            items={[
              { name: 'ніколи', value: null },
              { name: '1 хв', value: MIN },
              { name: '5 хв', value: MIN * 5 },
              { name: '10 хв', value: MIN * 10 },
              { name: '30 хв', value: MIN * 30 },
            ]}
            grouped={false}
            displayExpr="name"
            valueExpr="value"
            width={90}
            className={styles.selectBox}
          />
        </div>
      </div>
      <div className={cx(styles.container)}>
        <Table data={alarmTypeData} ref={tableRefref} />
        <Plotly
          onUpdate={handleUpdateLevel}
          onClick={handleClickAlarm}
          data={[{ ...diagramData, level: curentLevel }] as any}
          layout={{
            margin: { l: 0, r: 0, b: 0, t: 50 },
            width: 700,
            height: 750,
          }}
        />
      </div>
    </>
  )
}
// @ts-ignore-end

export default MainPage
