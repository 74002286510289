import React, { useState } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import ArrayStore from 'devextreme/data/array_store'
import DataGrid, {
  Column,
  Editing,
  Lookup,
  Scrolling,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'

import api from '../../api'

import Layout from './Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import { storeErrorHandler } from '../../helpers/errorHandling'

const typeData = Object.values(api.configParam.TYPES).map(type => ({ type }))

function disableIfUses({ row }: any): boolean {
  return !row?.data?.uses
}

const ConfigurationParamsPage: React.FC = () => {
  const [isAllowUpdate, setIsAllowUpdate] = useState(false)

  const paramsStore: any = new CustomStore({
    key: 'id',
    load: () =>
      api.configParam
        .get({})
        .then(result => result.data)
        .catch(storeErrorHandler),
    remove: id => api.configParam.delete(id).catch(storeErrorHandler),
    update: (id, data) => {
      return api.configParam.update({ id, ...data }).catch(storeErrorHandler)
    },
    insert: data => api.configParam.create(data).catch(storeErrorHandler),
  })

  const typesStore = new ArrayStore({
    key: 'type',
    data: typeData,
  })

  const onEditorPreparing = (e: any) => {
    setIsAllowUpdate(!e.row.data.uses)
  }

  return (
    <Layout>
      <PageTitle title="Параметри" />

      <p className="text-muted">
        Редагування або видалення параметрів можливо тільки якщо вони не
        використовуються.
      </p>
      <div className="mb-3">
        <DataGrid
          id="grid"
          showBorders
          dataSource={paramsStore}
          repaintChangesOnly
          onEditorPreparing={onEditorPreparing}
        >
          <Editing
            refreshMode="full"
            mode="form"
            allowAdding
            allowDeleting={disableIfUses}
            allowUpdating={true}
            useIcons
          />

          <Scrolling mode="virtual" />
          <Column
            dataField="name"
            caption="Им'я"
            allowEditing={isAllowUpdate}
          />
          <Column dataField="type" caption="Тип" allowEditing={isAllowUpdate}>
            <Lookup
              dataSource={typesStore}
              valueExpr="type"
              displayExpr="type"
            />
          </Column>
          <Column dataField="purpose" caption="Призначення" />
          <Summary>
            <TotalItem column="CustomerID" summaryType="count" />
          </Summary>
        </DataGrid>
      </div>
    </Layout>
  )
}

export default ConfigurationParamsPage
