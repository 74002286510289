import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spinner } from 'react-bootstrap'
import { uniqueId } from 'lodash'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  ColumnChooser,
} from 'devextreme-react/data-grid'
import { Template } from 'devextreme-react/core/template'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import styles from './TransactionsReportPage.module.scss'
import api from '../../api'

import { storeErrorHandler } from '../../helpers/errorHandling'

import { getTransactionReportCsvPdfAction } from '../../store/sagas/transaction-report'

export type SelectedPlaceT = {
  name?: any
  id?: any
  deviceId?: any
}

export type dateTypeT = 'day' | 'range'

export type SelectedDateT = {
  from?: string | number | null
  to?: number | string | null
}

const TransactionsReportPage: React.FC = () => {
  const location: any = useLocation()
  const query = new URLSearchParams(location.search)
  const _deviceId = query.get('device-id')
  const _deviceName = query.get('device-name')
  const dispatch = useDispatch()
  const isFetchingCsv = useSelector(
    (state: any) => state?.transactionState?.isFetchingCsv
  )

  const dataGridRef = useRef<DataGrid>(null)
  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState<SelectedDateT>({
    from: null,
    to: null,
  })
  const [dateType, setDateType] = useState<dateTypeT>('day')
  const [withCounter, setWithCounter] = useState(false)

  const transactionsDevice: any = useMemo(() => {
    return new CustomStore({
      key: 'id',
      load: ({ skip }) => {
        const _page = !skip ? 1 : skip / 100 + 1
        return api.transactionsDevice
          .getTransactionsReport({
            nodeId: place?.id,
            ...period,
            page: _page,
            perPage: 100,
            includeCounters: withCounter,
          })
          .then(result => {
            // @ts-ignore
            const { data } = result

            const transactions =
              data?.transactions?.map((t: any) => ({
                ...t,
                id: uniqueId(),
              })) ?? []

            return {
              data: transactions,
              //totalCount: data.count,
            }
          })
          .catch(storeErrorHandler)
      },
    })
  }, [place, period, withCounter])

  const handleChangeWithCounter = useCallback(
    e => {
      setWithCounter(e.value)
    },
    [setWithCounter]
  )

  useEffect(() => {
    if (dateType === 'day' && period.to) {
      const nowDay = new Date(period.to).setHours(2, 59, 59, 999)
      setPeriod({
        from: period.from,
        to: nowDay,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType])

  function onChangePeriod(key: string, value: any) {
    if (dateType === 'day') {
      setPeriod({
        from: value.from,
        to: value.to,
      })
    } else {
      setPeriod({
        ...period,
        [key]: value,
      })
    }
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const downloadReport = () => {
    dispatch(
      getTransactionReportCsvPdfAction({
        nodeId: place.id,
        type: 'xls',
        ...period,
        perPage: 10000,
        page: 1,
      })
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: updateData,
        },
      }
    )
  }

  useLayoutEffect(() => {
    if (_deviceId) {
      const nowDay = new Date()

      const to = nowDay.getTime()
      let from = new Date(to).setHours(3, 0, 0, 0)
      if (to < from) {
        from = new Date(from).setDate(new Date(from).getDate() - 1)
      }
      setPlace({ id: _deviceId, name: _deviceName })
      setPeriod({
        from: new Date(from).getTime(),
        to: to,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (place.id) {
      query.set('device-id', String(place?.id))
      query.set('device-name', String(place?.name))
      window.history.replaceState(null, '', '?' + query.toString())
    }
    if (!place.id) {
      window.history.replaceState(null, '', 'transactions-report')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place])

  useEffect(updateData, [period, place])

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column">
        <PageTitle title="Звіт з транзакцій" className="mb-2" />
        <ForPeriodTitleSection
          from={period.from}
          to={period.to}
          isUpdateButton={false}
        />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          setSelectedPlace={setPlace}
          selectedPlace={place.name || ''}
          isDeviceId={!!place.deviceId}
          handleChangeDateType={setDateType}
          withCounter={withCounter}
          handleChangeWithCounter={handleChangeWithCounter}
        />
      </div>
      <div className="row">
        {place?.id && period.from && period.to ? (
          <div className="d-flex flex-column">
            <DataGrid
              ref={dataGridRef}
              dataSource={transactionsDevice}
              showBorders
              allowColumnReordering={true}
              className={styles.transactionTableContainer}
              remoteOperations={true}
              sorting={{ mode: 'none' }}
              width="1140"
              height="62vh"
              onToolbarPreparing={onToolbarPreparing}
            >
              <ColumnChooser enabled={true} />
              <Scrolling mode="infinite" rowRenderingMode="virtual" />
              <Paging defaultPageSize={100} />
              <Column
                dataField="transactionDate"
                caption="Дата Транзакції"
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
                width={150}
              />
              <Column
                dataField="transactionNum"
                caption="Номер Транзакції"
                width={90}
                cssClass={styles.wordWrap}
              />
              <Column
                dataField="deviceId"
                caption="Термінал"
                width={200}
                cssClass={styles.wordWrapCell}
              />
              <Column
                dataField="transactionType"
                caption="Тип Транзакції"
                width={120}
                cssClass={styles.wordWrapCell}
              />
              <Column
                dataField="productCode"
                caption="Код продукту"
                width={200}
                cssClass={styles.wordWrapCell}
              />
              <Column
                dataField="moneyRemainder"
                caption="Залишок грошей"
                width={80}
                cssClass={styles.wordWrap}
              />
              <Column
                dataField="passRemainder"
                caption="Залишок поїздок"
                width={80}
                cssClass={styles.wordWrap}
              />
              <Column
                dataField="ident"
                caption="Ідентифікатор"
                width={180}
                cellRender={IdentCellRender}
              />
              <Column
                dataField="online"
                caption="Онлайн"
                cellRender={OnlineCellRender}
                width={70}
              />
              <Column
                dataField="errorMessage"
                caption="Помилка"
                width={150}
                cssClass={styles.wordWrapCell}
              />
              <Column
                dataField="productName"
                caption="Ім'я продукту"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="validity"
                width={'auto'}
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
                caption="Дата валідності"
                visible={false}
              />
              <Column
                dataField="errorCode"
                caption="Код помилки"
                visible={false}
                width={'90px'}
                cssClass={styles.wordWrap}
              />
              <Column
                dataField="AUTHCODE"
                caption="Код авторизації банк"
                width={'90px'}
                cssClass={styles.wordWrap}
                visible={false}
              />
              <Column
                dataField="BANKCARD"
                caption="Лічильник банк карт"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="BANKCARDFISC"
                caption="Фиск лічильник банк карт"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="BANKTERMINALID"
                caption="Номер банк POS"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="BTNGATEOPEN"
                caption="Лічильник відкриття воріт по кнопці"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="BTNPASSOPEN"
                caption="Лічильник відкриття проходу по кнопці"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="FIRSTTRANSNUM"
                caption="Номер першої транзакції"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="INVOICENUM"
                caption="Банк номер інвойсу"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="LASTTRANSNUM"
                caption="Номер останньої транзакції"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="PAN"
                caption="Номер банк карти"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="QRCCODE"
                caption="Лічильник QR кодів"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="QRCCODEFISC"
                caption="Фиск лічильник QR кодів"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="RIDANGOCARD"
                caption="Лічильник ріданго карт"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="RIDANGOCARDFISC"
                caption="Фиск лічильник ріданго карт"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="RRN"
                caption="ID банківської транзакції"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="SUMOP"
                caption="Сума"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="TRANSACTIONID"
                caption="ID транзакції"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="TRANSPCARD"
                caption="Лічильник трансп карт"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="TRANSPCARDFISC"
                caption="Фиск лічильник трансп карт"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="UNAUTHDOWNSENSOR"
                caption="Неавт прохід верхній"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="UNAUTHUPSENSOR"
                caption="Неавт прохід нижній"
                width={'auto'}
                visible={false}
              />
              <Column
                dataField="counter0"
                width={'auto'}
                visible={false}
                caption="Фиск лічильник QR кодів"
              />
              <Column
                dataField="counter1"
                width={'auto'}
                visible={false}
                caption="Фиск лічильник ріданго карт"
              />
              <Column
                dataField="counter2"
                width={'auto'}
                visible={false}
                caption="Фиск лічильник трансп карт"
              />
              <Column
                dataField="counter3"
                width={'auto'}
                visible={false}
                caption="Фиск лічильник банк карт"
              />
              <Template name="spinner" render={spinnerComponent} />
            </DataGrid>
          </div>
        ) : (
          <div>
            <span>Необхідно вибрати пристрій та дату</span>
          </div>
        )}
      </div>
    </div>
  )
}

// counter0: 12293
// counter1: 124667
// counter2: 70858
// counter3: 64610
// deviceId: "TurnstileHeroDnipra2.1"
// transactionDate: "2021-06-07T20:00:00.191Z"

const OnlineCellRender = (props: any) => {
  const { data } = props
  const { online } = data
  const text = online === 0 ? 'Offline' : 'Online'
  const color = online === 0 ? 'blue' : 'green'
  return (
    <span
      className="badge"
      style={{ backgroundColor: color, color: '#fff', width: 'auto' }}
    >
      {text}
    </span>
  )
}

const IdentCellRender = (props: any) => {
  const { data } = props
  const { ident } = data

  const handleClick = (e: any) => {
    e.preventDefault()
    window.open(
      `${window.location.origin}/reports/route-sheet-by-number?pattern=${ident}`,
      '_blank'
    )
  }

  return (
    <Button
      variant="link"
      style={{ fontSize: 14, padding: 0, lineHeight: 1 }}
      onClick={handleClick}
    >
      {ident}
    </Button>
  )
}

export default TransactionsReportPage
