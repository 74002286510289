import React, { useEffect, useRef, useState } from 'react';
import { DropDownBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import { Column, Selection, TreeList } from 'devextreme-react/tree-list';
import { Template } from 'devextreme-react/core/template';

import api from '../../api';
import { ConfigT } from '../../api/modules/config';
import { storeErrorHandler } from '../../helpers/errorHandling';
import { findParameterById, removeDevicesFromTree } from '../../helpers/treeModificator';

const expandedRowKeys = [1];

function NameCell({ data }: { data: ConfigT }): React.ReactFragment {
  const icon = data.deviceId ? 'fa fa-microchip' : 'fa fa-object-group';

  return (
    <>
      <i className={icon} /> {data.name}
    </>
  );
}

const paramsListStore: any = new CustomStore({
  key: 'id',
  load: async () => {
    const data = await api.config
      .get({})
      .then((result: any) => result.data)
      .catch(storeErrorHandler);

    return removeDevicesFromTree(data);
  },
});

interface DropDownDevicesTreeI {
  cellOption: any;
  dataSource?: any;
}

interface Value {
  name: string;
  value: string | number | null;
}

const ParameterListDropDown = ({ cellOption, dataSource }: DropDownDevicesTreeI) => {
  const [value, setValue] = useState<Value>({
    name: '',
    value: null,
  });

  useEffect(() => {
    if (cellOption?.data?.parameterParentId) {
      const parameter = findParameterById(dataSource, cellOption?.data?.parameterParentId);

      if (parameter) {
        setValue({
          name: parameter.name,
          value: parameter.id,
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  const dropDownRef = useRef<DropDownBox>(null);
  const treeListRef = useRef<TreeList>(null);

  const handleSelectionChanged = (event: { selectedRowsData?: Array<ConfigT> }) => {
    const selectedNode = event?.selectedRowsData?.[0];
    if (!selectedNode || !selectedNode.id) {
      return;
    }

    const { name, id } = selectedNode;

    setValue({
      name,
      value: id,
    });
    if (dropDownRef.current) {
      dropDownRef.current.instance.close();
    }
  };

  useEffect(() => {
    cellOption.setValue(value.value);
    // eslint-disable-next-line;
  }, [value, cellOption]);

  return (
    <DropDownBox
      ref={dropDownRef}
      value={value.name}
      valueExpr="ID"
      displayExpr="name"
      placeholder="Виберіть параметр..."
      showClearButton
      onValueChanged={() => {
        // eslint-disable-next-line no-unused-expressions
        treeListRef.current?.instance.deselectAll();
        return setValue({
          name: '',
          value: null,
        });
      }}
      contentRender={() => (
        <TreeList
          ref={treeListRef}
          id="configTree"
          dataSource={dataSource.children || paramsListStore}
          defaultExpandedRowKeys={expandedRowKeys}
          columnAutoWidth
          itemsExpr="children"
          dataStructure="tree"
          onSelectionChanged={handleSelectionChanged}
          height="100%"
        >
          <Selection mode="single" />
          <Column dataField="name" caption="Ім'я" cellTemplate="nameTemplate" />

          <Template name="nameTemplate" render={NameCell} />
        </TreeList>
      )}
    />
  );
};

export default ParameterListDropDown;
