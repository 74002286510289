import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uniqueId } from 'lodash'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid'
import { Template } from 'devextreme-react/core/template'
import { Spinner } from 'react-bootstrap'

import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'
import styles from './MetricsStateOnDeviceReport.module.scss'
import Filter from './components/filter/Filter'
import PageTitle from '../../components/PageTitle/PageTitle'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import { getMetricsStateOnDeviceReportXls } from '../../store/sagas/transaction-report'

export type SelectedPlaceT = {
  name?: any
  id?: any
}

export type SelectedDateT = {
  from?: string | number | null
  to?: number | string | null
}

const MetricsStateOnDeviceReport: React.FC = () => {
  const dispatch = useDispatch()
  const isFetchingCsv = useSelector(
    (store: any) => store.transactionState.isFetchingCsv
  )

  const dataGridRef = useRef<DataGrid>(null)

  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState<SelectedDateT>({
    from: null,
    to: null,
  })

  const eventReport: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: async () => {
          try {
            const res = await api.reports.getMetricsStateOfDeviceReportApi({
              nodeId: place?.id,
              ...period,
            })
            const _res = res?.data?.map(i => ({ ...i, id: uniqueId() })) || []
            return _res
          } catch (error) {
            storeErrorHandler(error)
          }
        },
      }),
    [place, period]
  )

  const downloadReport = useCallback(() => {
    dispatch(
      getMetricsStateOnDeviceReportXls({
        ...period,
        nodeId: place.id,
        reportType: 'xls',
        perPage: 10000,
        page: 1,
      })
    )
  }, [dispatch, place, period])

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      }
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  function onChangePeriod(key: string, value: any) {
    setPeriod({
      ...period,
      [key]: value,
    })
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  useEffect(updateData, [period, place])

  return (
    <div className="container d-flex flex-column">
      <div className="row d-flex flex-column">
        <PageTitle
          title="Звіт зі змін стану метрик на пристрої"
          className="mb-4"
        />
        <ForPeriodTitleSection from={period.from} to={period.to} />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
          setSelectedPlace={setPlace}
          selectedPlace={place.name || ''}
        />
      </div>
      <div className="row">
        {place?.id && period.from && period.to ? (
          <div className="d-flex flex-column">
            <DataGrid
              ref={dataGridRef}
              dataSource={eventReport}
              showBorders
              allowColumnReordering={true}
              className={styles.transactionTableContainer}
              onToolbarPreparing={onToolbarPreparing}
            >
              <Paging defaultPageSize={100} />
              <Column
                dataField="date"
                caption="Дата"
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
                width={150}
              />
              <Column dataField="name" caption="Метрика" width={'auto'} />
              <Column
                dataField="value"
                caption="Значення метрики"
                width={'auto'}
              />
              <Template name="spinner" render={spinnerComponent} />
            </DataGrid>
          </div>
        ) : (
          <div>
            <span>Необхідно вибрати пристрій та дату</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default MetricsStateOnDeviceReport
