import React, { useEffect, useState } from 'react'
import { TextBox } from 'devextreme-react'
import { ITextBoxOptions } from 'devextreme-react/text-box'

const EditTextInput = ({
  textBoxParams,
  cellOption,
}: {
  textBoxParams: ITextBoxOptions
  cellOption: any
}) => {
  const [value, setValue] = useState(
    String(cellOption?.data[cellOption?.column?.dataField]) ?? ''
  )

  useEffect(() => {
    cellOption.setValue(value)
    // eslint-disable-next-line
  }, [value])
  return (
    <TextBox
      {...textBoxParams}
      value={value}
      onValueChanged={(e: any) => {
        setValue(e.event?.target?.value || '')
      }}
    />
  )
}
export default EditTextInput
