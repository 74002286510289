import React, { useRef } from 'react'
import { DropDownBox } from 'devextreme-react'
import CustomStore from 'devextreme/data/custom_store'
import { Column, Selection, TreeList, Lookup } from 'devextreme-react/tree-list'
import { Template } from 'devextreme-react/core/template'

import api from '../../../../api'
import { ConfigT } from '../../../../api/modules/config'
import { DeviceT } from '../../../../api/modules/device'
import { storeErrorHandler } from '../../../../helpers/errorHandling'
import { SelectedPlaceT } from '../../MonthlyTransportationReportByNode'
import { removeDevicesFromTree } from '../../../../helpers/treeModificator'

const expandedRowKeys = [1]

function NameCell({ data }: { data: ConfigT }): React.ReactFragment {
  const icon = data.deviceId ? 'fa fa-microchip' : 'fa fa-object-group'

  return (
    <>
      <i className={icon} /> {data.name}
    </>
  )
}

const deviceName = (d: DeviceT): string =>
  d.deviceId ? d.deviceId : d.deviceMac

function collectLinkedDevices(tree: ConfigT): number[] {
  const visited = []
  const queue = []
  let current: ConfigT | void = tree

  queue.push(current)
  while (queue.length) {
    current = queue.shift()
    if (!current) {
      continue
    }
    if (current.deviceId) {
      visited.push(current.deviceId)
    }

    queue.push(...current.children)
  }

  return visited
}

let tree: ConfigT
let devices: DeviceT[] = []
let linked: number[] = []

const configStore: any = new CustomStore({
  key: 'id',
  load: async () => {
    const batch = [
      api.config
        .withoutMetrics()
        .then((result: any) => result.data)
        .catch(storeErrorHandler),
      api.device
        .get({})
        .then(result => result.data)
        .catch(storeErrorHandler),
    ]
    ;[tree, devices] = await Promise.all(batch)
    linked = collectLinkedDevices(tree)
    return removeDevicesFromTree(tree)
  },
})

interface DropDownDevicesTreeI {
  value: string | number
  setSelectedPlace: (data: SelectedPlaceT) => void
}

const DropDownDevicesTree = ({
  value,
  setSelectedPlace,
}: DropDownDevicesTreeI) => {
  const dropDownRef = useRef<DropDownBox>(null)
  const treeListRef = useRef<TreeList>(null)
  const handleSelectionChanged = (event: {
    selectedRowsData?: Array<ConfigT>
  }) => {
    const selectedNode = event?.selectedRowsData?.[0]
    // selectedNode.id is undefined for new nodes (creating)
    if (!selectedNode || !selectedNode.id) {
      return
    }
    setSelectedPlace({
      deviceId: selectedNode.deviceId,
      name: selectedNode.name,
      id: selectedNode.id,
    })
    if (dropDownRef.current) {
      dropDownRef.current.instance.close()
    }
  }

  function getDeviceData({ data }: { data: ConfigT }): DeviceT[] {
    if (!data) {
      return []
    }
    return devices.filter(d => !linked.includes(d.id) || d.id === data.deviceId)
  }

  return (
    <DropDownBox
      ref={dropDownRef}
      value={value}
      valueExpr="ID"
      displayExpr="name"
      placeholder="Виберіть пристрій..."
      showClearButton
      width={300}
      onValueChanged={() => {
        // eslint-disable-next-line no-unused-expressions
        treeListRef.current?.instance.deselectAll()
        setSelectedPlace({})
      }}
      contentRender={() => (
        <TreeList
          height="100%"
          ref={treeListRef}
          id="configTree"
          dataSource={configStore}
          defaultExpandedRowKeys={expandedRowKeys}
          columnAutoWidth
          itemsExpr="children"
          dataStructure="tree"
          onSelectionChanged={handleSelectionChanged}
        >
          <Selection mode="single" />
          <Column dataField="name" caption="Ім'я" cellTemplate="nameTemplate" />
          <Column dataField="deviceId" caption="Турнікет" visible={false}>
            <Lookup
              dataSource={getDeviceData}
              valueExpr="id"
              displayExpr={deviceName}
              allowClearing
            />
          </Column>

          <Template name="nameTemplate" render={NameCell} />
        </TreeList>
      )}
    />
  )
}

export default DropDownDevicesTree
