import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { Column, TreeList } from 'devextreme-react/tree-list'
import { useHistory } from 'react-router-dom'
import CustomStore from 'devextreme/data/custom_store'
import { Template } from 'devextreme-react/core/template'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import clsx from 'clsx'
import { uniqueId, cloneDeep } from 'lodash'
import { Button, Form } from 'react-bootstrap'

import isLight from '../../helpers/isLight'

import PageTitle from '../../components/PageTitle/PageTitle'
import { storeErrorHandler } from '../../helpers/errorHandling'
import api from '../../api'

import styles from './AlarmPage.module.scss'
import { ConfigT } from '../../api/modules/config'

const ALL_ALARM_STATE = 'all'

function NameCell(props: any) {
  const { data, handleClickButton } = props

  const handleClick = () => {
    handleClickButton(data.deviceId)
  }

  if (data.deviceId) {
    return (
      <button
        onClick={handleClick}
        style={{ background: 'none', border: 'none' }}
      >
        <i className={'fa fa-microchip'} /> {data.name}
      </button>
    )
  }

  return (
    <>
      <i className={'fa fa-object-group'} /> {data.name}
    </>
  )
}

interface TreeWithErrors extends ConfigT {
  errors?: any[]
  errorLen?: number
  index?: number
}

function setDeviceErrorsToTree(tree: ConfigT): TreeWithErrors {
  const treeWithErrors: TreeWithErrors = { ...tree }

  treeWithErrors.index = 0
  const brunches = []

  brunches.push(treeWithErrors)

  while (brunches.length) {
    const lastBranch: any = brunches[brunches.length - 1]
    const childLength = lastBranch.children.length
    if (lastBranch.deviceId && lastBranch.metrics) {
      lastBranch.errors = lastBranch.metrics.filter(
        (metric: any, i: any, arr: []) => {
          if (
            arr.some(
              (a: any) =>
                a.metricName === 'Virtual Metric' && a.alarmTypeId !== 'OK'
            ) &&
            metric.metricName !== 'Virtual Metric'
          ) {
            return false
          }
          return !!metric.idAlarmType && metric.alarmTypeId !== 'NA'
        }
      )
      if (lastBranch.errors.length) {
        brunches.forEach(node => {
          // eslint-disable-next-line no-param-reassign
          node.errorCount = node.errorCount
            ? { ...node.errorCount, [lastBranch.deviceId]: lastBranch.errors }
            : { [lastBranch.deviceId]: lastBranch.errors }
        })
      }

      brunches.pop()
      continue
    }

    if (
      !lastBranch ||
      (!childLength && !lastBranch.devicePrimalId) ||
      lastBranch.index === childLength
    ) {
      brunches.pop()
      continue
    }

    const newBranch = lastBranch.children[lastBranch.index]
    newBranch.index = 0
    brunches.push(newBranch)
    lastBranch.index += 1
  }
  return treeWithErrors
}

const getAlayrmTypes = (tree: any) => {
  const alltype: any = {}
  const _recurseF = (node: any) => {
    if (node.length) {
      node.forEach((i: any) => {
        if (i?.children?.length) {
          _recurseF(i.children)
        } else if (i.metrics) {
          i.metrics.forEach((m: any) => {
            if (
              m.alarmTypeId &&
              m.alarmTypeId !== 'NA' &&
              !i.metrics.some(
                (a: any) =>
                  a.metricName === 'Virtual Metric' && a.alarmTypeId !== 'OK'
              )
            ) {
              alltype[m.alarmTypeId] = {
                color: m.color,
                alarmName: m.alarmName,
                count: alltype[m.alarmTypeId]
                  ? alltype[m.alarmTypeId].count + 1
                  : 1,
              }
            } else if (
              m.metricName === 'Virtual Metric' &&
              m.alarmTypeId !== 'OK'
            ) {
              alltype[m.alarmTypeId] = {
                color: m.color,
                alarmName: m.alarmName,
                count: alltype[m.alarmTypeId]
                  ? alltype[m.alarmTypeId].count + 1
                  : 1,
              }
            }
          })
        }
      })
    }
  }
  _recurseF(tree.children)
  return alltype
}

const filteredAlayrmTypes = (tree: any, stateFilter: string) => {
  const res = cloneDeep(tree)

  const _filterTree = (children: any): any => {
    if (children && children.length) {
      return children.filter((n: any) => {
        if (n.deviceId && n.metrics) {
          if (stateFilter === ALL_ALARM_STATE) {
            return true
          } else
            return n.metrics.some(
              (a: any) =>
                a.alarmTypeId === stateFilter &&
                !n.metrics.some(
                  (a: any) =>
                    a.alarmTypeId !== stateFilter &&
                    a.metricName === 'Virtual Metric' &&
                    a.alarmTypeId !== 'OK'
                )
            )
        } else {
          if (n.children && n.children.length) {
            n.children = _filterTree(n.children)
            if (n.children.length === 0) {
              return false
            }
            return true
          }
          return false
        }
      })
    }
    return children
  }

  _filterTree(res.children)
  return res
}

const AlarmPage: React.FC = () => {
  const defaultstatefilter: any = { label: ALL_ALARM_STATE, color: null }

  const history = useHistory()
  const treeRef = useRef<TreeList>(null)
  const [isCollapsed, setCollapseStatus] = useState(false)

  const [deviceByAlarm, setDeviceByAlarm] = useState<number[] | null>(null)

  const [stateFilter, setStateFilter] = useState(ALL_ALARM_STATE)
  const [filterTypes, setFilterTypes] = useState<any>([])

  const configStore: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: async () => {
          return api.config
            .get({})
            .then((result: any) => {
              const allType = getAlayrmTypes(cloneDeep(result.data))

              const filtredRes = filteredAlayrmTypes(
                cloneDeep(result.data),
                stateFilter
              )

              const _res: any = setDeviceErrorsToTree(cloneDeep(filtredRes))

              let _filterType: any = []
              for (const [key, value] of Object.entries(allType)) {
                const _value: any = value
                _filterType = [
                  ..._filterType,
                  {
                    label: key,
                    color: _value.color,
                    alarmName: _value.alarmName,
                    count: _value.count,
                  },
                ]
              }

              setFilterTypes([defaultstatefilter, ..._filterType])

              return _res
            })
            .catch(storeErrorHandler)
        },
      }),
    // eslint-disable-next-line
    [stateFilter]
  )

  function openAlarmsById() {
    const deviceIds = localStorage.getItem('deviceIds')
    if (deviceIds) {
      localStorage.setItem('deviceIds', '')
      const _deviceIds = JSON.parse(deviceIds)

      setTimeout(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        () => requestAnimationFrame(() => toggleBudge(_deviceIds)),
        100
      )
    }
  }

  function toggleBudge(item: any) {
    setDeviceByAlarm(item)

    const collapseKeys: any[] = []
    treeRef.current?.instance.forEachNode((node: any) => {
      if (!node.data.errorCount) return
      const nodeErrorKeys = Object.keys(node.data.errorCount)
      if (nodeErrorKeys.find(element => item.includes(+element))) {
        collapseKeys.push(node.key)
      }
    })
    treeRef.current?.instance.forEachNode((node: any) => {
      treeRef.current?.instance.collapseRow(node.key)
    })
    collapseKeys.forEach(key => {
      treeRef.current?.instance.expandRow(key)
    })
  }

  const RenderBadge: React.FC = (params: any) => {
    if (params?.data?.errors?.length) {
      const _err = params.data.errors.reduce(
        (res: any, error: any, index: any) => {
          if (res.some((f: any) => f.alarmName === error.alarmName)) {
            return res.map((r: any) => {
              if (r.alarmName === error.alarmName) {
                return {
                  ...r,
                  metricDescription: [
                    ...r.metricDescription,
                    error.metricDescription,
                  ],
                }
              }
              return r
            })
          }
          res.push({
            id: uniqueId(),
            alarmName: error.alarmName,
            color: error.color,
            metricDescription: [error.metricDescription],
          })
          return res
        },
        []
      )

      return (
        <div className={clsx(styles.badgeContainer)}>
          {_err.map((errorElement: any) => {
            return (
              <div
                key={`${errorElement.id}-RenderBadge-key`}
                className={clsx(styles.badgeSpan)}
              >
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Popover id="tooltip-disabled" style={{ padding: 5 }}>
                      <div>
                        <strong>Тривога: </strong>
                        <span>{errorElement.alarmName}</span>
                      </div>
                      <div>
                        <strong>Метрики: </strong>
                        <ul style={{ paddingLeft: 20 }}>
                          {errorElement.metricDescription.map(
                            (c: any, i: any) => (
                              <li key={i}>{c}</li>
                            )
                          )}
                        </ul>
                      </div>
                    </Popover>
                  }
                >
                  <Button
                    onClick={() => toggleBudge([])}
                    variant="light"
                    className={clsx(styles.badgeButton, 'm-0 p-0')}
                  >
                    <Badge
                      style={{
                        backgroundColor: errorElement.color,
                        color:
                          errorElement.metricDescription.length > 1
                            ? isLight(errorElement.color)
                              ? '#000'
                              : '#fff'
                            : errorElement.color,
                      }}
                    >
                      {errorElement.metricDescription.length}
                    </Badge>
                  </Button>
                </OverlayTrigger>
              </div>
            )
          })}
        </div>
      )
    }

    if (params.data.errorCount) {
      const badges: any = Object.values(params.data.errorCount).reduce(
        (result: any, errors: any) => {
          const items = {}
          errors.forEach((err: any) => {
            const errCount = errors?.filter((e: any) => err?.color === e?.color)
            if (err.idAlarmType) {
              Object.assign(items, {
                [err.color]: {
                  alarmId: err.idAlarmType,
                  alarmName: err.alarmName,
                  count:
                    (result[err.color]?.count || 0) + errCount?.length || 1,
                  devicesIds: [
                    ...(result[err.color]?.devicesIds || []),
                    err.deviceId,
                  ],
                },
              })
            }
          })

          return { ...result, ...items }
        },
        {}
      )
      return (
        <div className={clsx(styles.badgeContainer)}>
          {Object.keys(badges as any).map(
            (item: any, index: number, array: any) => {
              return (
                <div
                  key={`${item}-RenderBadge-key`}
                  className={clsx(styles.badgeSpan)}
                >
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Popover id="tooltip-disabled" style={{ padding: 5 }}>
                        <div>
                          <strong>Тривога: </strong>
                          <span>{badges[item].alarmName}</span>
                        </div>
                      </Popover>
                    }
                  >
                    <Button
                      title={badges[item].metricDescription}
                      onClick={() => {
                        toggleBudge(badges[item].devicesIds)
                      }}
                      variant="light"
                      className={clsx(styles.badgeButton, 'm-0 p-0')}
                    >
                      <Badge
                        style={{
                          backgroundColor: item,
                          color: isLight(item) ? '#000' : '#fff',
                        }}
                      >
                        {badges[item].count}
                      </Badge>
                    </Button>
                  </OverlayTrigger>
                </div>
              )
            }
          )}
        </div>
      )
    }
    return null
  }

  const handleClickButton = useCallback(
    (idDevice: any) => {
      if (idDevice) {
        history.push(`/device-state/?device-id=${idDevice}`)
      }
    },
    [history]
  )

  const handleChangeRadioButton = (e: any) => {
    setStateFilter(e.target.id)
  }

  function onRowPrepared(info: any) {
    if (info?.data?.deviceId && deviceByAlarm?.includes(info?.data?.deviceId)) {
      info.rowElement.className = `${info.rowElement.className} ${styles.blink}`

      setTimeout(() => {
        const classNameArray = info.rowElement.className.split(' ')
        info.rowElement.className = classNameArray
          .filter((name: string) => name !== styles.blink)
          .join(' ')
        setDeviceByAlarm(null)
      }, 1000)
    }
  }

  useEffect(() => {
    treeRef.current?.instance.forEachNode((node: any) => {
      treeRef.current?.instance[!isCollapsed ? 'collapseRow' : 'expandRow'](
        node.key
      )
    })
  }, [isCollapsed])

  useEffect(() => {
    if (
      !filterTypes.some((f: any) => f.label === stateFilter) &&
      stateFilter !== ALL_ALARM_STATE
    ) {
      setStateFilter(ALL_ALARM_STATE)
    }
  }, [filterTypes, stateFilter, setStateFilter])

  useEffect(() => {
    configStore.on('loaded', openAlarmsById)
    return () => {
      configStore.off('loaded')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="container">
        <PageTitle title="Тривоги" />

        <div className="row justify-content-end">
          <Button
            onClick={() => setCollapseStatus(!isCollapsed)}
            variant="outline-primary"
            className={styles.collapseButton}
          >
            {!isCollapsed ? 'Розгорнути' : 'Згорнути'}
          </Button>
        </div>
        <Form style={{ marginBottom: 20 }}>
          {filterTypes.map((item: any) => {
            return (
              <Form.Check inline key={item.label}>
                <Form.Check.Input
                  type={'radio'}
                  name="state-type"
                  id={item.label}
                  checked={stateFilter === item.label}
                  style={{ cursor: 'pointer' }}
                  onChange={handleChangeRadioButton}
                />
                <Form.Check.Label htmlFor={item.label}>
                  {item.color ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Popover id="tooltip-disabled" style={{ padding: 5 }}>
                          <div>
                            <strong>Тривога: </strong>
                            <span>{item.alarmName}</span>
                          </div>
                        </Popover>
                      }
                    >
                      <Badge
                        style={{
                          backgroundColor: item.color,
                          color: isLight(item.color) ? '#000' : '#fff',
                          cursor: 'pointer',
                        }}
                      >
                        {item.count}
                      </Badge>
                    </OverlayTrigger>
                  ) : (
                    <span className="badge" style={{ cursor: 'pointer' }}>
                      {item.label}
                    </span>
                  )}
                </Form.Check.Label>
              </Form.Check>
            )
          })}
        </Form>
      </div>

      <TreeList
        ref={treeRef}
        id="configTree"
        dataSource={configStore}
        showRowLines
        showBorders
        columnAutoWidth
        itemsExpr="children"
        dataStructure="tree"
        onRowPrepared={onRowPrepared}
      >
        <Column
          dataField="name"
          caption="Розташування"
          cellTemplate="nameTemplate"
          //width="100%"
        />
        <Column
          width="300"
          cellRender={RenderBadge}
          dataField="error"
          caption="Стан"
        />
        <Template
          name="nameTemplate"
          render={(props: any) => (
            <NameCell handleClickButton={handleClickButton} {...props} />
          )}
        />
      </TreeList>
    </>
  )
}

export default AlarmPage
