import { AxiosInstance } from 'axios'
import CRUD from '../base/crud'

export type TransactionsT = {
  to: string
  from: string
  query: any
  type: any
  sortDate: {
    sortColumn: string
    sortOrder: 'ASC' | 'DESC'
  }
  perPage: any
}

export class TransactionsDevicesCRUD extends CRUD<TransactionsT> {

  getTransactionsTypes() {
    let url = `${this.config.url}/transactions/types`
    return this.r({
      url,
    })
  }

  getUnprocessedTransactionsReport(params: any) {
    let url = `${this.config.url}/transactions/list`

    return this.r({
      method: 'GET',
      url,
      params
    })
  }

  getTransactionsReport(params: any) {
    let url = `reports/transactions`//&sortBy=DESC

    return this.r({
      method: 'GET',
      url,
      params
    })
  }

  getTransactionsReportCsvPdf(params: any) {
    let url = `reports/transactions/generate`

    return this.r({
      method: 'GET',
      url,
      responseType: 'blob',
      params
    })
  }

  getDayliTransactionsReport(params: any) {

    let url = `reports/transactions-daily`

    return this.r({
      method: 'GET',
      url,
      params,
      responseType: params.reportType ? 'blob' : 'json',
    })
  }

  // getUnprocessedTransactionsReportCsv(params: any) {
  //   let url = `${this.config.url}/transactions/csv-report`

  //   return this.r({
  //     method: 'GET',
  //     url,
  //     params,
  //     responseType: 'blob',
  //   })
  // }

  getTransactionsProcessingReport(params: any) {
    let url = `${this.config.url}/transactions/processing`

    return this.r({
      method: 'GET',
      url,
      params
    })
  }
}

export default function transactionsdeviceCRUD(
  request: AxiosInstance
): TransactionsDevicesCRUD {
  return new TransactionsDevicesCRUD({
    url: `/device`,
    request,
    id: 'id',
  })
}
