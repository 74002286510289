import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import { useSelector, useDispatch } from 'react-redux'
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid'
import { Template } from 'devextreme-react/core/template'
import { uniqueId } from 'lodash'
import Spinner from 'react-bootstrap/Spinner'

import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import styles from './MonthlyTransportationReport.module.scss'

import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'
import { getMonthlyTransactionReportXls } from '../../store/sagas/transaction-report'

const date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth()
const firstDay = new Date(y, m, 1)
const lastDay = new Date(y, m + 1, 0)

function MonthlyTransportationReport() {
  const dispatch = useDispatch()
  const isFetchingCsv = useSelector(
    (store: any) => store.transactionState.isFetchingCsv
  )

  const dataGridRef = useRef<DataGrid>(null)
  const [period, setPeriod] = useState({
    from: firstDay.setHours(0, 0, 0, 0),
    to: lastDay.setHours(23, 59, 59, 999),
  })

  const monthlyTransportation: any = useMemo(() => {
    return new CustomStore({
      key: 'id',
      load: async () => {
        const res = await api.reports
          .getMonthlyTransportationReport({ ...period })
          .then((result: any) => result.data)
          .catch(storeErrorHandler)
        const _mappedRes = res?.map((item: any) => {
          return {
            ...item,
            id: uniqueId(),
            count:
              Number(item.bcard ?? 0) +
              Number(item.kcard ?? 0) +
              Number(item.qr ?? 0) +
              Number(item.scard ?? 0),
          }
        })

        if (_mappedRes.length) {
          const sumRow = _mappedRes?.reduce((r: any, item: any) => {
            r.repdate = 'Разом'
            r.id = uniqueId()
            r.bcard = !r.bcard
              ? Number(item.bcard ?? 0)
              : Number(item.bcard ?? 0) + Number(r.bcard ?? 0)

            r.kcard = !r.kcard
              ? Number(item.kcard ?? 0)
              : Number(item.kcard ?? 0) + Number(r.kcard ?? 0)

            r.qr = !r.qr
              ? Number(item.qr ?? 0)
              : Number(item.qr ?? 0) + Number(r.qr ?? 0)

            r.scard = !r.scard
              ? Number(item.scard ?? 0)
              : Number(item.scard ?? 0) + Number(r.scard ?? 0)

            r.count = !r.count
              ? Number(item.count ?? 0)
              : Number(item.count ?? 0) + Number(r.count ?? 0)

            return r
          }, {})
          _mappedRes.push(sumRow)
        }
        return _mappedRes
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  function onChangePeriod(values: { from: number; to: number }) {
    setPeriod({
      from: values.from,
      to: values.to,
    })
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const downloadReport = () => {
    dispatch(
      getMonthlyTransactionReportXls({
        ...period,
        type: 'xls',
      })
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: updateData,
        },
      }
    )
  }

  useEffect(() => {
    updateData()
  }, [period])

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column mb-4">
        <PageTitle title="Звіт з перевезень за місяць" className="mb-4" />
        <ForPeriodTitleSection
          from={period.from}
          to={period.to}
          isUpdateButton={false}
        />
        <Filter
          from={period.from}
          to={period.to}
          onChangePeriod={onChangePeriod}
        />
      </div>
      {period.from && period.to ? (
        <DataGrid
          ref={dataGridRef}
          dataSource={monthlyTransportation}
          showBorders
          allowColumnReordering={true}
          className={styles.transactionTableContainer}
          remoteOperations={false}
          loadPanel={{
            enabled: true,
          }}
          width="100%"
          onToolbarPreparing={onToolbarPreparing}
        >
          <Paging defaultPageSize={100} />
          <Column
            dataField="repdate"
            caption="Дата"
            dataType="date"
            format="dd/MM/yyyy"
            width={150}
          />
          <Column dataField="kcard" caption="Картка Киянина" width={'auto'} />
          <Column
            dataField="bcard"
            caption="Банківська картка"
            width={'auto'}
          />
          <Column dataField="scard" caption="Смарт карта" width={'auto'} />
          <Column dataField="qr" caption="QR" width={'auto'} />
          <Column dataField="count" caption="Всього" width={'auto'} />
          <Template name="spinner" render={spinnerComponent} />
        </DataGrid>
      ) : (
        <div>
          <span>Необхідно вибрати дату</span>
        </div>
      )}
    </div>
  )
}

export default memo(MonthlyTransportationReport)
