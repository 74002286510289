
import {SET_IS_FETCHING_CSV_REPORT} from '../../sagas/transaction-report'

export const transactionState = (state: any = {isFetchingCsv: false}, actions: {
  type: string,
  payload: any
}) => {
  switch (actions.type) {
    case SET_IS_FETCHING_CSV_REPORT:
      
      return {...state, isFetchingCsv: actions.payload};
  
    default:
      return state;
  }
}
