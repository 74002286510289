import { ConfigT } from '../api/modules/config';

interface NewTree extends ConfigT {
  index?: number;
}

export function removeDevicesFromTree(tree: ConfigT): any {
  const newTree: NewTree = JSON.parse(JSON.stringify(tree));
  newTree.index = 0;
  const brunches = [];
  brunches.push(newTree);

  while (brunches.length) {
    const lastBranch: any = brunches[brunches.length - 1];
    const childLength = lastBranch.children.length;

    if (lastBranch.children.some((ch: any) => ch.deviceId)) {
      lastBranch.children = lastBranch.children.filter((ch: any) => !ch.deviceId);
    }

    if (!lastBranch || !lastBranch.children.length || lastBranch.index === childLength) {
      brunches.pop();
      continue;
    }

    const newBranch = lastBranch.children[lastBranch.index];
    newBranch.index = 0;
    brunches.push(newBranch);
    lastBranch.index += 1;
  }

  return newTree;
}

export function findDeviceParentId(tree: ConfigT, deviceId: number) {
  const newTree: NewTree = { ...tree };
  newTree.index = 0;
  const brunches = [];
  brunches.push(newTree);

  let parentId;

  while (brunches.length) {
    const lastBranch: any = brunches[brunches.length - 1];
    const childLength = lastBranch.children.length;

    if (lastBranch.deviceId === deviceId) {
      parentId = lastBranch.parentId;
      break;
    }

    if (!lastBranch || !lastBranch.children.length || lastBranch.index === childLength) {
      brunches.pop();
      continue;
    }

    const newBranch = lastBranch.children[lastBranch.index];
    newBranch.index = 0;
    brunches.push(newBranch);
    lastBranch.index += 1;
  }

  return parentId;
}

export function findParameterById(tree: ConfigT, id: number) {
  const newTree: NewTree = { ...tree };
  newTree.index = 0;
  const brunches = [];
  brunches.push(newTree);

  let parameter;

  while (brunches.length) {
    const lastBranch: any = brunches[brunches.length - 1];
    const childLength = lastBranch.children.length;

    if (lastBranch.id === id) {
      parameter = lastBranch;
      break;
    }

    if (!lastBranch || !lastBranch.children.length || lastBranch.index === childLength) {
      brunches.pop();
      continue;
    }

    const newBranch = lastBranch.children[lastBranch.index];
    newBranch.index = 0;
    brunches.push(newBranch);
    lastBranch.index += 1;
  }

  return parameter;
}

export function removeAlarmType(tree: ConfigT, id: number) {
  const newTree: NewTree = { ...tree };
  newTree.index = 0;
  const brunches = [];
  brunches.push(newTree);

  let parameter;

  while (brunches.length) {
    const lastBranch: any = brunches[brunches.length - 1];
    const childLength = lastBranch.children.length;

    if (lastBranch.id === id) {
      parameter = lastBranch;
      break;
    }

    if (!lastBranch || !lastBranch.children.length || lastBranch.index === childLength) {
      brunches.pop();
      continue;
    }

    const newBranch = lastBranch.children[lastBranch.index];
    newBranch.index = 0;
    brunches.push(newBranch);
    lastBranch.index += 1;
  }

  return parameter;
}
