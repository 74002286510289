import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import user from './user';
import {transactionState} from './transactions'
import {alarmStore} from './alarm'
import auth, { LOGOUT_SUCCESS, ActionTypeT } from './auth/auth';

const createAppReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user,
    transactionState,
    alarmStore
  });

type AppReducerT = ReturnType<typeof createAppReducer>;
export type AppState = ReturnType<AppReducerT>;

const initialState: AppState = {} as AppState;

let appReducer: AppReducerT;

export default function(history: History) {
  if (!appReducer) {
    appReducer = createAppReducer(history);
  }

  return function(state: AppState = initialState, action: ActionTypeT): AppState {
    if (action.type === LOGOUT_SUCCESS) {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };
}
