import { AxiosInstance } from 'axios';
import CRUD from '../base/crud';
import { HardwareT } from './hardware';
import { DeviceT } from './device';

export type FirmwareGroupT = {
  id: number;
  name: string;
  hardwareId: number;
  hardware: HardwareT;
  devices: DeviceT[];
};

export class FirmwareGroupCRUD extends CRUD<FirmwareGroupT> {}

export default function firmwareGroupCrud(request: AxiosInstance): FirmwareGroupCRUD {
  const crud = new FirmwareGroupCRUD({
    url: '/fw-groups',
    request,
    id: 'id',
  });

  return crud;
}
