import React, { useMemo } from 'react'
import cx from 'clsx'
import CustomStore from 'devextreme/data/custom_store'

import DropDownDevicesTree from '../dropDownDevicesTree/DropDownDevicesTree'
import CalendarPicker from '../../../../components/CalendarPicker/CalendarPicker'
import { methodConstant } from '../../constant'
import styles from './Filter.module.scss'
import { storeErrorHandler } from '../../../../helpers/errorHandling'
import api from '../../../../api'
interface FilterInterface {
  onChangePeriod: (key: string, value: any) => void
  from: any
  to: any
  handleChangeDateType?: (data: any) => void
  changeSelectedMethod: (e: any) => void
  selectedMethod: string[]
  changeSelectedEntity: (e: any) => void
  selectedEntity: string[]
}

const Filter = ({
  onChangePeriod,
  from,
  to,
  handleChangeDateType,
  changeSelectedMethod,
  selectedMethod,
  selectedEntity,
  changeSelectedEntity,
}: FilterInterface) => {
  const entities: any = useMemo(() => {
    return new CustomStore({
      key: 'id',
      load: () => {
        return api.audit
          .getSettingAuditEntities()
          .then(result => {
            // @ts-ignore
            const { data } = result
            const _data = data.map(i => ({ entity: i, ID: i }))
            return _data
          })
          .catch(storeErrorHandler)
      },
    })
  }, [])

  return (
    <div className="d-flex flex-row align-items-end mb-4">
      <div className="d-flex flex-column">
        <CalendarPicker
          to={to}
          from={from}
          onChangePeriod={onChangePeriod}
          withFilter={true}
          handleChangeDateType={handleChangeDateType}
          initialTimeFrom={[0, 0, 0, 0]}
          initialTimeTo={[23, 59, 59, 999]}
          notNextDay={true}
        />
      </div>
      <div
        className={cx(
          'column',
          styles.filterItem,
          styles.dropDown,
          'flex-row',
          'justify-content-end'
        )}
      >
        <div className={cx('column', styles.filterItem)}>
          <div className="font-weight-bold">Метод</div>
          <DropDownDevicesTree
            selectedValues={selectedMethod}
            changeSelected={changeSelectedMethod}
            placeholder="виберіть методи..."
            nameKey={'method'}
            dataSource={methodConstant}
          />
        </div>
        <div className={cx('column', styles.filterItem)}>
          <div className="font-weight-bold">Категорія</div>
          <DropDownDevicesTree
            selectedValues={selectedEntity}
            changeSelected={changeSelectedEntity}
            placeholder="виберіть Категорію..."
            nameKey={'entity'}
            dataSource={entities}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
