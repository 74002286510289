import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import PageTitle from '../../components/PageTitle/PageTitle'
import styles from '../DevicesPage/DevicesPage.module.scss'

import Table from './components/Table'
import { DeviceIdsT, PlaceT } from '../../components/DeviceFilter/DeviceFilter'

const DevicesPage: React.FC = () => {
  const [isCollapsed, setCollapseStatus] = useState(false)
  const [count, setCount] = useState(0)

  const [place] = useState<PlaceT>({
    name: '',
  })
  const [deviceIds] = useState<DeviceIdsT>(undefined)

  function updateData() {
    setCount(count + 1)
  }

  useEffect(updateData, [place])

  return (
    <>
      <div className="container">
        <div className="row justify-content-between mb-4">
          <PageTitle title="Пристрої" />

          <div className="column justify-content-end mt-3">
            <div className="container">
              <Button
                onClick={() => setCollapseStatus(!isCollapsed)}
                variant="outline-primary"
                className={styles.downloadButton}
              >
                {!isCollapsed ? 'Розгорнути' : 'Згорнути'}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Table
        isCollapsed={isCollapsed}
        count={count}
        deviceIds={deviceIds}
        isShowAddButtonInToolbox
      />
    </>
  )
}

export default DevicesPage
