import React, { useRef } from 'react'
import { DropDownBox } from 'devextreme-react'
import TreeView from 'devextreme-react/tree-view'

interface DropDownDevicesTreeI {
  selectedValues: string[]
  changeSelected: (e: any) => void
  placeholder: string
  nameKey: string
  dataSource: any
}

const DropDownDevicesTree = ({
  selectedValues,
  changeSelected,
  placeholder,
  nameKey,
  dataSource,
}: DropDownDevicesTreeI) => {
  const dropDownRef = useRef<DropDownBox>(null)
  const treeViewRef = useRef<TreeView>(null)

  const _changeSelectedMethod = e => {
    const { itemData } = e
    if (itemData && itemData?.selected) {
      changeSelected(prev => [...prev, itemData.ID])
    }
    if (itemData && !itemData.selected) {
      changeSelected(prev => prev.filter(f => f !== itemData.ID))
    }
    if (!itemData) {
      changeSelected([])
      if (treeViewRef?.current) {
        treeViewRef.current.instance.unselectAll()
      }
    }
  }

  const handleContentReady = () => {
    if (treeViewRef.current) {
      //treeViewRef.current.instance.selectAll()
    }
  }

  return (
    <DropDownBox
      ref={dropDownRef}
      value={selectedValues}
      valueExpr="ID"
      displayExpr={nameKey}
      placeholder={placeholder}
      showClearButton
      width={250}
      onValueChanged={_changeSelectedMethod}
      contentRender={() => (
        <TreeView
          dataSource={dataSource}
          ref={treeViewRef}
          dataStructure="plain"
          keyExpr="ID"
          selectionMode="multiple"
          showCheckBoxesMode="normal"
          selectNodesRecursive={false}
          displayExpr={nameKey}
          selectByClick={true}
          onItemSelectionChanged={_changeSelectedMethod}
          onContentReady={handleContentReady}
        />
      )}
    />
  )
}

export default DropDownDevicesTree
