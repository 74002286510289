import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, { Column, Paging, Scrolling } from 'devextreme-react/data-grid'
import { Template } from 'devextreme-react/core/template'
import PageTitle from '../../components/PageTitle/PageTitle'
import Filter from './components/filter/Filter'
import ForPeriodTitleSection from '../../components/ForPeriodTitleSection/ForPeriodTitleSection'
import styles from './NotificationReportsPage.module.scss'

import api from '../../api'
import { storeErrorHandler } from '../../helpers/errorHandling'
import { getAlarmReportAction } from '../../store/sagas/alarm'

interface FilterParamsT {
  id?: number
  parentId?: number
  name?: string | number
  deviceId?: number
  from: number | undefined
  to: number | undefined
  nodeId?: number | undefined
}
function filterParams(params: FilterParamsT) {
  return Object.fromEntries(Object.entries(params).filter(value => value[1]))
}

const toTimeStamp = (date: any) => new Date(date).getTime()

export type SelectedPlaceT = {
  name?: string | number
  deviceId?: string | number
  id?: number
}

const NotificationReportsPage: React.FC = () => {
  const dataGridRef = useRef<DataGrid>(null)
  const location: any = useLocation()
  const query = new URLSearchParams(location.search)
  const _deviceId = query.get('device-id')
  const _deviceName = query.get('device-name')
  const dispatch = useDispatch()
  const isFetchingCsv = useSelector(
    (store: any) => store.alarmStore.isFetchingReport
  )

  const [place, setPlace] = useState<SelectedPlaceT>({})
  const [period, setPeriod] = useState({
    from: new Date().setHours(0, 0, 0, 0),
    to: new Date().getTime(),
  })

  const alarmData: any = useMemo(
    () =>
      new CustomStore({
        key: 'id',
        load: () => {
          return api.alarm
            .get(filterParams({ nodeId: place.id, ...period }))
            .then(result => {
              // @ts-ignore
              const parsedData = result.data.map((alarm: any) => {
                // @ts-ignore
                const {
                  //alarmType,
                  lobby,
                  station,
                  line,
                  metro,
                  ...restData
                } = alarm

                return restData
              })

              return parsedData
            })
            .catch(storeErrorHandler)
        },
      }),
    [place, period]
  )

  function onChangePeriod(key: string, value: string | number) {
    setPeriod({
      ...period,
      [key]: toTimeStamp(value),
    })
  }

  function updateData() {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const downloadReport = () => {
    dispatch(
      getAlarmReportAction({
        nodeId: place.id,
        ...period,
        type: 'xls',
      })
    )
  }

  const spinnerComponent = () => {
    if (isFetchingCsv) return <Spinner animation="border" variant="primary" />
    return null
  }

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'spinner',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          onClick: downloadReport,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: updateData,
        },
      }
    )
  }

  useEffect(updateData, [period, place])

  useLayoutEffect(() => {
    if (_deviceId) {
      const nowDay = new Date()

      const to = nowDay.getTime()
      let from = new Date(to).setHours(3, 0, 0, 0)
      if (to < from) {
        from = new Date(from).setDate(new Date(from).getDate() - 1)
      }
      setPlace({ id: Number(_deviceId), name: _deviceName })
      setPeriod({
        from: new Date(from).getTime(),
        to: to,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (place.id) {
      query.set('device-id', String(place?.id))
      query.set('device-name', String(place?.name))
      window.history.replaceState(null, '', '?' + query.toString())
    }
    if (!place.id) {
      window.history.replaceState(null, '', 'notifications')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place])

  return (
    <div className="mb-4">
      <div className="container">
        <div className="row justify-content-between mb-4">
          <div className="d-flex flex-column w-100">
            <PageTitle title="Технологічні повідомлення" />
            <ForPeriodTitleSection
              from={period.from}
              to={period.to}
              isUpdateButton={false}
            />
          </div>
          <div className="mt-3 w-100">
            <div className="container">
              <Filter
                from={period.from}
                to={period.to}
                onChangePeriod={onChangePeriod}
                setSelectedPlace={setPlace}
                selectedPlace={place.name || ''}
              />
            </div>
          </div>
        </div>
      </div>

      {place.id && period.from && period.to ? (
        <DataGrid
          ref={dataGridRef}
          dataSource={alarmData}
          showBorders
          onToolbarPreparing={onToolbarPreparing}
        >
          <Scrolling mode="virtual" />
          <Paging pageSize="100" />
          <Column
            dataField="createAt"
            caption="Дата"
            dataType="date"
            format="dd/MM/yyyy HH:mm:ss"
            width={'auto'}
          />

          <Column dataField="deviceName" caption="Пристрій" width={'auto'} />
          <Column
            dataField={'metricName'}
            caption="Тривога"
            cellRender={RenderMetricCell}
          />
          <Column
            dataField="oldValue"
            caption="Попередній статус"
            width={'auto'}
          />

          <Column dataField="newValue" caption="Новий статус" width={'auto'} />
          <Column
            dataField="metricCurrentValue"
            caption="Поточне значення"
            width={'auto'}
          />
          <Template name="spinner" render={spinnerComponent} />
        </DataGrid>
      ) : (
        <div>
          <span>Необхідно вибрати пристрій, початкову та кінцеву дату</span>
        </div>
      )}
    </div>
  )
}

const RenderMetricCell = ({ data }: any) => {
  const { metricDescription, metricName } = data || {}
  return (
    <span className={styles.renderMetricCell}>
      {metricDescription || metricName}
    </span>
  )
}

export default NotificationReportsPage
