import React, { useRef, useState } from 'react';
import DataGrid, { Column, Editing, RequiredRule } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { CustomRule } from 'devextreme-react/validator';
import { Button, TextBox } from 'devextreme-react';
import axios from 'axios';
import { publicKeyCredentialToJSON, preformatMakeCredReq } from '../../helpers/webauthn';
import appConfig from '../../config';
import UsersList from './UsersList';
import styles from './UsersKeys.module.scss';

import api from '../../api';

import { validateUserId, validationKeyName } from '../../helpers/validation';
import { storeErrorHandler } from '../../helpers/errorHandling';
import { localizedValidationErrors } from '../../constants/errorsLocalization';
import EditTextInput from '../../components/EditTextInput/EditTextInput';

const RenderUserColumn = (cellOption: any) => {
  if (cellOption.data?.user?.id && cellOption.data?.user?.username) {
    return (
      <p>
        id: {cellOption.data.user.id}, username: {cellOption.data.user.username}
      </p>
    );
  }
  return null;
};

const UsersKeysPage: React.FC = () => {
  const [keyCredential, setKeyCredential] = useState<any>(null);
  const [user, setUser] = useState<any>({});
  const dataGridRef = useRef<DataGrid>(null);

  const customStore: any = new CustomStore({
    key: 'id',
    load: () =>
      api.usersKeys
        .usersKeys()
        .then((result: any) => result.data)
        .catch(storeErrorHandler),
    remove: id => api.key.delete(id).catch(storeErrorHandler),
    update: (id, data) => api.key.update({ id, ...data }).catch(storeErrorHandler),
    insert: data => {
      const cloneData = { ...data };
      cloneData.credentialID = keyCredential.credID;
      return api.key.create(cloneData).catch(storeErrorHandler);
    },
  });

  function onEditingStart(component: any): void {
    if (component.data?.user) {
      setUser(component.data.user);
    }
  }

  const renderAddKeyButton = (cellOptions: any) => {
    return (
      <div>
        <TextBox className={styles.customInput} disabled value={cellOptions.data.value} />
        <Button
          disabled={user.id === undefined}
          width={180}
          text="Пiдключити ключ"
          type="normal"
          stylingMode="contained"
          onClick={async () => {
            try {
              const token = localStorage.getItem('accessToken');
              const response = await axios.post(
                `${appConfig.baseUrl}/user/webauthn/create`,
                { userId: user.id },
                {
                  headers: {
                    'x-access-token': token,
                  },
                },
              );
              if (!response.data) {
                throw Error('Invalid backend error');
              }

              const publicKey = preformatMakeCredReq(response.data);
              const credentials = (await navigator.credentials.create({ publicKey })) as any;
              const json = publicKeyCredentialToJSON(credentials);
              const result = await axios.post(`${appConfig.baseUrl}/user/key/generate`, json, {
                headers: {
                  'x-access-token': token,
                },
              });
              setKeyCredential(result.data.authrInfo);
              cellOptions.data.setValue(result.data.authrInfo.publicKey);
            } catch (error) {
              console.log(error);
            }
          }}
        />
      </div>
    );
  };

  return (
    <DataGrid
      ref={dataGridRef}
      id="key"
      onEditingStart={onEditingStart}
      showBorders
      dataSource={customStore}
    >
      <Editing refreshMode="full" mode="form" allowUpdating allowDeleting allowAdding useIcons />
      <Column
        dataField="keyName"
        editCellRender={cellOption => (
          <EditTextInput cellOption={cellOption} textBoxParams={{ maxLength: 255 }} />
        )}
      >
        <RequiredRule />
        <CustomRule
          validationCallback={validationKeyName}
          message={localizedValidationErrors.keyName}
        />
      </Column>
      <Column
        dataField="userId"
        cellRender={RenderUserColumn}
        editCellComponent={cellOptions => (
          <UsersList cellOptions={cellOptions} user={user} setUser={setUser} />
        )}
      >
        <CustomRule
          validationCallback={() => validateUserId(user)}
          message={localizedValidationErrors.userId}
        />
      </Column>
      <Column dataField="createdAt" allowEditing={false} />
      <Column
        dataField="publicKey"
        editCellComponent={cellOptions => renderAddKeyButton(cellOptions)}
      >
        <RequiredRule />
      </Column>
    </DataGrid>
  );
};

export default UsersKeysPage;
