/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useState, useRef, useEffect } from 'react'
import {
  Column,
  Editing,
  TreeList,
  Form,
  Lookup,
} from 'devextreme-react/tree-list'
import { CheckBox } from 'devextreme-react/ui/check-box'
import { Item, GroupItem } from 'devextreme-react/form'
import CustomStore from 'devextreme/data/custom_store'
import { CustomRule } from 'devextreme-react/validator'
import { RequiredRule } from 'devextreme-react/data-grid'
import { orderBy, cloneDeep } from 'lodash'

import FileManager from './FileManager/FileManager'
import PageTitle from '../../components/PageTitle/PageTitle'
import { storeErrorHandler } from '../../helpers/errorHandling'
import api from '../../api'

import './FirmwarePage.module.scss'
import { localizedValidationErrors } from '../../constants/errorsLocalization'
import { validateGroupName } from '../../helpers/validation'
import EditTextInput from '../../components/EditTextInput/EditTextInput'

function EditNameCellTemplate(options: any) {
  const maxLength = options.data.parentId === 0 ? 30 : 45
  return <EditTextInput cellOption={options} textBoxParams={{ maxLength }} />
}

function cellTemplate(container: any, options: any): void {
  if (options.value) {
    const text = options.value.length
    container.textContent = text || ''
    container.title = text
  }
}

const RenderId: React.FC = (data: any) => {
  switch (data.data.type) {
    case 'software':
      return <span>Software id: {data.data.firmwareId}</span>
    case 'hardware':
      return <span>Hardware id: {data.data.hardwareId}</span>
    case 'group':
      return <span>{`Group id: ${data.data.firmwareGroupId}`}</span>
    default:
      return <span />
  }
}

const RenderName: React.FC = (data: any) => {
  switch (data.data.type) {
    case 'software':
      return <span>{data.data.name}</span>
    case 'hardware':
      return <span>{data.data.name}</span>
    case 'group':
      return <span>{data.data.name}</span>
    default:
      return <span />
  }
}

function extendUpdatingData(options: any): void {
  options.newData = { ...options.oldData, ...options.newData }
}

// id: 18
// key: "18-software"
// name: "турникет проход стандарт"
// parentId: 37
// type: "software"
// versionMajor: 1
// versionMinor: 0
// versionPatch: 160

function getTree(firmware: any, firmwareGroup: any, hardware: any) {
  const groupsWithSoftware = firmwareGroup.map((item: any) => {
    item.type = 'group'
    item.key = `${item.id}-group`
    item.parentId = item.hardware.id
    firmware.map((software: any) => {
      software.type = 'software'
      software.key = `${software.id}-software`
      software.parentId = software.group.id
      item.parentId = software.group.id
      if (item.id === software.group.id) {
        item.children = item.children
          ? [...item.children, software]
          : [software]
      }
    })
    const _children = cloneDeep(item.children) || []

    item.children = orderBy(
      _children,
      ['versionMajor', 'versionMinor', 'versionPatch'],
      ['desc', 'desc', 'desc']
    )
    return item
  })

  const tree = hardware.map((item: any) => {
    delete item.groups
    item.key = `${item.id}-hardware`
    item.type = 'hardware'
    groupsWithSoftware.map((group: any) => {
      if (group.hardware.id === item.id) {
        item.children = item.children ? [...item.children, group] : [group]
      }
    })
    return item
  })
  return tree
}

let firmware: any
let firmwareGroup: any
let hardware: any

const uploadFileChunk = (firmwareId: number, file: any) => {
  const formData = new FormData()
  formData.append('file', file.fileData)
  formData.append('name', file.fileData.name)
  formData.append('executable', file.executable)
  formData.append('firmwareId', `${firmwareId}`)
  return api.fwFile.create(formData).catch(storeErrorHandler)
}

interface LocalFilesData {
  create: any[]
  update: any[]
  delete: number[]
}

async function getAll() {
  const batch = [
    api.firmware
      .get({})
      .then((result: any) => result.data)
      .catch(storeErrorHandler),
    api.firmwareGroup
      .get({})
      .then(result => result.data)
      .catch(storeErrorHandler),
    api.hardware
      .get({})
      .then(result => result.data)
      .catch(storeErrorHandler),
  ]
  ;[firmware, firmwareGroup, hardware] = await Promise.all(batch)
  const result = getTree(firmware, firmwareGroup, hardware)
  return result
}

const create = (params: any, localFiles: any[]): Promise<any> => {
  if (params.parentId === 0) {
    params.details = {}
    return api.hardware.create(params).catch(storeErrorHandler)
  }
  if (
    typeof params.parentId === 'string' &&
    params.parentId.split('-')[1] === 'hardware'
  ) {
    params.name = `${params.name || ''}`
    params.hardwareId = +params.parentId.split('-')[0]
    return api.firmwareGroup.create(params).catch(storeErrorHandler)
  }
  params.groupId = +params.parentId.split('-')[0]

  return api.firmware
    .create(params)
    .then((response: any) => {
      if (localFiles.length) {
        localFiles.forEach(async file => {
          await uploadFileChunk(response.data.id, file)
        })

        return api.firmware
          .get({ id: response.data.id })
          .catch(storeErrorHandler)
      }
      return response
    })
    .catch(storeErrorHandler)
}

const removeFile = (file: any) => {
  return api.fwFile.delete(file).catch(storeErrorHandler)
}

const update = (id: any, params: any, data: LocalFilesData): any => {
  const softwareId = params.id
  switch (params.type) {
    case 'software':
      // eslint-disable-next-line no-case-declarations
      if (data.create.length) {
        data.create.forEach(async file => {
          await uploadFileChunk(softwareId, file)
        })
      }
      if (data.update.length) {
        data.update.forEach(async file => {
          await api.fwFile.update(file).catch(storeErrorHandler)
        })
      }
      if (data.delete.length) {
        data.delete.forEach(async fileId => {
          await removeFile(fileId)
        })
      }
      // eslint-disable-next-line no-case-declarations
      const { files, ...allData } = params
      return api.firmware
        .update({ id: softwareId, ...allData })
        .catch(storeErrorHandler)
    case 'hardware':
      params.details = {}
      return api.hardware.update({ id, ...params }).catch(storeErrorHandler)
    default:
      return api.firmwareGroup
        .update({ id, ...params })
        .catch(storeErrorHandler)
  }
}

function remove(id: any) {
  switch (id.split('-')[1]) {
    case 'hardware':
      return api.hardware.delete(id.split('-')[0]).catch(storeErrorHandler)
    case 'software':
      return api.firmware.delete(id.split('-')[0]).catch(storeErrorHandler)
    default:
      return api.firmwareGroup.delete(id.split('-')[0]).catch(storeErrorHandler)
  }
}

function allowAdding(e: any) {
  if (e.row.node.data.type === 'software') {
    return false
  }
  return true
}

const renderCheckbox: React.FC = (data: any) => {
  if (typeof data.value === 'boolean') {
    return <CheckBox value={data.value} disabled />
  }
  return null
}

function VersionCellTemplate(options: any) {
  return <EditTextInput cellOption={options} textBoxParams={{ maxLength: 6 }} />
}

function FWGroupIdCellTemplate(options: any) {
  return (
    <EditTextInput cellOption={options} textBoxParams={{ maxLength: 20 }} />
  )
}

const FirmwarePage: React.FC = (props: any) => {
  const localFilesData = useRef<LocalFilesData>({
    create: [],
    update: [],
    delete: [],
  })
  const [isCreate, setCreateStatus] = useState<boolean>(false)

  const [filesHasUpdated, setUpdateFilesStatus] = useState<boolean>(false)

  const [showFiles, setShowFiles] = useState(false)
  const [showVersion, setShowVersion] = useState(true)
  const [showId, setShowId] = useState(false)

  const refTree = useRef(null)

  const firmwareData: any = useRef(
    new CustomStore({
      key: 'key',
      load: getAll,
      remove,
      update: (id, params) => {
        return update(id, params, localFilesData.current as any).finally(() => {
          if (localFilesData.current?.update.length) {
            localFilesData.current.create = []
            localFilesData.current.delete = []
            localFilesData.current.update = []
          }
        })
      },
      insert: params =>
        create(params, localFilesData.current?.create).finally(() => {
          if (localFilesData.current?.create.length) {
            localFilesData.current.create = []
          }
        }),
    })
  ).current

  function treeEditing(e: any) {
    if (isCreate) setCreateStatus(false)
    setShowId(e.data.parentId === 0)
    setShowVersion(e.data.parentId === 0 && e.data.type !== 'hardware')
    setShowFiles(e.data.parentId !== 0 && e.data.type === 'software')
    if (typeof e.data.parentId === 'string') {
      setShowVersion(e.data.parentId.includes('group'))
    }
  }

  function treeAdding(e: any) {
    if (!isCreate) setCreateStatus(true)
    setShowId(e.data.parentId === 0)
    setShowFiles(
      e.data.parentId !== 0 && e.data.parentId.split('-')[1] === 'group'
    )
    if (typeof e.data.parentId === 'string') {
      setShowVersion(e.data.parentId.split('-')[1] === 'group')
    } else {
      setShowVersion(false)
    }
  }

  const treeListRef = useRef<TreeList>(null)
  const [prevDataLength, setDataLength] = useState(0)

  function updateCounter() {
    if (treeListRef.current) {
      if (treeListRef.current.props) {
        const dataLength = React.Children.toArray(
          treeListRef.current.props.children
        ).length
        if (prevDataLength !== dataLength) {
          setDataLength(dataLength)
        }
      }
    }
  }

  useEffect(() => {
    firmwareData.on('modified', updateCounter)
    firmwareData.on('loaded', updateCounter)

    return () => {
      firmwareData.off('modified')
      firmwareData.off('loaded')
    }
    // eslint-disable-next-line
  }, [props])

  function updateData() {
    if (treeListRef.current) {
      if (treeListRef.current.props) {
        if (
          prevDataLength ===
          React.Children.toArray(treeListRef.current.props.children).length
        ) {
          if (!isCreate && filesHasUpdated) {
            treeListRef.current.instance
              .refresh()
              .then(() => setUpdateFilesStatus(false))
          }
        }
      }
    }
  }

  return (
    <>
      <PageTitle title="Прошивки" />

      <TreeList
        ref={treeListRef}
        id="firmwareTree"
        dataStructure="tree"
        dataSource={firmwareData}
        itemsExpr="children"
        keyExpr="key"
        showRowLines
        showBorders
        columnAutoWidth
        onRowUpdating={extendUpdatingData}
        onEditingStart={treeEditing}
        onInitNewRow={treeAdding}
      >
        <Editing
          refreshMode="full"
          mode="form"
          allowAdding={allowAdding}
          allowDeleting
          allowUpdating
          useIcons
        >
          <Form activeStateEnabled focusStateEnabled ref={refTree} colCount="1">
            <GroupItem colCount="2">
              <Item dataField="name" />
              <Item visible={showVersion} dataField="versionMajor" />
              <Item visible={showVersion} dataField="versionMinor" />
              <Item visible={showId} dataField="hardwareId" />
              <Item
                visible={!showId && !showVersion}
                dataField="firmwareGroupId"
              />
            </GroupItem>
            <GroupItem colCount="2">
              <Item visible={showVersion} dataField="versionPatch" />
              <Item
                dataField="enabled"
                editorType="dxCheckBox"
                visible={showFiles}
              />
            </GroupItem>
            <Item dataField="files" visible={showFiles} />
          </Form>
        </Editing>
        <Column
          cellRender={RenderId}
          dataField="softwareId"
          caption="ID"
          allowEditing={false}
          showEditorAlways={false}
        />
        <Column
          editCellRender={EditNameCellTemplate}
          cellRender={RenderName}
          dataField="name"
          caption="Назва"
        >
          <RequiredRule />
          <CustomRule
            validationCallback={validateGroupName}
            message={localizedValidationErrors.groupName}
          />
        </Column>
        <Column
          editCellRender={VersionCellTemplate}
          dataField="versionMajor"
          caption="Мажорна версія"
        >
          <RequiredRule />
        </Column>
        <Column
          editCellRender={VersionCellTemplate}
          dataField="versionMinor"
          caption="Мінорна версія"
        >
          <RequiredRule />
        </Column>
        <Column
          editCellRender={VersionCellTemplate}
          dataField="versionPatch"
          caption="Патч версія"
        >
          <RequiredRule />
        </Column>
        <Column
          cellRender={renderCheckbox}
          dataField="enabled"
          caption="Підключена"
        />
        <Column
          dataField="files"
          editCellComponent={cellProps => (
            <FileManager
              {...cellProps}
              updateData={updateData}
              isCreate={isCreate}
              localFilesData={localFilesData}
              setUpdateFilesStatus={setUpdateFilesStatus}
            />
          )}
          caption="Файли"
          cellTemplate={cellTemplate}
        >
          <Lookup displayExpr="name" valueExpr="name" />
        </Column>

        <Column
          visible={false}
          dataField="firmwareGroupId"
          editCellRender={FWGroupIdCellTemplate}
        >
          <RequiredRule />
        </Column>

        <Column dataField="hardwareId" visible={false} caption="ID">
          <RequiredRule />
        </Column>
      </TreeList>
    </>
  )
}

export default FirmwarePage
