import React, { useRef, useState } from 'react';
import DataGrid, {
  Column,
  Editing,
  Scrolling,
  RequiredRule,
  Button,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';

import PageTitle from '../../components/PageTitle/PageTitle';
import { storeErrorHandler } from '../../helpers/errorHandling';
import api from '../../api';
import EditTextInput from '../../components/EditTextInput/EditTextInput';
import ParameterListDropDown from '../../components/ParameterListDropDown/ParameterListDropDown';
import GroupsListDropDown from '../../components/GroupsListDropDown/GroupsListDropDown';
import { findDeviceParentId, removeDevicesFromTree } from '../../helpers/treeModificator';
import { DeviceT } from '../../api/modules/device';
import { ConfigT } from '../../api/modules/config';
import { FirmwareGroupT } from '../../api/modules/firmwareGroup';
import DevicePopUp from '../DevicesPage/components/DevicePopUp';

function setParamsToDevices(devices: DeviceT[], config: ConfigT, groups: FirmwareGroupT[]) {
  return devices.map(device => {
    const firmwareGroup = groups.find(group => group.devices.some(d => d.id === device.id));
    const parameterParentId = findDeviceParentId(config, device.id);
    return {
      ...device,
      firmwareGroupId: firmwareGroup?.id,
      parameterParentId,
    };
  });
}

const DevicesListPage: React.FC = () => {
  const [deviceId, setId] = useState<number | undefined>();
  const [groupListStore, setGroupListStore] = useState<any>([]);
  const [paramsListStore, setParamsListStore] = useState<any>([]);

  const devicesData: any = useRef(
    new CustomStore({
      key: 'id',
      load: async () => {
        const batch = [
          api.device
            .get({})
            .then(result => result.data)
            .catch(storeErrorHandler),
          api.config
            .get({})
            .then((result: any) => result.data)
            .catch(storeErrorHandler),
          api.firmwareGroup
            .get({})
            .then(result => {
              return result.data;
            })
            .catch(storeErrorHandler),
        ];

        const [devices, config, groups] = await Promise.all(batch);
        setGroupListStore(groups);
        setParamsListStore(removeDevicesFromTree(config));

        const tree = setParamsToDevices(devices, config, groups);
        return tree;
      },
      remove: id => api.device.delete(id).catch(storeErrorHandler),
      update: (id, data) => api.device.update({ id, ...data }).catch(storeErrorHandler),
      insert: data => api.device.create({ ...data }).catch(storeErrorHandler),
    }),
  ).current;

  function handleClick({ row: { data } }: any) {
    setId(data);
  }
  return (
    <>
      <DevicePopUp closeModal={() => setId(undefined)} device={deviceId} />
      <PageTitle title="Всi пристрої" />

      <DataGrid id="grid" showBorders dataSource={devicesData} repaintChangesOnly>
        <Editing refreshMode="full" mode="form" allowAdding allowDeleting allowUpdating useIcons />

        <Scrolling mode="virtual" />

        <Column
          editCellRender={cellOption => (
            <EditTextInput cellOption={cellOption} textBoxParams={{ maxLength: 255 }} />
          )}
          dataField="name"
          caption="Ім'я"
        >
          <RequiredRule />
        </Column>

        <Column
          editCellRender={cellOption => (
            <EditTextInput cellOption={cellOption} textBoxParams={{ maxLength: 45 }} />
          )}
          dataField="deviceId"
          caption="ID пристрія"
        >
          <RequiredRule />
        </Column>

        <Column
          editCellRender={cellOption => (
            <EditTextInput cellOption={cellOption} textBoxParams={{ maxLength: 45 }} />
          )}
          dataField="deviceMac"
          caption="MAC адреса"
        >
          <RequiredRule />
        </Column>
        <Column type="buttons">
          <Button name="edit" />
          <Button name="delete" />
          <Button text="Статистика" hint="statistic" onClick={handleClick} />
        </Column>

        <Column
          visible={false}
          editCellRender={cellOption => (
            <ParameterListDropDown dataSource={paramsListStore} cellOption={cellOption} />
          )}
          dataField="parameterParentId"
          caption="Параметр"
        >
          <RequiredRule />
        </Column>

        <Column
          visible={false}
          editCellRender={cellOption => (
            <GroupsListDropDown dataSource={groupListStore} cellOption={cellOption} />
          )}
          dataField="firmwareGroupId"
          caption="Группа пристроїв"
        >
          <RequiredRule />
        </Column>
      </DataGrid>
    </>
  );
};

export default DevicesListPage;
