import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';

import { Button } from 'devextreme-react';

import AceEditor from 'react-ace';

import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

import './CodeEditor.css';
import TestForm from './TestForm'

interface Props {
  cellOptions: any;
}

const CodeEditor = ({ cellOptions }: Props) => {
  const [value, setValue] = useState('');
  const [popUpVisible, setIsPopUpVisible] = useState(false);

  const editor = useRef<AceEditor>(null);

  useEffect(() => {
    if (cellOptions.value) {
      setValue(cellOptions.value);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      editor?.current?.editor.resize();
    }, 100);
  }, [popUpVisible]);

  useEffect(() => {
    cellOptions.setValue(value);
  }, [value, cellOptions]);

  function hideInfo(e: any) {
    setIsPopUpVisible(false);
  }

  return (
    <>
      <Popup
        visible={popUpVisible}
        onHiding={hideInfo}
        dragEnabled={false}
        closeOnOutsideClick
        showTitle
        title="Редактор"
        width={750}
        height={'auto'}
      >
        <TestForm value={value} />
        <AceEditor
          ref={editor}
          mode="typescript"
          theme="github"
          name="blah2"
          // onLoad={this.onLoad}
          onChange={setValue}
          fontSize={14}
          showPrintMargin
          showGutter
          width={'700px'}
          height={'490px'}
          highlightActiveLine={false}
          value={value}
          wrapEnabled={true}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </Popup>

      <Button
        disabled={!cellOptions.data.id}
        width={180}
        text="Вiдкрити редактор"
        type="normal"
        stylingMode="contained"
        onClick={() => setIsPopUpVisible(true)}
      />
    </>
  );
};

export default CodeEditor;
