import React, { useState, useEffect } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, {
  Column,
  Editing,
  Scrolling,
  RequiredRule,
  MasterDetail,
  Lookup,
} from 'devextreme-react/data-grid'

import api from '../../api'
import PageTitle from '../../components/PageTitle/PageTitle'
import { storeErrorHandler } from '../../helpers/errorHandling'
import './KeysPage.scss'

const keysData: any = new CustomStore({
  key: 'keyId',
  load: () =>
    api.keyApi
      .get()
      .then(result => {
        return result.data
      })
      .catch(storeErrorHandler),
  remove: id => api.keyApi.delete(id).catch(storeErrorHandler),
  update: (id, data) => {
    return api.keyApi.updateKeys(id, data).catch(storeErrorHandler)
  },
  insert: data => {
    const _data = {
      ...data,
      versions: [],
    }
    return api.keyApi.create(_data).catch(storeErrorHandler)
  },
})

const KeysPage = () => {
  const [_keyTypes, setKeyTypes] = useState([])

  const keysTypes: any = new CustomStore({
    load: () =>
      api.keyApi
        .getKeysTypes()
        .then(result => {
          const _res =
            result?.data?.map((item: string) => ({
              keyType: item,
            })) || []
          setKeyTypes(_res)
          return _res
        })
        .catch(storeErrorHandler),
  })

  useEffect(() => {
    keysTypes.load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mb-3">
      <PageTitle title="Ключі пристроїв" />
      <DataGrid id="keyId" showBorders dataSource={keysData} repaintChangesOnly>
        <Editing
          refreshMode="full"
          mode="form"
          allowAdding
          allowDeleting
          allowUpdating
          useIcons
        />

        <Scrolling mode="virtual" />

        <Column dataField="keyName" caption="Ім'я ключа">
          <RequiredRule />
        </Column>
        <Column dataField="keyNumber" caption="Номер ключа">
          <RequiredRule />
        </Column>
        <Column dataField="keyType" caption="Тип ключа">
          <RequiredRule />
          <Lookup
            dataSource={_keyTypes}
            valueExpr="keyType"
            displayExpr="keyType"
          />
        </Column>
        <MasterDetail
          enabled={true}
          component={props => <DetailTemplate keysData={keysData} {...props} />}
        />
      </DataGrid>
    </div>
  )
}

const DetailTemplate = props => {
  const {
    data: { data },
  } = props

  const versionData: any = new CustomStore({
    key: 'keyVersionId',
    load: () =>
      api.keyApi
        .getKeyVersions(data.keyId)
        .then(result => {
          return result.data
        })
        .catch(storeErrorHandler),
    remove: id => api.keyApi.deleteKeyVersion(id).catch(storeErrorHandler),
    update: (id, data) => {
      return api.keyApi.updateKeyVersion(id, data).catch(storeErrorHandler)
    },
    insert: values => {
      return api.keyApi
        .createKeyVersion({ ...values, keyKeyId: data.keyId })
        .catch(storeErrorHandler)
    },
  })

  return (
    <DataGrid
      id={'keyId'}
      dataSource={versionData}
      showBorders={true}
      columnAutoWidth={true}
      className="versions_tabel"
    >
      <Editing
        refreshMode="full"
        mode="form"
        allowAdding
        allowDeleting
        allowUpdating
        useIcons
      />
      <Column dataField="keyVersion" caption="Версія Ключа">
        <RequiredRule />
      </Column>
      <Column
        dataField="publicKey"
        caption="Відкритий ключ"
        width={300}
        cellRender={KeysComponent}
      >
        <RequiredRule />
      </Column>
      <Column dataField="privateKey" caption="Закритий ключ" />
    </DataGrid>
  )
}

const KeysComponent = props => {
  return <span title={props.value}>{props.value}</span>
}

export default KeysPage
