import { UserT } from '../../../api/modules/user';
import { LoadSuccessT, LOAD_SUCCESS } from './load';
import { CreateSuccessT, CREATE_SUCCESS } from './create';
import { UpdateSuccessT, UPDATE_SUCCESS } from './update';
import { DeleteSuccessT, DELETE_SUCCESS } from './delete';

export type ActionTypeT = LoadSuccessT | CreateSuccessT | UpdateSuccessT | DeleteSuccessT;

export type StateT = Array<UserT>;

const initialState: StateT = [];

export default function userDataReducer(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case LOAD_SUCCESS:
      return action.result.data;

    case CREATE_SUCCESS:
      return [...state, action.result.data];

    case UPDATE_SUCCESS: {
      const data = state.map(item => {
        if (item.id === action.result.data.id) {
          return action.result.data;
        }

        return item;
      });

      return data;
    }

    case DELETE_SUCCESS: {
      const data = state.filter(item => item.id !== action.result.data.id);

      return data;
    }

    default:
      return state;
  }
}
